import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from "./index";

type InitialState = {
    langList: string[],
    currentLang: string,
}

const initialState: InitialState = {
    langList: [],
    currentLang: localStorage.getItem('lang') || 'en',
};

const locale = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        setLangList(state, action) {
            state.langList = action.payload
        },
        setCurrentLang(state, action) {
            state.currentLang = action.payload
        }
    }
});

const selectSelf = (state: RootState) => state.locale;

export const selectCurrentLanguage = createSelector(selectSelf, (state) => state.currentLang);

export const { setLangList, setCurrentLang } = locale.actions;

export const languagesReducer =  locale.reducer;
