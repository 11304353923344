import { useMemo } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Table from '../../../../components/Table/Table';
import { TABLE_OPENED_POSITIONS_COLUMNS_NAMES } from "./OpenedPositionsTableColumns";
import { makeTableData } from '../../../../utils/makeTableData';
import { OpenedPosition, TradingTableProps } from '../../../../types/Trading';

import './positions-table.scss';

export const OpenedPositionsTable = ({ data, perPage, showLoader }: TradingTableProps<OpenedPosition>) => {
  const preparedTableData = useMemo(() => makeTableData(data, data.length).map((item: OpenedPosition) => {
    const {
      current_price,
      open_price,
      opened_at_dt,
      position,
      profit,
      symbol,
      volume,
      account,
      side,
      demo
    } = item;
    return {
      account: (
        <span>
          {demo === 1 && (<span className="account-table__demo">
                            <FormattedMessage id="table.th.demo" defaultMessage="DEMO" />
                          </span>)}
          {account}
        </span>
      ),
      side:(
        <span className={side}>{side}</span>
      ),
      position,
      symbol,
      open_price,
      current_price,
      volume,
      profit,
      open_time: (
        <div className='date-cell-row'>
          <span>{moment(opened_at_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span className='_grey-title'>{moment(opened_at_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
    };
  }), [data]);

  return (
    <Table
      columns={TABLE_OPENED_POSITIONS_COLUMNS_NAMES}
      data={preparedTableData}
      perPage={perPage}
      showLoader={showLoader}
      getCellProps={() => ({style: {}})}
      className="positions-table"
    />
  );
};
