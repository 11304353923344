import { createPortal } from "react-dom";
import { useAppSelector } from "../../store";
import { selectModalOpened } from "../../store/modal";
import { ReactNode } from "react";
import { ModalContainer } from "./components/ModalContainer";
import { ThemeWrapper } from "../ThemeWrapper";

type ModalProps = {
    children: ReactNode;
    modal: string;
    disableBodyScroll?: boolean;
    onOverlayClick: () => void;
};

export const Modal = ({children, modal, onOverlayClick, disableBodyScroll = true}: ModalProps) => {
    const modalKey = useAppSelector(selectModalOpened);

    if (modalKey !== modal) {
        return null
    }

    return createPortal(
        <ModalContainer disableBodyScroll={disableBodyScroll} onOverlayClick={onOverlayClick}>
            <ThemeWrapper>
                {children}
            </ThemeWrapper>
        </ModalContainer>,
        document.body);
};