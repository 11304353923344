import { FormattedMessage } from "react-intl";

export const optionsTypeTranslations = {
    passport: <FormattedMessage id="documentTypes.popup.file.type.passport" defaultMessage="Passport"/>,
    driving_licence: <FormattedMessage id="documentTypes.popup.file.type.driving_licence" defaultMessage="Driving licence"/>,
    utility_bill: <FormattedMessage id="documentTypes.popup.file.type.utility_bill" defaultMessage="Utility bill"/>,
    bank_statement: <FormattedMessage id="documentTypes.popup.file.type.bank_statement" defaultMessage="Bank statement"/>,
    rental_agreement: <FormattedMessage id="documentTypes.popup.file.type.rental_agreement" defaultMessage="Rental agreement"/>,
    insurance: <FormattedMessage id="documentTypes.popup.file.type.insurance" defaultMessage="Insurance"/>,
    residence_other: <FormattedMessage id="documentTypes.popup.file.type.residence_other" defaultMessage="Residence other"/>
};

export const optionsCategoryTranslations = {
    proof_of_identity: <FormattedMessage id="documentTypes.popup.file.category.proof_of_identity" defaultMessage="Proof of identity"/>,
    proof_of_residence: <FormattedMessage id="documentTypes.popup.file.category.proof_of_residence" defaultMessage="Proof of residence"/>
};