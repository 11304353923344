import { ReactNode } from "react";

type DropdownOptionProps = {
    icon?: string,
    title: string | ReactNode,
    onSelect: () => void
    className?: string,
}

export const DropdownOption = ({ icon, title, onSelect, className = '' }: DropdownOptionProps) => {
    return (
        <div className={`dropdown-option _flex _flex-a-c ${className}`} onClick={onSelect}>
            {icon ? <div className="dropdown-option-icon"><i className={`fi ${icon}`}/></div> : null}
            <div className="_fz-12">{title}</div>
        </div>
    )
}