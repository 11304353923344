import cn from "classnames";
import { ReactNode } from "react";
import { Modal } from "../../Modal";
import './ModalTemplate.scss'

export type ModalSizes = 'sm' | 'md' | 'lg' | 'xs';
type ModalTemplateProps = {
    id: string;
    title: string | ReactNode;
    size?: ModalSizes;
    onClose: () => void;
    closeOnOverlayClick?: boolean;
    className?: string;
    children: ReactNode;
}

export const ModalTemplate = ({ id, title, children, onClose, closeOnOverlayClick, size = 'sm', className = '' }: ModalTemplateProps) => {
    return (
        <Modal modal={id} onOverlayClick={closeOnOverlayClick && onClose}>
            <div className={cn("modal-template", size, className)}>
                <div className="modal-template-header">
                    <div className="modal-template-title"><span>{title}</span></div>
                    <div className="modal-template-close" onClick={onClose}>
                        <i className="fi fi-rr-cross"/>
                    </div>
                </div>
                <div className="modal-template-content">{children}</div>
            </div>
        </Modal>
    )
};
