import cn from "classnames";
import PhoneInputComponent from 'react-phone-input-2';
import { useController } from 'react-hook-form';
import { InputField } from "../InputField/InputField";
import 'react-phone-input-2/lib/style.css';
import './phoneInput.scss'

const PhoneInput = (props) => {
    const {field, formState} = useController(props);

    const fieldError = formState.errors?.[field.name]?.message || '';
    return (
        <InputField
            id={props.id}
            label={props.label}
            error={fieldError}
            className={cn('select-input', props.className, { required: props.rules?.required })}
            tooltip={props.tooltip}
        >
            <PhoneInputComponent
                {...field}
                excludeCountries={props.exclude}
                country={props.country}
                containerClass={'phoneInputContainer'}
                inputClass={'input phone-input'}
                buttonClass={'phoneButton'}
                dropdownClass={'phoneDropdown'}
                enableSearch
            />
        </InputField>
    );
};

export default PhoneInput;
