import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { usePageTitle } from "../../hooks/usePageTitle";
import { uploadFile } from "../../api/documents";
import { useFormInput } from "../../components/Form/FileInput/useFormInput";
import { addToast } from "../../store/toast";
import { getResponseErrorMessage } from "../../utils/getResponseErrorMessage";
import { useAppDispatch } from "../../store";
import { closeModal, openModal } from "../../store/modal";
import { DOCUMENT_UPLOAD_MODAL_KEY, DocumentUploadModal } from "./DocumentUploadModal/DocumentUploadModal";
import { ModalTemplate } from "../../components/Modal/components/ModalTemplate/ModalTemplate";
import { DocumentsDescription } from "./DocumentsDescription/DocumentsDescription";
import { FileInput } from "../../components/Form/FileInput/FileInput";
import { refreshFileInput } from "../../utils/refreshFileInput";
import './documents.scss';


const Documents = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const {files, setFiles, progress, setProgress, onUploadProgress} = useFormInput();

    usePageTitle(intl.formatMessage({
        id: "menu.documents",
        defaultMessage: "Documents",
    }));

    const onSelectFile = (data) => {
        dispatch(openModal({ key: DOCUMENT_UPLOAD_MODAL_KEY, data: data?.[0]?.name }));
    };

    const handleCloseModal = () => {
        refreshFileInput('document');
        setFiles(null);
        dispatch(closeModal());
    };

    const sendFile = (data) => {
        if (!data) {
            return;
        }

        dispatch(closeModal());
        setProgress(1);

        const formData = new FormData();
        for (const item of files) {
            formData.append('file', item, item.name.split('.')[0]);
            formData.append('type_key', data.category_type.value);
        }

        for (const item of Object.keys(data)) {
            formData.append(item, item === 'category_type' ? data.category.value : data.category_type.value);
        }

        uploadFile(formData, { onUploadProgress })
            .then(() => {
                setProgress(0);
                setFiles(null);
                dispatch(addToast({
                    type: 'success',
                    message: intl.formatMessage({id: 'file.upload.success.text'}),
                    timeout: 3000
                }));
            })
            .catch((err) => {
                setProgress(0);
                setFiles(null);
                dispatch(addToast({
                    type: 'error',
                    message: getResponseErrorMessage(err),
                    timeout: 3000
                }));
            }).finally(() => refreshFileInput('document'));
    };

    return (
        <div className="documents-page">
            <h1><FormattedMessage id="page.documents.descr" defaultMessage="Verification documentation"/></h1>
            <div className="row">
                <div className="documents-page-text col c-lg-7">
                    <DocumentsDescription />
                </div>
                <div className="col c-lg-5 _flex _flex-col">
                    <FileInput
                        name="document"
                        progress={progress}
                        files={files}
                        setFile={setFiles}
                        onChange={onSelectFile}
                        onSubmit={() => setProgress(0)}
                        isDisabled={progress > 0}
                    />
                </div>
            </div>
            <ModalTemplate
                id={DOCUMENT_UPLOAD_MODAL_KEY}
                title={<FormattedMessage id="documentTypes.popup.title" defaultMessage="Set document type"/>}
                onClose={handleCloseModal}
                size="sm">
                <DocumentUploadModal onSubmit={sendFile} closeModal={handleCloseModal}/>
            </ModalTemplate>
        </div>
    );
};

export default Documents;
