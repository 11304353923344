const CURRENT_DOMAIN_CRM: string = process.env.REACT_APP_API_URL_CRM;
const ITS_WITH_ITALIAN = Boolean(Number(process.env.REACT_APP_ITALIANO));
const ITS_WITH_CZECH = Boolean(Number(process.env.REACT_APP_CZECH));
const ITS_WITH_JAPANESE = Boolean(Number(process.env.REACT_APP_JAPANESE));
const ITS_WITH_PORTUGUESE = Boolean(Number(process.env.REACT_APP_PORTUGUESE));
const ITS_WITH_TURKISH = Boolean(Number(process.env.REACT_APP_TURKISH));
const ITS_WITH_ROMANIAN = Boolean(Number(process.env.REACT_APP_ROMANIAN));
const ITS_WITH_DEUTSCH = Boolean(Number(process.env.REACT_APP_DEUTSCH));
const IS_BLOCKED_IP_COUNTRY: Boolean = Boolean(Number(process.env.REACT_APP_BLOCKED_IP_COUNTRY));
const IS_DWS_DOMAIN: Boolean = Boolean(Number(process.env.REACT_APP_IS_DWS));


export { CURRENT_DOMAIN_CRM, ITS_WITH_ITALIAN, ITS_WITH_CZECH, ITS_WITH_JAPANESE, ITS_WITH_PORTUGUESE, ITS_WITH_TURKISH, ITS_WITH_ROMANIAN, ITS_WITH_DEUTSCH, IS_BLOCKED_IP_COUNTRY, IS_DWS_DOMAIN };
