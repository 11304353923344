import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import { ToastItem } from "./ToastItem";
import { selectToastItems } from "../../store/toast";

import "./Toast.scss";

export const Toast = () => {
  const toasts = useSelector(selectToastItems);

  return createPortal(
    <div className="toasts-wrapper _pos-f _flex _flex-col">
      {toasts.length ? toasts.map((toast) => <ToastItem toast={toast} key={toast.key} />) : null}
    </div>,
    document.body,
  );
};
