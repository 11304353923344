import API from '../api'
import {
    EmailVerificationSubmitData,
    LoginSubmitData,
    ResetPasswordSubmitData,
    SignUpSubmitData
} from "../../types/Auth";

export const signIn = (data: LoginSubmitData) => (
  API.post('api/v1/login', data)
)

export const signUp = (data: SignUpSubmitData) => (
  API.post('api/v1/register', data)
)

export const verificationEmail = (verificationData: EmailVerificationSubmitData) => (
  API.post('/api/v1/verify/code', verificationData) 
)
export const resetPassword = (data: ResetPasswordSubmitData) => (
  API.post('/api/v1/password/reset', data) 
)

export const newPassword = (data: ResetPasswordSubmitData) => (
  API.post('/api/v1/new/password/email', data) 
)

export const logOut = () => (
  API.post('/api/v1/logout') 
)