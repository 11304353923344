import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectThemeMode, setMode } from "../../store/theme";
import { updateThemeMode } from "../../api/settings";
import './ThemeSwitcher.scss';

export const ThemeSwitcher = () => {
    const dispatch = useAppDispatch();
    const mode = useAppSelector(selectThemeMode);
    const isLight = mode === 'light';

    const handleSwitch = () => {
        const newMode = isLight ? 'dark' : 'light';
        dispatch(setMode(newMode));
        updateThemeMode({theme: newMode});
        localStorage.setItem('theme-mode', newMode);
    };

    return (
        <div className={cn("theme-switcher _flex _flex-xy-center", { dark: !isLight, light: isLight })}
             onClick={handleSwitch}
        >
            <i className="fi fi-rr-moon _text-main"/>
            <div className="theme-switcher-decor"/>
            <i className="fi fi-rr-sun _text-main"/>
        </div>
    )
}