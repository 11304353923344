import QRCode from "react-qr-code";
import { FormattedMessage, useIntl } from "react-intl";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { addToast } from "../../store/toast";
import { useAppDispatch } from "../../store";

export const TransactionInfo = ({cryptoTransaction}) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    return (
        <div className='transaction__info'>
            <div className='transaction__qr _flex-sh-0'>
                <QRCode value={`${cryptoTransaction.wallet}`} size={100}/>
            </div>
            <div className='transaction__desc _m-l-15 _fz-12 _text-c'>
                <div>
                    <FormattedMessage
                        id="page.finance.deposit.popup.wallet_text1"
                        defaultMessage="Please send"
                    />{' '}
                    <b>{cryptoTransaction.amount}</b> {cryptoTransaction.cryptoType}{' '}
                    <FormattedMessage
                        id="page.finance.deposit.popup.wallet_text2"
                        defaultMessage="to this wallet address"
                    />
                </div>
                <div
                    className='copy_id _fw-600'
                    onClick={() => copyToClipboard(cryptoTransaction.wallet, dispatch(addToast({
                        type: 'success',
                        message: intl.formatMessage({id: 'copied.to.buffer.alert'}),
                        timeout: 2000
                    })))}
                >
                    {cryptoTransaction.wallet}
                </div>
                <div>
                    <FormattedMessage
                        id="page.finance.deposit.popup.wallet_text3"
                        defaultMessage="Funds are credited after confirmation."
                    />
                </div>
            </div>
        </div>
    )
}