import { FormattedMessage } from "react-intl";

export const TABLE_OPENED_POSITIONS_COLUMNS_NAMES = [
    {
        Header: <FormattedMessage id="table.th.account" defaultMessage="Account" />,
        accessor: 'account',
    },
    {
        Header: <FormattedMessage id="table.th.side" defaultMessage="Side" />,
        accessor: 'side',
    },
    {
        Header: <FormattedMessage id="table.th.position" defaultMessage="Position" />,
        accessor: 'position',
    },
    {
        Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
        accessor: 'symbol',
    },
    {
        Header: <FormattedMessage id="table.th.openPrice" defaultMessage="Open Price" />,
        accessor: 'open_price',
    },
    {
        Header: <FormattedMessage id="table.th.currentPrice" defaultMessage="Current price" />,
        accessor: 'current_price',
    },
    {
        Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
        accessor: 'volume',
    },
    {
        Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
        accessor: 'profit',
    },
    {
        Header: <FormattedMessage id="table.th.openTime" defaultMessage="Open Time" />,
        accessor: 'open_time',
    },
];