import { useState } from "react";

export const useFormInput = () => {
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState(0);

    return {
        files,
        setFiles,
        progress,
        setProgress,
        onUploadProgress: (progressEvent) => {
            const percent = progressEvent.total / 100;
            const uploaded = progressEvent.loaded / percent;
            setProgress(Number(uploaded.toFixed()));
        },
    }
}