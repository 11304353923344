export const UKIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_25_31602)">
                <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#F0F0F0"/>
                <path d="M1.65379 3.12952C1.02538 3.94711 0.551541 4.88945 0.275635 5.91317H4.43745L1.65379 3.12952Z" fill="#0052B4"/>
                <path d="M15.7245 5.91314C15.4486 4.88945 14.9747 3.94711 14.3463 3.12952L11.5627 5.91314H15.7245Z" fill="#0052B4"/>
                <path d="M0.275635 10.087C0.551572 11.1107 1.02542 12.0531 1.65379 12.8706L4.43735 10.087H0.275635Z" fill="#0052B4"/>
                <path d="M12.8705 1.65382C12.0529 1.02542 11.1106 0.551572 10.0869 0.275635V4.43742L12.8705 1.65382Z" fill="#0052B4"/>
                <path d="M3.12939 14.3463C3.94699 14.9747 4.88933 15.4486 5.91302 15.7245V11.5627L3.12939 14.3463Z" fill="#0052B4"/>
                <path d="M5.91299 0.275635C4.8893 0.551572 3.94696 1.02542 3.12939 1.65379L5.91299 4.43738V0.275635Z" fill="#0052B4"/>
                <path d="M10.0869 15.7245C11.1106 15.4486 12.0529 14.9747 12.8705 14.3463L10.0869 11.5627V15.7245Z" fill="#0052B4"/>
                <path d="M11.5627 10.087L14.3463 12.8707C14.9747 12.0531 15.4486 11.1107 15.7245 10.087H11.5627Z" fill="#0052B4"/>
                <path d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z" fill="#D80027"/>
                <path d="M10.0869 10.0869L13.6568 13.6568C13.821 13.4927 13.9776 13.3211 14.127 13.1432L11.0707 10.0869H10.0869V10.0869Z" fill="#D80027"/>
                <path d="M5.91292 10.087H5.91286L2.34302 13.6569C2.50714 13.8211 2.67874 13.9777 2.85661 14.1271L5.91292 11.0708V10.087Z" fill="#D80027"/>
                <path d="M5.91293 5.91311V5.91305L2.34305 2.34314C2.17887 2.50726 2.02224 2.67886 1.8728 2.85673L4.92915 5.91308L5.91293 5.91311Z" fill="#D80027"/>
                <path d="M10.0869 5.91308L13.6568 2.34314C13.4927 2.17896 13.3211 2.02233 13.1432 1.87292L10.0869 4.92927V5.91308Z" fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0_25_31602">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}