import { useEffect, useState } from "react";
import { getSymbols } from "../api/opened-positions";

export const useGetSymbolOptions = () => {
    const [symbols, setSymbols] = useState([]);

    useEffect(() => {
        getSymbols()
        .then(res => {
            const opt = res?.data?.ticker.map((opt) => ({
              value: opt,
              label: opt,
            }));
            setSymbols(opt);
          })
          .catch(e => {
            console.log(e);
          });
    }, []);

    return symbols
}