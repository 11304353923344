import { FormattedMessage } from "react-intl"

export const tradingPageTabs = [
    {
        title: <FormattedMessage id="menu.accounts" defaultMessage="Accounts"/>,
        src: '/accounts'
    },
    {
        title: <FormattedMessage id="menu.history" defaultMessage="History"/>,
        src: '/history'
    },
    {
        title: <FormattedMessage id="menu.openedPositions" defaultMessage="Opened Positions"/>,
        src: '/opened-positions'
    },
    {
        title: <FormattedMessage id="menu.deals" defaultMessage="Deals"/>,
        src: '/deals'
    }
]