import { IS_BLOCKED_IP_COUNTRY } from 'config';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { checkIpCountry } from "../store/auth";

export const useCheckIpCountry = ( isAuth ) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isBlock = sessionStorage.getItem('is_blocked_ip');

    useEffect(() => {
        if (IS_BLOCKED_IP_COUNTRY) {
            if (!isAuth && isBlock === null) {
                dispatch(checkIpCountry())
                    .unwrap()
                    .then((isBlocked: boolean) => {
                        if (isBlocked) {
                            sessionStorage.setItem('is_blocked_ip', JSON.stringify(isBlocked));
                            navigate('/access-restricted');
                        }
                    });
            }

            if (JSON.parse(isBlock) === true) {
                navigate('/access-restricted');
            }
        }
    }, [isAuth, IS_BLOCKED_IP_COUNTRY]);
};