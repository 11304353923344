import App from "../App";
import Cryptocurrency from '../pages/Payments/Cryptocurrency/CryptocurrencyPage';
import Accounts from '../pages/Trading/Accounts/Accounts';
import History from '../pages/Trading/History/History';
import PaymentForm from "../pages/Payments/PaymentForm/PaymentForm";
import PaymentsError from "../pages/Payments/PaymentForm/PaymentsError/PaymentsError";
import PaymentsSuccess from "../pages/Payments/PaymentForm/PaymentsSuccess/PaymentsSuccess";
import PaymentSystemStatuses from "../pages/Payments/PaymentSystemStatuses/PaymentSystemStatuses";
import Personal from "../pages/MyProfile/Personal/Personal";
import ChangePassword from "../pages/MyProfile/ChangePassword/ChangePassword";
import OpenedPositions from "../pages/Trading/OpenedPositions/OpenedPositions";
import Deals from "../pages/Trading/Deals/Deals";
import Documents from '../pages/Documents/Documents';
import MetaTrader from "../pages/MetaTrader/MetaTrader";
import ChatPage from "../pages/Chat/Chat";
import VerifyEmail from "../pages/Auth/VerifyEmail/VerifyEmail";
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { PrivateLayout } from "../pages/PrivateLayout/PrivateLayout";
import { SignIn } from '../pages/Auth/SignIn/SignIn';
import { SignUp } from '../pages/Auth/SignUp/SignUp';
import { PasswordReset } from '../pages/Auth/PasswordReset/PasswordReset';
import { PasswordForgot } from '../pages/Auth/PasswordForgot/PasswordForgot';
import { isChatEnabled } from "../pages/Chat/helpers/isChatEnabled";
import { AccessRestricted } from "../pages/AccessRestricted/AccessRestricted";

const PrivateRoute = ({ children }) => localStorage.getItem('token') ? children : <Navigate to="/" />;

const PublicRoute = () => !localStorage.getItem('token') ? <Outlet/> : <Navigate to="/cryptocurrency" />;

const PUBLIC_ROUTES = [
  {
    element: <SignIn/>,
    index: true
  },
  {
    path: '/sign-up',
    element: <SignUp/>,
  },
  {
    path: '/partner',
    element: <SignUp/>,
  },
  {
    path: '/password/reset/:email/:code',
    element: <PasswordReset/>,
  },
  {
    path: '/verify/:email/:code',
    element: <VerifyEmail/>,
  },
  {
    path: '/forgot-password',
    element: <PasswordForgot/>,
  },
  {
    path: '/access-restricted',
    element: <AccessRestricted/>,
  },
  {
    path: '*',
    element: <ErrorPage/>,
  },
];

const PRIVATE_ROUTES = [
  {
    path: '/cryptocurrency',
    element: <Cryptocurrency/>,
    index: true,
  },
  {
    path: '/accounts',
    element: <Accounts/>,
  },
  {
    path: '/history',
    element: <History/>,
  },
  {
    path: '/opened-positions',
    element: <OpenedPositions />,
  },
  {
    path: '/deals',
    element: <Deals/>,
  },
  {
    path: '/documents',
    element: <Documents/>,
  },
  {
    path: '/personal',
    element: <Personal/>,
  },
  {
    path: '/change-password',
    element: <ChangePassword/>,
  },
  {
    path: '/wire-statuses',
    element: <PaymentSystemStatuses/>,
  },
  {
    path: '/payment/form',
    element: <PaymentForm/>,
  },
  {
    path: '/payment/success',
    element: <PaymentsSuccess/>,
  },
  {
    path: '/payment/success/:id',
    element: <PaymentsSuccess/>,
  },
  {
    path: '/payment/fail',
    element: <PaymentsError/>,
  },
  {
    path: '/payment/fail/:id',
    element: <PaymentsError/>,
  },
  {
    path: '/meta-trader',
    element: <MetaTrader/>,
  },
  {
    path: '/chat',
    element: isChatEnabled() ? <ChatPage/> : <ErrorPage />,
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];

export const router = createBrowserRouter([
  {
    element: <App/>,
    children: [
      {
        element: <PublicRoute/>,
        errorElement: <ErrorPage/>,
        children: PUBLIC_ROUTES
      },
      {
        element: <PrivateLayout/>,
        errorElement: <ErrorPage/>,
        children: PRIVATE_ROUTES.map(route => ({
          ...route,
          element: <PrivateRoute children={route.element}/>
        }))
      }]
  }
]);
