import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ToastState } from './types';
import { RootState } from "../index";

const toastInitialState: ToastState = {
  items: [],
};

export const selectToastItems = (state: RootState) => state.toast.items;

const toastSlice = createSlice({
  name: 'toast',
  initialState: toastInitialState,
  reducers: {
    addToast(state, { payload }) {
      state.items.push({ ...payload, key: (payload.key || '') + uuidv4() });
    },
    removeToast(state, action) {
      state.items = state.items.filter((toast) => toast.key !== action.payload);
    },
  },
});

const { addToast, removeToast } = toastSlice.actions;
const toastReducer = toastSlice.reducer;

export { addToast, removeToast, toastReducer, toastInitialState };
