import API from '../api';

export const getTradingAccounts = (options) => API.get(`/api/v1/trading-account?${options}`);
export const getTradingAccount = (id) => API.get(`/api/v1/trading-account/${id}`);
export const createTradingAccount = (postData, options) =>
  API.post(`/api/v1/trading-account`, postData, options);
export const changeTradingAccountPassword = (id, postData, options) =>
  API.post(`/api/v1/trading-account/change-password/${id}`, postData, options);
export const getAvailableWithdrawAmount = (login) =>
    API.get(`/api/v1/trading-account/available-for-withdrawal/${login}`);
