import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TablePagination } from '../../../components/Table/TablePagination';
import { getHeaderInfo } from '../../../api/myProfile';
import { setHeaderInfo } from '../../../store/headerInfo';
import { getTradingAccounts } from '../../../api/trading-account';
import { useTableData } from '../../../hooks/useTableData';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppDispatch } from '../../../store';
import AccountsTable from './AccountsTable/AccountsTable';
import { Account } from '../../../types/Trading';
import { PageNavigation } from "../../../components/PageNavigation/PageNavigation";
import { tradingPageTabs } from "../_shared/TradingPageTabs";
import { Button } from "../../../components/Button/Button";
import { closeModal, openModal } from "../../../store/modal";
import AddAccountModal, { ADD_ACCOUNT_MODAL_KEY } from "../../../modals/AddAccountModal/AddAccountModal";
import { ModalTemplate } from "../../../components/Modal/components/ModalTemplate/ModalTemplate";
import AccountChangePasswordModal, {
    ACCOUNT_PASSWORD_MODAL_KEY
} from "../../../modals/AccountChangePasswordModal/AccountChangePasswordModal";

export const Accounts = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const {
    tableDataLoading,
    tableData,
    tableMeta,
    getTableData,
    setPerPageCount,
    perPageCount,
    tableLinks,
  } = useTableData<Account>(getTradingAccounts);

  usePageTitle(
    intl.formatMessage({
      id: "trading.accounts.title",
      defaultMessage: "Trading Accounts",
    }),
  );

  useEffect(() => {
    getHeaderInfo()
      .then((res) => {
        const info = res.data.data;
        dispatch(setHeaderInfo(info));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <h1><FormattedMessage id="menu.trading" defaultMessage="Trading"/></h1>
      <PageNavigation nav={tradingPageTabs}>
        <Button onClick={() => dispatch(openModal({ key: ADD_ACCOUNT_MODAL_KEY }))}>
            + <FormattedMessage id="newAccountPopup.title.add" defaultMessage="Trading"/>
        </Button>
      </PageNavigation>

      <AccountsTable data={tableData} perPage={perPageCount} showLoader={tableDataLoading} />
      {tableData?.length && tableMeta ? (
        <TablePagination
          onChange={getTableData}
          setPerPageCount={setPerPageCount}
          perPage={perPageCount}
          tableLinks={tableLinks}
          tableMeta={tableMeta}
        />
      ) : null}

     <ModalTemplate
        id={ADD_ACCOUNT_MODAL_KEY}
        title={<FormattedMessage id="newAccountPopup.title.add" defaultMessage="Add Account" />}
        onClose={handleCloseModal}
        size="sm"
     >
        <AddAccountModal closeModal={handleCloseModal} onSuccess={() => getTableData(null)}/>
     </ModalTemplate>
     <ModalTemplate
        id={ACCOUNT_PASSWORD_MODAL_KEY}
        title={<FormattedMessage
            id="page.trading.popup.changePassword.title"
            defaultMessage="Change trading account password"
        />}
        onClose={handleCloseModal}
        size="sm"
     >
        <AccountChangePasswordModal onCloseModal={handleCloseModal} />
     </ModalTemplate>
    </>
  );
};

export default Accounts;
