export const RussiaIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_398_5365)">
                <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#F0F0F0"/>
                <path d="M15.5024 10.7826C15.824 9.91594 16 8.97856 16 8C16 7.02144 15.824 6.08406 15.5024 5.21741H0.497594C0.176031 6.08406 0 7.02144 0 8C0 8.97856 0.176031 9.91594 0.497594 10.7826L8 11.4783L15.5024 10.7826Z" fill="#0052B4"/>
                <path d="M7.99996 16C11.4397 16 14.372 13.829 15.5024 10.7826H0.497559C1.6279 13.829 4.56025 16 7.99996 16Z" fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0_398_5365">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}