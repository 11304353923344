import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  selectChatAuth,
  selectChatConnectionStatus,
  selectChatToken,
} from "../../store/chat/chatSlice";
import { ChatDialog } from "./components/ChatDialog/ChatDialog";
import { useChatOpen } from "./helpers/useChatOpen";
import { usePageTitle } from "../../hooks/usePageTitle";
import "./Chat.scss";

const ChatPage = () => {
  const intl = useIntl();
  const token = useSelector(selectChatToken);
  const userLoaded = useSelector(selectChatAuth);
  const connectionEstablished = useSelector(selectChatConnectionStatus);

  useChatOpen();
  usePageTitle(intl.formatMessage({ id: "chat.page.page_title", defaultMessage: "Support Chat" }));

  return (
    <div className="chat">
      <h1>
        <FormattedMessage id="chat.page.title" defaultMessage="Live Support Chat" />
      </h1>
      {!connectionEstablished ? (
        <div className="connection-status">
          <FormattedMessage id="chat.errors.no_connection" defaultMessage="Connection issues..." />
        </div>
      ) : null}
      {token && userLoaded ? (
        <div className="chat-content">
          <ChatDialog />
        </div>
      ) : null}
    </div>
  );
};

export default ChatPage;
