import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/Button/Button';
import { useAppSelector } from 'store';
import { selectIsAuthorized } from 'store/auth';
import ErrorImage from '../../assets/img/404_t.png';
import './error-page.scss';

export const ErrorPage = () => {
  const isAuth = useAppSelector(selectIsAuthorized);
  const navigate = useNavigate();

  return (
    <div className="error-page _flex _flex-xy-c _m-x-auto _w-100">
      <div className="error-page__img _w-50">
        <img src={ErrorImage} alt="error page pict" />
      </div>
      <div className="error-page__description _flex _flex-col _flex-a-s _w-50">
        <span className="error-page__number _fw-500 _m-b-40">404</span>
        <span className="_m-b-30 _fz-30 _fw-500">
          <FormattedMessage id="errorPage.title" defaultMessage="Page not found" />
        </span>
        <span className="_fz-12 _lh-24">
          <FormattedMessage
            id="errorPage.text"
            defaultMessage="The page you are looking for does not exist, it may have been moved or deleted altogether. Please go to the main page by clicking the button below."
          />
        </span>
        <div className="_m-t-30">
          <Button onClick={() => navigate('/')}>
            {!isAuth ? (
              <FormattedMessage id="errorPage.login" defaultMessage="Log in" />
            ) : (
              <FormattedMessage id="errorPage.home" defaultMessage="Home" />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
