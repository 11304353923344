export function paymentMethodLabelAdapter(method: string): string {
    switch(method) {
        case "internal_transfer":
            return "Crypto";
        case "Credit":
        case "Bonus":
            return "Credit balance";
        case "credit_card":
            return "Credit card";
        default:
            return method;
    }
}
