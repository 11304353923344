import { ReactNode } from "react";
import { FileUploader } from "react-drag-drop-files";
import { chatFileTypes } from "../../constants";
import { useAppDispatch } from "../../../../store";
import { addToast } from "../../../../store/toast";
import { useIntl } from "react-intl";

export const ChatUploadInput = ({ onChangeHandler }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const handleError = (message: string | ReactNode) => {
        dispatch(addToast({
            type: 'error',
            message: message,
            timeOut: 3000,
        }));
    };
    return (
        <FileUploader
            name="chat_upload"
            types={chatFileTypes}
            maxSize={16}
            handleChange={onChangeHandler}
            onTypeError={() => handleError(intl.formatMessage({
                id: "form.error.fileType",
                defaultMessage: "Wrong file type!"
            }))}
            onSizeError={() => handleError(intl.formatMessage({
                id: "form.error.fileSize",
                defaultMessage: "The file is too large. Allowed file size is 16Mb."
            }, {SIZE: 16}))}
        >
            <div className='attach-file-button _flex _flex-col _flex-a-c _flex-j-c'>
                <i className="fi fi-rr-clip _flex _fz-18"/>
            </div>
        </FileUploader>
    )
};