import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from "./index";
import { getThemeMode, getThemeSettings } from "../api/settings";

type InitialState = {
    theme: string,
    mode: string,
    color: string,
    logo_src: string,
    logo_src_crm: string,
    sign_in: string,
    sign_up: string,
    reset_password: string,
    favicon_src_client: string,
    favicon_src_crm: string,
}

const colors = ['blackberry', 'mint', 'blueberry', 'raspberry', 'tangerine', 'ice'];
const themes = ['theme-1', 'theme-2', 'theme-3'];

const initialState: InitialState = {
    theme: localStorage.getItem('theme-type') || themes[0],
    color: localStorage.getItem('theme-color') || colors[0],
    mode: localStorage.getItem('theme-mode') || 'light',

    logo_src: localStorage.getItem('logo_src'),
    logo_src_crm: localStorage.getItem('logo_src_crm'),
    sign_in: localStorage.getItem('sign_in_src'),
    sign_up: localStorage.getItem('sign_up_src'),
    reset_password: localStorage.getItem('reset_password_src'),
    favicon_src_client: localStorage.getItem('favicon_src_client'),
    favicon_src_crm: localStorage.getItem('favicon_src_crm'),
};

const theme = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setMode(state, action) {
            state.mode = action.payload
        },
        resetTheme() {
            return initialState
        }
    },
    extraReducers: builder => {
        builder.addCase(getTheme.fulfilled, (state, action) => {
            state.theme = action.payload.theme;
            state.color = action.payload.color;
            state.logo_src = action.payload.logo_src;
            state.logo_src_crm = action.payload.logo_src_crm;
            state.favicon_src_crm = action.payload.favicon_src_crm;
            state.favicon_src_client = action.payload.favicon_src_client;
            state.sign_in = action.payload.sign_in;
            state.sign_up = action.payload.sign_up;
            state.reset_password = action.payload.reset_password;
        });
        builder.addCase(getMode.fulfilled, (state, action) => {
            state.mode = action.payload;
        });
    },
});

export const getTheme = createAsyncThunk('theme/get', async (_, { rejectWithValue }) => {
        try {
            const response = await getThemeSettings();
            const {
                colorset,
                theme,
                logo_src,
                logo_src_crm,
                favicon_src_crm,
                favicon_src_client,
                sign_in,
                sign_up,
                reset_password
            } = response.data.data;

            const isValidColor = colors.includes(colorset);
            const isValidTheme = themes.includes(theme);
            if (isValidColor) {
                localStorage.setItem('theme-color', colorset);
            } else {
                console.warn(`getTheme: '${colorset}' is invalid color set`);
            }

            if (isValidTheme) {
                localStorage.setItem('theme-type', theme);
            } else {
                console.warn(`getTheme: '${theme}' is invalid theme`);
            }

            localStorage.setItem('logo_src', logo_src);
            localStorage.setItem('logo_src_crm', logo_src_crm);
            localStorage.setItem('sign_in_src', sign_in);
            localStorage.setItem('sign_up_src', sign_up);
            localStorage.setItem('reset_password_src', reset_password);
            localStorage.setItem('favicon_src_client', favicon_src_client);
            localStorage.setItem('favicon_src_crm', favicon_src_crm);

            return {
                theme: isValidTheme ? theme : themes[0],
                color: isValidColor ? colorset : colors[0],
                logo_src,
                logo_src_crm,
                favicon_src_crm,
                favicon_src_client,
                sign_in,
                sign_up,
                reset_password
            }
        } catch (error: any) {
            return rejectWithValue(error.response.data.message)
        }
    }
);

export const getMode = createAsyncThunk('theme/mode/get', async (_, { rejectWithValue }) => {
        try {
            const modeResponse = await getThemeMode();
            modeResponse.data.theme && localStorage.setItem('theme-mode', modeResponse.data.theme);

            return modeResponse.data.theme || 'light'
        } catch (error: any) {
            return rejectWithValue(error.response.data.message)
        }
    }
);

export const selectTheme = (state: RootState) => state.theme;
export const selectFavicon = createSelector(selectTheme, state => state.favicon_src_client);
export const selectThemeMode = (state: RootState) => state.theme.mode;

export const { setMode, resetTheme } = theme.actions;

export const themeReducer =  theme.reducer;
