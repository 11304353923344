import { FormattedMessage } from "react-intl";

export const paymentsPageTabs = [
    {
        title: <FormattedMessage id="page.transactions.page_title" defaultMessage="Transactions"/>,
        src: '/cryptocurrency'
    },
    {
        title: <FormattedMessage id="menu.paymentSystemStatuses" defaultMessage="Payment system statuses"/>,
        src: '/wire-statuses'
    }
]