import cn from "classnames";
import { ReactNode, useRef } from "react";
import { useDropdown } from "./useDropdown";
import { createPortal } from "react-dom";
import { ThemeWrapper } from "../ThemeWrapper";
import './Dropdown.scss';

type DropdownPosition = 'top-left' | 'top' | 'top-right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left' | 'right';

type DropdownProps = {
    button: ReactNode;
    position?: DropdownPosition;
    className?: string;
    children: ReactNode;
    closeOnClick?: boolean;
};

type PortalDropdownProps = Omit<DropdownProps, 'button'> & {
    positionX: number;
    positionY: number;
    onClickOutside?: () => void;
}

export const Dropdown = ({button, position, className, closeOnClick, children}: DropdownProps) => {
    const buttonRef = useRef(null);
    const {dropdownOpened, switchDropdown} = useDropdown(buttonRef, closeOnClick);

    return (
        <div
            className={cn('dropdown-wrap', position ? position : '', className ? className : '', {isOpened: dropdownOpened})}>
            <div className="dropdown-button" ref={buttonRef} onClick={switchDropdown}>{button}</div>
            {dropdownOpened ? <div className='dropdown'>{children}</div> : null}
        </div>
    )
};

export const PortalDropdown = ({className, positionX, positionY, onClickOutside, children}: PortalDropdownProps) => {
    const handleClickOutside = () => {
        onClickOutside && onClickOutside();
    };

    return createPortal(
        <ThemeWrapper>
            <div className={cn('dropdown-portal', {captured: !!onClickOutside})} onClick={handleClickOutside}>
                <div className={cn('dropdown', className ? className : '')}
                     style={{top: positionY, left: positionX}}>{children}</div>
            </div>
        </ThemeWrapper>
        , document.body
    )
};
