import { ReactNode } from "react";
import { NavLink } from 'react-router-dom';
import { PageNavLink } from "../../types/Layout";
import './PageNavigation.scss';

type PageNavigationProps = {
    nav: PageNavLink[],
    children?: ReactNode
}

export const PageNavigation = ({ nav, children }: PageNavigationProps) => {
    return (
        <div className="page-nav _flex _flex-a-s _w-100 _m-b-10">
            <div className="page-nav-list _flex _flex-a-c _flex-w _flex-sh-0 _m-r-10">
                {nav.map((link) => (
                    <NavLink to={link.src} className="page-nav-link _text-up _fz-14 _fw-600" key={link.src}>
                        {link.title}
                    </NavLink>
                ))}
            </div>
            {children ? <div className="page-nav-actions _flex _flex-a-c _flex-w _flex-j-e _m-l-auto">{children}</div> : null}
        </div>
    )
}