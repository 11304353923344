import { createAsyncThunk, createSlice, createSelector, isAnyOf } from '@reduxjs/toolkit'
import { signIn, logOut } from '../api/auth';
import { COUNTRY_NOT_ALLOWED } from "../constants/errorKeys";
import { addToast } from "./toast";
import { RootState } from "./index";
import { LoginSubmitData } from "../types/Auth";
import { getResponseErrorMessage } from "../utils/getResponseErrorMessage";
import { checkIpCountry as checkIpCountryRequest } from 'api/country';

type InitialState = {
    token: string | null,
    isAuthorized: boolean,
    isLoading: boolean,
    isBlockedIpCountry: boolean,
    isError: boolean | null,
};

export const logout = createAsyncThunk('auth/logout', async (_, {rejectWithValue, dispatch}) => {
        try {
            await logOut()
            localStorage.removeItem('token');
            localStorage.clear();
        } catch (error: any) {
            dispatch(addToast({
                type: 'error',
                message: error.response.data.message,
                timeout: 3000,
            }));
            return rejectWithValue(error.response.data.message)
        }
    }
);

export const login = createAsyncThunk('auth/login', async (credentials: LoginSubmitData, {rejectWithValue, dispatch}) => {
        try {
            const response = await signIn(credentials)
            localStorage.setItem('token', response.data.token);

            return response.data.token
        } catch (error: any) {
            const interceptorException = error.response.data.message === COUNTRY_NOT_ALLOWED;
            if (!interceptorException) {
                dispatch(addToast({
                    type: 'error',
                    message: getResponseErrorMessage(error),
                    timeout: 3000,
                }));
            }
            return rejectWithValue(error.response.data.message)
        }
    }
);

export const checkIpCountry = createAsyncThunk(
    'auth/checkIpCountry',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await checkIpCountryRequest();

            return response.data['BLOCKED'] === 1;
        } catch (error) {
            dispatch(addToast({
                type: 'error',
                message: getResponseErrorMessage(error),
                timeOut: 3000,
            }));
            return rejectWithValue(error.response.data['MESSAGE']);
        }
    },
);


const initialState: InitialState = {
    token: null,
    isAuthorized: !!(sessionStorage.getItem('token') || localStorage.getItem('token')),
    isLoading: false,
    isBlockedIpCountry: false,
    isError: null,
};

const selectSelf = (state: RootState) => state.auth;
export const selectIsAuthorized = createSelector(selectSelf, state => state.isAuthorized);
export const selectIsAuthorizing = createSelector(selectSelf, state => state.isLoading);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuth() {
            return {
                token: null,
                isAuthorized: false,
                isLoading: false,
                isBlockedIpCountry: false,
                isError: null,
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isAuthorized = true;
            state.token = action.payload;
        });
        builder.addCase(login.rejected, (state) => {
            state.isLoading = false;
            state.isError = true
        });
        builder.addCase(logout.pending, () => initialState);
        builder.addCase(checkIpCountry.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(checkIpCountry.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isBlockedIpCountry = action.payload;
        });
        builder.addCase(checkIpCountry.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addMatcher(isAnyOf(logout.fulfilled, logout.rejected), (state) => {
            state.isAuthorized = false
        });
    },
});

export const { resetAuth } = authSlice.actions;

export const authReducer = authSlice.reducer;
