import cn from 'classnames';
import { useController } from 'react-hook-form';
import { InputField } from "../InputField/InputField";

export const FormInput = (props) => {
  const { field, formState } = useController(props);
  const onSelectChange = (value) => {
    field.onChange(value);
    props.onChange?.(value.target.value);
  };

  const fieldError = formState.errors?.[field.name]?.message || '';

  return (
    <InputField
      id={props.id}
      label={props.label}
      error={fieldError}
      tooltip={props.tooltip}
      className={cn('text-input', props.className, { required: props.rules?.required })}
    >
      <input
          {...field}
          {...props}
          className='input'
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={props.onFocus}
          onChange={onSelectChange}
          autoComplete={props.autoComplete ? props.autoComplete : ''}
          value={props.value || (field.value === null ? '' : field.value)}
      />
    </InputField>
  );
};
