import cn from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

type OptionProps = {
    title: string | ReactNode,
    icon: string,
    active: boolean,
    activeIcon: string,
    count?: number
}

type LayoutMenuOptionProps = OptionProps & {
    src?: string
}

const Option = ({ title, icon, active, activeIcon, count }: OptionProps) => {
    return (
        <div className={cn("layout-menu-option", {active})}>
            <div className="layout-menu-option-icon">
                <i className={`fi ${active ? activeIcon : icon} _fz-24`}/>
                {count ? <div className="badge">{count}</div> : null}
            </div>
            <div className="layout-menu-option-title">
                <div className="option-text">{title}</div>
            </div>
        </div>
    )
};

export const LayoutMenuOption = ({ src, ...props }: LayoutMenuOptionProps) => {
    if (src) {
        return <Link to={src} className="layout-menu-link"><Option {...props}/></Link>
    }

    return <Option {...props}/>
};