export const LitIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_398_5328)">
                <path d="M15.5024 10.7826C15.824 9.91598 16 8.97857 16 8.00001C16 7.02145 15.824 6.08404 15.5024 5.21742L8 4.52176L0.497594 5.21742C0.176 6.08404 0 7.02145 0 8.00001C0 8.97857 0.176 9.91598 0.497594 10.7826L8 11.4783L15.5024 10.7826Z" fill="#6DA544"/>
                <path d="M15.5024 5.21741C14.372 2.171 11.4397 0 7.99996 0C4.56025 0 1.6279 2.171 0.497559 5.21741H15.5024Z" fill="#FFDA44"/>
                <path d="M7.99996 16C11.4397 16 14.372 13.829 15.5024 10.7826H0.497559C1.6279 13.829 4.56025 16 7.99996 16Z" fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0_398_5328">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}