import './Burger.scss';

export const Burger = ({ onClick, opened }) => {
  return (
    <div className={`burger _flex _flex-col _flex-j-b ${opened ? 'active' : ''}`} onClick={onClick}>
      <span className="_w-100"></span>
      <span className="_w-100"></span>
      <span className="_w-100"></span>
    </div>
  );
};
