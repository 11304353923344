import { FormattedMessage } from "react-intl";

export const TRANSACTION_TABLE_COLUMNS = [
    {
        Header: <FormattedMessage id="table.th.transaction" defaultMessage="Transaction" />,
        accessor: 'transaction',
    },
    {
        Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
        accessor: 'type',
    },
    {
        Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
        accessor: 'amount',
    },
    {
        Header: <FormattedMessage id="page.finance.widthdraw.popup.paymentMethod" defaultMessage="Payment Method" />,
        accessor: 'paymentMethod',
    },
    {
        Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading Account" />,
        accessor: 'tradingAcc',
    },
    {
        Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
        accessor: 'dateTime',
    },
    {
        Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
        accessor: 'status',
    },
];

export const TRANSACTION_TABLE_COLUMNS_WITH_PAYMENTS = [
    {
        Header: <FormattedMessage id="table.th.transaction" defaultMessage="Transaction" />,
        accessor: 'transaction',
    },
    {
        Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
        accessor: 'type',
    },
    {
        Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
        accessor: 'amount',
    },
    {
        Header: <FormattedMessage id="page.finance.widthdraw.popup.paymentMethod" defaultMessage="Payment Method" />,
        accessor: 'paymentMethod',
    },
    {
        Header: <FormattedMessage id="table.th.gateway" defaultMessage="Gateway" />,
        accessor: 'gateway',
    },
    {
        Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading Account" />,
        accessor: 'tradingAcc',
    },
    {
        Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
        accessor: 'dateTime',
    },
    {
        Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
        accessor: 'status',
    },
];