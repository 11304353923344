import { RefObject, useState } from "react";
import { useEvent } from "../../hooks/useEvent";

export const useDropdown = (buttonRef: RefObject<HTMLDivElement>, closeOnClick: boolean) => {
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const handleSwitch = () => setDropdownOpened(o => !o);

    useEvent('click',(e: MouseEvent) => {
        if (dropdownOpened && buttonRef.current && !buttonRef.current.contains(e.target as Node)) {
            closeOnClick && setDropdownOpened(false);
        }
    });

    return {
        switchDropdown: handleSwitch,
        dropdownOpened,
        setDropdownOpened
    }
};
