import cn from "classnames";
import { Dropdown } from "../Dropdown/Dropdown";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectCurrentLanguage, setCurrentLang } from "../../store/locale";
import { langOptions } from "./options";
import './LangSwitcher.scss';

export const LangSwitcher = ({ closeOnSelect, onSelect }: { closeOnSelect?: boolean, onSelect?: () => void}) => {
    const dispatch = useAppDispatch();
    const currentLang = useAppSelector(selectCurrentLanguage);
    const options = Object.keys(langOptions).filter(option => langOptions[option].enabled);

    const handleSwitchLanguage = (lang) => () => {
        localStorage.setItem('lang', lang);
        dispatch(setCurrentLang(lang));
        onSelect?.();
    };

    return (
        <Dropdown
            className='lang-switcher'
            position='bottom-left'
            button={<>{langOptions[currentLang].icon || ''}<i className="fi-rr-caret-down _text-main"/></>}
            closeOnClick={closeOnSelect}
        >
            {options.map(option => {
                return (
                    <div className={cn('lang-switcher-option _flex _flex-a-c _fz-12', {active: currentLang === option})}
                         onClick={handleSwitchLanguage(option)} key={option}>
                        <div className="_flex-sh-0 _m-r-10">{langOptions[option].icon}</div>
                        {langOptions[option].label}
                    </div>)
            })}
        </Dropdown>
    )
}