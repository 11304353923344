import { ReactNode } from "react";

type HeaderCurrencyOptionProps = {
    title: string | ReactNode,
    titleMobile: string | ReactNode,
    value: string
}

export const HeaderCurrencyOption = ({ title, titleMobile, value }: HeaderCurrencyOptionProps) => {
    return (
        <div className="currency-option">
            <div className="currency-option-title">{title}</div>
            <div className="currency-option-title short">{titleMobile}</div>
            <div className="currency-option-value">{value}</div>
        </div>
    )
}