import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { usePaymentInfo } from '../../../../hooks/usePaymentInfo';

const PaymentsSuccess = () => {
  const paymentInfo = usePaymentInfo();
  return (
    <>
      <h1>
        <FormattedMessage id="page.payments.success" defaultMessage="Payment Success" />
      </h1>
      <div className="_flex _flex-col _flex-a-c">
        <div className="_text-c _fz-24 _m-b-15">
          <FormattedMessage
            id="page.payments.operaton_completed"
            defaultMessage="Operation completed successfully!"
          />
        </div>
        {paymentInfo ? (
          <div className="_text-c _m-b-15">
            <span>
              {paymentInfo?.amount} {paymentInfo?.currency}
            </span>{' '}
            <FormattedMessage
              id="page.payments.credited_to_acc"
              defaultMessage="credited to your trading account "
            />{' '}
            <span>{paymentInfo?.trading_account?.[0]?.name}</span> (
            {paymentInfo?.trading_account?.[0]?.trading_account})
          </div>
        ) : null}
        <Link to="/cryptocurrency" className="text-link">
          <FormattedMessage
            id="page.payments.goto_payments"
            defaultMessage="Go to Payments"
          />
        </Link>
      </div>
    </>
  );
};

export default PaymentsSuccess;
