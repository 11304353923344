import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../store";
import { selectHeaderInfo } from "../../../store/headerInfo";
import { HeaderCurrencyOption } from "./HeaderCurrencyOption";
import './CurrencyInfo.scss'

const mapCurrency = {
    USD: '$',
    EUR: '€'
};

export const CurrencyInfo = () => {
    const headerInfo = useAppSelector(selectHeaderInfo);
    return (
        <div className="header-currency-info _flex">
            {Object.keys(headerInfo.balance).map(item => {
                return (
                    <div className='currency _flex _flex-a-c' key={item}>
                        <div className="currency-icon _flex _flex-col _flex-xy-c">
                            <span>{mapCurrency[item] || item}</span>
                        </div>
                        <HeaderCurrencyOption
                            title={<FormattedMessage id="header.balance" defaultMessage="Balance"/>}
                            titleMobile={<FormattedMessage id="header.balance" defaultMessage="Balance"/>}
                            value={headerInfo.balance[item].total}
                        />
                        <HeaderCurrencyOption
                            title={<FormattedMessage id="header.creditBalance" defaultMessage="Credit Balance"/>}
                            titleMobile={<FormattedMessage id="header.creditBalanceMobile" defaultMessage="Credit"/>}
                            value={headerInfo.balance[item].credit}
                        />
                    </div>
                )
            })}
        </div>
    )
}