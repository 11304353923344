import { ReactNode } from 'react';
import './filter-tag.scss';

interface FilterTagProps {
  title?: string | ReactNode;
  children: ReactNode;
  onRemove: () => void;
}

export const FilterTag = ({ title, children, onRemove }: FilterTagProps) => {
  return (
  <div className="filter-tag">
    {title ? <div className="filter-tag__title">{title}</div> : null}
    {children}
    <div className="filter-tag__close" onClick={onRemove}>
      <i className="fi fi-rr-cross-small _flex _fz-12 cursor-pointer" />
    </div>
  </div>
  );
};
