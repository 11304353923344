import ChatListenerMiddleware from "./chat/effects";
import headerInfoReducer from "./headerInfo";
import chatReducer from "./chat/chatSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { languagesReducer } from './locale';
import { toastReducer } from "./toast";
import { authReducer } from "./auth";
import { paymentsReducer } from "./payments";
import { themeReducer } from "./theme";
import { modalReducer } from "./modal";
import { languagesQuery } from "./languagesQuery";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    payments: paymentsReducer,
    locale: languagesReducer,
    toast: toastReducer,
    header: headerInfoReducer,
    modal: modalReducer,
    chat: chatReducer,
    [languagesQuery.reducerPath]: languagesQuery.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .prepend(ChatListenerMiddleware.middleware)
      .concat(
          languagesQuery.middleware,
      ),
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
