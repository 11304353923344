import { ReactNode, useEffect } from "react";
import { useScrollBlock } from "../useScrollBlock";
import { useAppSelector } from "../../../store";
import { selectTheme } from "../../../store/theme";
import '../Modal.scss';

type ModalContainerProps = {
    children: ReactNode;
    disableBodyScroll: boolean;
    onOverlayClick: () => void;
};

export const ModalContainer = ({ disableBodyScroll, onOverlayClick, children }: ModalContainerProps) => {
    const [blockScroll, allowScroll] = useScrollBlock();
    const { mode } = useAppSelector(selectTheme);

    useEffect(() => {
        disableBodyScroll && blockScroll();
        return () => {
            disableBodyScroll && allowScroll();
        }
    }, [disableBodyScroll]);

    return (
        <div className="modal">
            <div className='modal-container'>
                {children}
            </div>
            <div className={`modal-overlay ${mode}`} onClick={onOverlayClick}/>
        </div>
    )
}