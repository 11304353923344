import { useRef, useState } from "react";
import queryString, { ParsedQuery } from "query-string";
import ReactTooltip from "react-tooltip";
import LoginImg from "../../../assets/img/login.jpg";
import PhoneInput from "../../../components/Form/PhoneInput/PhoneInput";
import CheckBox from "../../../components/Form/CheckBox/CheckBox";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, useWatch } from "react-hook-form";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useRegionValidation } from "../../../hooks/useRegionValidation";
import {
    EMAIL_INPUT_VALIDATION,
    PASSWORD_INPUT_VALIDATION,
    REQUIRED_FIELD_VALIDATION
} from "../../../utils/validators";
import { useAppDispatch, useAppSelector } from "../../../store";
import { FormInput } from "../../../components/Form/FormInput/FormInput";
import { Button } from "../../../components/Button/Button";
import { FormHeader } from "../_shared/FormHeader/FormHeader";
import { useGetProhibitedCountries } from "../../../hooks/useGetProhibitedCountries";
import { addToast } from "../../../store/toast";
import { COUNTRY_NOT_ALLOWED } from "../../../constants/errorKeys";
import { signUp } from "../../../api/auth";
import { selectCurrentLanguage } from "../../../store/locale";
import { AuthLayout } from "../_shared/AuthLayout/AuthLayout";
import { PasswordInput } from "../../../components/Form/PasswordInput/PasswordInput";
import { SelectInput } from "../../../components/Form/SelectInput/SelectInput";
import { useCountriesOptions } from "../../../hooks/useCountriesOptions";
import { selectTheme } from "../../../store/theme";
import cn from "classnames";
import { getResponseErrorMessage } from "../../../utils/getResponseErrorMessage";
import { selectHasRecaptcha } from "../../../store/payments";
import { Recaptcha } from "./Recaptcha";
import './signup.scss';

export const SignUp = () => {
    const intl = useIntl();
    const lang = useAppSelector(selectCurrentLanguage);
    const { sign_up } = useAppSelector(selectTheme);
    const hasRecaptcha = useAppSelector(selectHasRecaptcha);
    const recaptchaRef = useRef(null);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState(false);
    const {
        list: prohibitedCountries,
        iso: prohibitedCountriesCodes,
        loading: prohibitedCountriesLoading
    } = useGetProhibitedCountries();
    const countriesOptions = useCountriesOptions();

    const {
        handleSubmit,
        control,
        setValue,
        formState: {errors},
    } = useForm({ reValidateMode: 'onChange' });

    const phoneCountry = useWatch({control, name: 'country_id'});

    usePageTitle(intl.formatMessage({
        id: "auth.signUp.title",
        defaultMessage: "Sign Up",
    }));

    useRegionValidation();

    const onSubmit = async (data) => {
        setIsLoading(true);

        try {
            const query: ParsedQuery = queryString.parse(window.location.search);
            const response = await signUp({
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone || '---',
                password: data.password,
                country_id: data.country_id.value,
                not_citizen_usa: data.not_citizen_usa,
                policy: data.policy,
                adult: data.adult,
                referral_code: query.key,
                language_code: lang,
                captcha: data.captcha
            });

            if (!response.data.errors) {
                setRegistrationStatus(true);
            }
        } catch (error) {
            if (error && error.response.data !== null) {
                recaptchaRef.current.reset();
                setValue('captcha', null);

                const errors = error.response.data?.errors;
                const interceptorException = error.response.data.message === COUNTRY_NOT_ALLOWED;
                const errorMessage = [errors?.country_id?.[0], errors?.phone?.[0]].includes(COUNTRY_NOT_ALLOWED) ?
                    intl.formatMessage({
                        id: "auth.signUp.notAllowed",
                        defaultMessage: "Access is blocked due to the region where you are located. Please contact customer support.",
                    }) : getResponseErrorMessage(error);

                if (!interceptorException) {
                    dispatch(addToast({
                        type: 'error',
                        message: errorMessage,
                        timeout: 10000,
                    }));
                }
            }
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <AuthLayout
            className={cn("sign-up", {"registration-status": registrationStatus})}
            src={sign_up || LoginImg}
            pageTitle={<FormattedMessage id="auth.signUp.page_title" defaultMessage="We’re glad you have made your choice to join us!"/>}
            inverse={true}
        >
            <FormHeader title={<FormattedMessage id="auth.signUp.title" defaultMessage="Sign Up"/>}/>
            {!registrationStatus ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gap-16">
                        <div className="col c-sm-6">
                            <FormInput
                                name="first_name"
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="auth.signUp.fieldName.firstName"
                                        defaultMessage="First name"
                                    />
                                }
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 100,
                                        message: <FormattedMessage
                                            id="form.error.maxLength"
                                            defaultMessage="Maximum length is 100 characters"
                                            values={{NUMBER: 100}}
                                        />
                                    },
                                    ...REQUIRED_FIELD_VALIDATION,
                                }}
                            />
                        </div>
                        <div className="col c-sm-6">
                            <FormInput
                                name="last_name"
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="auth.signUp.fieldName.lastName"
                                        defaultMessage="Last name"
                                    />
                                }
                                control={control}
                                rules={{
                                    maxLength: {
                                        value: 250,
                                        message: <FormattedMessage
                                            id="form.error.maxLength"
                                            defaultMessage="Maximum length is 250 characters"
                                            values={{NUMBER: 250}}
                                        />
                                    },
                                    ...REQUIRED_FIELD_VALIDATION,
                                }}
                            />
                        </div>
                    </div>
                    <FormInput
                        name="email"
                        type="text"
                        label={
                            <FormattedMessage
                                id="auth.signUp.fieldName.eMail"
                                defaultMessage="E-mail"
                            />
                        }
                        control={control}
                        rules={{
                            maxLength: {
                                value: 256,
                                message: <FormattedMessage
                                    id="form.error.maxLength"
                                    defaultMessage="Maximum length is 256 characters"
                                    values={{NUMBER: 256}}
                                />
                            },
                            ...REQUIRED_FIELD_VALIDATION,
                            validate: EMAIL_INPUT_VALIDATION,
                        }}
                    />
                    <PasswordInput
                        name="password"
                        type="password"
                        label={
                            <FormattedMessage
                                id="auth.signUp.fieldName.password"
                                defaultMessage="Password"
                            />
                        }
                        control={control}
                        rules={{
                            maxLength: {
                                value: 100,
                                message: <FormattedMessage
                                    id="form.error.maxLength"
                                    defaultMessage="Maximum length is 100 characters"
                                    values={{NUMBER: 100}}
                                />
                            },
                            ...REQUIRED_FIELD_VALIDATION,
                            validate: PASSWORD_INPUT_VALIDATION,
                        }}
                    />
                    <div className="row gap-16">
                        <div className="col c-sm-6">
                            <SelectInput
                                name="country_id"
                                label={
                                    <FormattedMessage
                                        id="auth.signUp.fieldName.country"
                                        defaultMessage="Country"
                                    />
                                }
                                control={control}
                                rules={REQUIRED_FIELD_VALIDATION}
                                options={countriesOptions}
                                menuPosition="absolute"
                            />
                        </div>
                        <div className="col c-sm-6">
                            {!prohibitedCountriesLoading ?
                                <PhoneInput
                                    name="phone"
                                    type="text"
                                    label={
                                        <FormattedMessage
                                            id="auth.signUp.fieldName.phone"
                                            defaultMessage="Phone"
                                        />
                                    }
                                    control={control}
                                    country={
                                        phoneCountry && phoneCountry.iso_3166_2
                                            ? phoneCountry.iso_3166_2.toLowerCase()
                                            : 'gb'
                                    }
                                    exclude={prohibitedCountriesCodes}
                                    rules={REQUIRED_FIELD_VALIDATION}
                                /> : null}
                        </div>
                    </div>
                    {hasRecaptcha ? (
                        <div className='_m-y-15'>
                            <Recaptcha ref={recaptchaRef} setValue={setValue} />
                        </div>
                    ) : null}
                    <div className="_m-b-15">
                        <CheckBox
                            control={control}
                            name="policy"
                            type="checkbox"
                            label={(
                                <>
                                    <FormattedMessage 
                                        id="auth.signUp.fieldName.terms"
                                        defaultMessage={`By submitting information I confirm that I agree to the ${process.env.REACT_APP_COMPANY_NAME} Terms and Conditions and Privacy Policy and may be contacted about my application.`}
                                        values={
                                            {REACT_APP_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME}
                                        }
                                    />
                                </>
                            )}
                            id="policy"
                            labelNoClickable={true}
                            rules={{
                                required: true,
                            }}
                            errors={errors.policy}
                        />
                    </div>
                    <div className="_m-b-15">
                        <CheckBox
                            control={control}
                            name="adult"
                            type="checkbox"
                            label={
                                <>
                                    <FormattedMessage
                                        id="auth.signUp.fieldName.age"
                                        defaultMessage="I certify that I am over 18 and I am not a citizen of the prohibited countries."
                                    />
                                    <span data-tip data-for='prohibited_countries'><i className='fi fi-rr-interrogation'/></span>
                                    {/**@ts-ignore**/}
                                    <ReactTooltip id='prohibited_countries'>
                                        {prohibitedCountries.join(', ')}
                                    </ReactTooltip>
                                </>
                            }
                            // labelNoClickable={true}
                            id="adult"
                            rules={{
                                required: true,
                            }}
                            errors={errors.adult}
                        />
                    </div>
                    <div className="_m-b-40 _fz-12 _ff-roboto">
                        <FormattedMessage
                            id="auth.signUp.fieldName.overText"
                            defaultMessage="We care about your privacy. We do not sell or share your information with anyone else."
                        />
                    </div>
                    <Button tag='button' type='submit' className='_w-100 _m-b-40' isDisabled={isLoading}>
                        <FormattedMessage id="auth.signUp.btn" defaultMessage="Sign up"/>
                    </Button>
                </form>
            ) : (
                <div className="_ff-roboto">
                    <div className="title">
                        <FormattedMessage
                            id="letter.title"
                            defaultMessage="The letter was successfully sent."
                        />
                    </div>
                    <div>
                        <FormattedMessage
                            id="letter.dscr_1"
                            defaultMessage="Please check and confirm your email."
                        />
                    </div>
                </div>
            )}
            <div className="_flex _flex-xy-c _m-t-40 _ff-roboto _fz-14">
                <FormattedMessage
                    id="auth.signUp.descr.title"
                    defaultMessage="Already registered?"
                />
                <Link to="/" className='_p-l-5 _text-main'>
                    <FormattedMessage
                        id="auth.signUp.signInBtnText"
                        defaultMessage="Sign in here"
                    />
                </Link>
            </div>
        </AuthLayout>
    );
}
