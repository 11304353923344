import { useState } from "react";
import { useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { createTradingAccount } from '../../api/trading-account';
import { Button } from '../../components/Button/Button';
import { CheckBox } from '../../components/Form/CheckBox/CheckBox';
import { FormInput } from '../../components/Form/FormInput/FormInput';
import { SelectInput } from '../../components/Form/SelectInput/SelectInput';
import { CURRENCY } from '../../constants/deposit';
import { useAppDispatch, useAppSelector } from '../../store';
import { addToast } from '../../store/toast';
import { getResponseErrorMessage } from '../../utils/getResponseErrorMessage';
import { REQUIRED_FIELD_VALIDATION } from '../../utils/validators';
import { selectPaymentsEnableWebTrader } from "../../store/payments";

type AddAccountTypes = {
  account_name: string,
  amount_type: string,
  demo?: number,
}

export const ADD_ACCOUNT_MODAL_KEY = 'ADD_ACCOUNT_MODAL_KEY';

const AddAccountModal = ({ closeModal, onSuccess }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const ENABLE_WT = useAppSelector(selectPaymentsEnableWebTrader);

  const { handleSubmit, control } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      amount_type: '',
      account_name: '',
      demo: 0,
    },
  });
  const fields = useWatch({ control });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const sendData: AddAccountTypes = {
      ...data,
      amount_type: data.amount_type.value
    };

    if (ENABLE_WT) {
      sendData['demo'] = data.demo ? 1 : 0;
    }

    createTradingAccount(sendData, null)
      .then(() => {
        dispatch(
          addToast({
            type: 'success',
            title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
            message: intl.formatMessage({ id: 'trading.account.created.text' }),
            timeout: 5000,
          }),
        );
        onSuccess();
        closeModal();
      })
      .catch((err) => {
        dispatch(
          addToast({
            type: 'error',
            message: getResponseErrorMessage(err),
            timeOut: 5000,
          }),
        );
        setIsSubmitting(false);
      });
  };

  const options = Object.keys(CURRENCY).map((item) => ({
    value: item,
    label: item,
  }));
  
  return (
    <div className="add-acc-modal">
      <div className="modal-template-content-subtitle">
        <FormattedMessage id="newAccountPopup.descr" defaultMessage="Enter account information" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectInput
          id="currency_add_account_modal"
          name="amount_type"
          label={
            <FormattedMessage id="newAccountPopup.field.currency.title" defaultMessage="Currency" />
          }
          control={control}
          rules={REQUIRED_FIELD_VALIDATION}
          options={options}
        />
        <FormInput
          name="account_name"
          type="text"
          placeholder={intl.formatMessage({
            id: "newAccountPopup.field.accountName.title",
            defaultMessage: "Account Name",
          })}
          label={
            <FormattedMessage
              id="newAccountPopup.field.accountName.title"
              defaultMessage="Account Name"
            />
          }
          control={control}
          rules={{
            required: (
              <FormattedMessage
                id="form.error.requiredFieldnoName"
                defaultMessage="Field is required"
              />
            ),
          }}
        />
        {ENABLE_WT ? (
          <CheckBox
            control={control}
            name="demo"
            id="demo"
            type="checkbox"
            label={
              <FormattedMessage id="newAccountPopup.demoCheckbox" defaultMessage="Demo account" />
            }
          />
        ) : null}
        <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
          <Button buttonType="outline" className="_m-r-15" onClick={closeModal}>
            <FormattedMessage id="newAccountPopup.cancelBtn" defaultMessage="Cancel" />
          </Button>
          <Button tag="button" type="submit" isDisabled={isSubmitting || Object.values(fields).every(value => !value)}>
            <FormattedMessage id="newAccountPopup.btn" defaultMessage="Create" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddAccountModal;
