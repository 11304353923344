import { useMemo } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import Table from '../../../../components/Table/Table';
import { TABLE_DEALS_COLUMNS_NAMES } from './DealsTableColumns';
import { makeTableData } from '../../../../utils/makeTableData';
import { Deal, TradingTableProps } from '../../../../types/Trading';

import './deals-table.scss';

export const DealsTable = ({ data, perPage, showLoader }: TradingTableProps<Deal>) => {

  const prepearedTableData = useMemo(() => makeTableData(data, data.length).map((item: Deal) => {
    const {
      close_price,
      open_price,
      close_time_dt,
      deal,
      profit,
      symbol,
      volume,
      account,
      side,
      demo
    } = item;
    return {
      account: (
        <span>
          {demo === 1 && (<span className="account-table__demo">
                            <FormattedMessage id="table.th.demo" defaultMessage="DEMO" />
                          </span>)}
          {account}
        </span>
      ),
      side:(
        <span className={side}>{side}</span>
      ),
      deal,
      symbol,
      open_price,
      close_price,
      volume,
      profit,
      close_time: (
        <div className="date-cell-row">
          <span>{moment(close_time_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
          <span className="_grey-title">{moment(close_time_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}</span>
        </div>
      ),
    };
  }), [data]);

  return (
    <Table
      columns={TABLE_DEALS_COLUMNS_NAMES}
      data={prepearedTableData}
      perPage={perPage}
      showLoader={showLoader}
      getCellProps={() => ({style: {}})}
      className="deals-table"
    />
  );
};
