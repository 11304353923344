import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../store";
import { addToast } from "../../../store/toast";
import { resetPassword } from "../../../api/auth";
import { FormInput } from "../../../components/Form/FormInput/FormInput";
import { FormHeader } from "../_shared/FormHeader/FormHeader";
import { Button } from "../../../components/Button/Button";
import { EMAIL_INPUT_VALIDATION, REQUIRED_FIELD_VALIDATION } from "../../../utils/validators";
import { SimpleLayout } from "../_shared/SimpleLayout/SimpleLayout";
import { getResponseErrorMessage } from "../../../utils/getResponseErrorMessage";
import { Link } from "react-router-dom";

export const PasswordForgot = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [requestStatus, setRequestStatus] = useState(false);

    const intl = useIntl();
    const dispatch = useAppDispatch();

    const {handleSubmit, control} = useForm({reValidateMode: 'onChange'});

    usePageTitle(intl.formatMessage({id: "auth.resetPassword.title", defaultMessage: "Reset password"}));

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            const response = await resetPassword(data);
            setIsLoading(false);
            setRequestStatus(true);
            dispatch(addToast({
                type: 'success',
                message: response.data.message,
                timeout: 3000,
            }));
        } catch (error) {
            setIsLoading(false);
            dispatch(addToast({
                type: 'error',
                message: getResponseErrorMessage(error),
                timeout: 3000,
            }));
        }
    };

    return (
        <SimpleLayout>
            <FormHeader
                title={<FormattedMessage id='auth.resetPassword.title' defaultMessage='Reset password'/>}
                backUrl='/'
            />
            {!requestStatus ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormInput
                        name='email'
                        type='email'
                        label='E-mail'
                        control={control}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            validate: EMAIL_INPUT_VALIDATION,
                        }}
                    />
                    <div className="_m-t-50">
                        <Button isDisabled={isLoading} tag='button' type='submit' className='_w-100'>
                            <FormattedMessage
                                id='auth.resetPassword.btnText'
                                defaultMessage='Reset password'
                            />
                        </Button>
                    </div>
                    <div className="_flex _flex-xy-c _m-t-40 _ff-roboto _fz-14">
                        <FormattedMessage
                            id='auth.signIn.singUp.doNotHaveAccount'
                            defaultMessage='Do not have an account yet?'
                        />
                        <Link to='/sign-up' className='_p-l-5 _text-main'>
                            <FormattedMessage id='auth.signIn.singUp.btn' defaultMessage='Sign up here'/>
                        </Link>
                    </div>
                </form>
            ) : (
                <>
                    <div className='_m-b-15 _ff-roboto _text-accent _fz-16 _fw-600'>
                        <FormattedMessage id='letter.title' defaultMessage='The letter was successfully sent.'/>
                    </div>
                    <div className="_fz-14">
                        <FormattedMessage id='letter.dscr' defaultMessage='Please check.'/>
                    </div>
                </>
            )}
        </SimpleLayout>
    )
}