import { Dispatch } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { FILE_MAX_SIZE, FILE_TYPES } from '../../../constants/file-upload';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { Button } from "../../Button/Button";
import { useAppDispatch } from "../../../store";
import { addToast } from "../../../store/toast";
import './FileInput.scss';

type FileInputProps = {
    name: string;
    isMultiple?: boolean,
    onChange?: (data: any[]) => void,
    onSubmit: () => void,
    setFile: Dispatch<any>,
    files: any[],
    isDisabled: boolean,
    progress: number
}

export const FileInput = ({
                               name,
                               onChange,
                               onSubmit,
                               setFile,
                               files,
                               isDisabled,
                               progress,
                               isMultiple = false
                           }: FileInputProps) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const handleChange = (fileOrFileList) => {
        if (!fileOrFileList) {
            return;
        }
        const fileArr = [];
        if (isMultiple) {
            for (const key of Object.keys(fileOrFileList)) {
                fileArr.push(fileOrFileList[key]);
            }
        } else {
            fileArr.push(fileOrFileList);
        }
        setFile(fileArr);
        onChange?.(fileArr)
    };

    const deleteFile = (pos) => {
        if (!files || !files.length) {
            setFile(null)
            return;
        }
        const arr = files.filter((item, index) => index !== pos);
        if (!arr.length) {
            setFile(null);
            return;
        }
        setFile(arr);
    };

    const onSizeError = () => {
        dispatch(addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'file.upload.size.error.title' }),
            message: intl.formatMessage({id: 'file.upload.size.error.text'}).replace('{SIZE}', FILE_MAX_SIZE),
            timeout: 3000
        }));
    };

    const onTypeError = () => {
        dispatch(addToast({
            type: 'error',
            title: intl.formatMessage({ id: 'file.upload.type.error.title' }),
            message: intl.formatMessage({id: 'file.upload.type.error.text'}).replace('{TYPES}', FILE_TYPES.join(', ')),
            timeout: 3000
        }));
    };

    return (
        <div className='file-uploader _flex _flex-col _flex-a-c _text-c _p-15'>
            <form onSubmit={(e) => e.preventDefault()}>
                <FileUploader
                    name={`file_input_${name}`}
                    classes='drop_area drop_zone'
                    fileOrFiles={files}
                    multiple={isMultiple}
                    maxSize={FILE_MAX_SIZE}
                    types={FILE_TYPES}
                    onSizeError={onSizeError}
                    onTypeError={onTypeError}
                    handleChange={handleChange}
                ><></></FileUploader>
                <div className='file-input-decor _flex _flex-col _flex-xy-c _lh-20 _fz-14'>
                    <i className='fi-rr-cloud-upload _flex _m-x-auto _fz-30 _text-main'/>
                    <div className='file-uploader-title _fw-600 _fz-20 _text-main'>
                        <FormattedMessage id='fileUploader.title' defaultMessage='Drag & Drop to Upload file'/>
                    </div>
                </div>
                {files?.length ? (
                    <div className='_flex _flex-col _flex-a-c'>
                        {progress !== 0 ? (
                            <div className='progress-bar _m-b-15'>
                                <div className='upload-progress' style={{width: `${progress}%`}}/>
                                <div className='progress-bar-status'>{progress}%</div>
                            </div>
                        ) : null}
                        <div className='_flex _flex-wrap _m-b-25'>
                            {files.map((file, i) => (
                                <div key={file.name} className='file'>
                                    <i className="fi fi-rr-cross _m-r-10" onClick={() => deleteFile(i)}/>
                                    <span>{file.name}</span>
                                </div>
                            ))}
                        </div>
                        <Button tag='button' isDisabled={isDisabled} className="submit-files" onClick={onSubmit}>
                            <FormattedMessage id='fileUploader.buttonSend' defaultMessage='Send'/>
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className="file-uploader-title-divider">
                            <FormattedMessage id='fileUploader.or' defaultMessage='OR'/>
                        </div>
                        <Button className='file-uploader-button' buttonType="outline">
                            <FormattedMessage id='fileUploader.button' defaultMessage='Browse files'/>
                            <i className="fi fi-rr-add-folder _flex _m-l-5"/>
                        </Button>
                    </>
                )}
            </form>
            {!files ?
                <div className='_ff-roboto _m-t-auto'>
                    <FormattedMessage
                        id='fileUploader.text.type'
                        defaultMessage='Supported files are .DOC, .DOCX, .PDF, .JPG, .PNG, .ODT. The maximum size of a single file is 16 Mb.'
                    />
                </div> : null}
        </div>
    );
};
