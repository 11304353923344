import cn from "classnames";
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import './checkbox.scss';

export const CheckBox = (props) => {
  const { field } = useController(props);
  const { label } = props;
  const [checked, setCheck] = useState(field.value);

  useEffect(() => {
    field.onChange(checked);
  }, [checked]);

  return (
    <div className="checkbox__icon">
      <div className={cn("input-wrap _ff-roboto", { error: props.errors })}>
        <i
          className={cn("fi _fz-16 _text-main _m-r-10", { "fi-rr-checkbox": checked, "fi-rr-square": !checked})}
          onClick={() => {
            setCheck(!checked);
          }}
        ></i>
        <input
          {...field}
          id={props.id}
          className="checkbox-input"
          type={props.type}
          placeholder={props.placeholder}
          defaultChecked={props.checked}
        />
        <div className={`label-wrap ${props.labelNoClickable ? 'no-click' : ''}`}>
          {!props.labelNoClickable && (
            <label
              htmlFor={props.id}
              className='input__label'
            >
                <span
                  onClick={() => {
                    setCheck(!checked);
                  }}
                >
                  {label}
                </span>
              {props.rules && props.rules.required ? <span className="required">*</span> : ''}
            </label>
          )}

          {props.labelNoClickable && (
            <>
              <div
                className={
                  props.errors ? 'input__label-no-click error' : 'input__label-no-click'
                }
              >
                {label}
                {props.rules && props.rules.required ? (
                  <span className="required">*</span>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
