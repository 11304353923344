import { AuthPageHeader } from "../AuthPageHeader/AuthPageHeader";
import { ReactNode } from "react";
import classNames from "classnames";
import './AuthLayout.scss'

type AuthLayoutProps = {
    src: string,
    pageTitle: ReactNode,
    children: ReactNode,
    inverse?: boolean,
    className?: string
}

export const AuthLayout = ({src, pageTitle, inverse, className = '', children}: AuthLayoutProps) => {
    return (
        <div className={classNames('auth-layout _flex', className, { inverse })}>
            <AuthPageHeader inverse={inverse}/>
            <div className='auth-layout-title _flex _flex-col _flex-j-c _pos-r _w-50 _h-100vh'>
                <img src={src} className='_object-fit' alt=''/>
                <span className="_fw-700 _pos-r">{pageTitle}</span>
            </div>
            <div className="auth-layout-form-wrap _flex _flex-col _flex-xy-c _w-50 _h-100vh">
                <div className="auth-layout-overflow-wrap">
                    <div className="auth-layout-form _p-x-15">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}