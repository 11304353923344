import { useEffect, useState } from "react";
import { getCountryList } from "../api/country";

interface ICountry {
    value: number,
    label: string,
    iso_3166_2: string,
}

export const useCountriesOptions = (currency?: string) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const getOptions = async () => {
            try {
                const response = await getCountryList(currency);
                const opt = (response?.data?.data || []).map((opt):ICountry => ({
                    value: opt.id,
                    label: opt.name,
                    iso_3166_2: opt.iso_3166_2,
                }));

                setOptions(opt);
            } catch (e) {
                console.log('Error in useCountriesOptions: ', e?.data?.message);
            }
        };

        getOptions();
    }, [currency]);

    return options
}