import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IPaymentInfo } from "../types/payments";
import { getPaymentById } from "../api/payments-currency";

export const usePaymentInfo = () => {
    const [paymentInfo, setPaymentInfo] = useState<Partial<IPaymentInfo> | null>(null);
    const params = useParams();

    const getPaymentByUser = () => {
        if (params.id) {
            getPaymentById(params.id)
                .then(res => {
                    if (res.status === 200) {
                        setPaymentInfo(res.data.data)
                    }
                })
                .catch(err => console.log(err))
        }
    };

    useEffect(() => {
        getPaymentByUser();
    }, []);

    return paymentInfo;
}