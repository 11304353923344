import { useRef, useState } from 'react';
import cn from "classnames";
import moment from 'moment';
import { useController } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import { InputField } from "../InputField/InputField";
import { useOnClickOutside } from "../../../hooks/useClickOutside";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const CalendarPicker = (props) => {
    const {field, formState} = useController(props);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const handleSelect = (date) => {
        const dateSelect = moment(date).valueOf();
        field.onChange(dateSelect);
    };

    const contentRef = useRef(null);
    useOnClickOutside(contentRef, () => {
        setShowDatePicker(false);
    });

    const fieldError = formState.errors?.[field.name]?.message || '';

    return (
        <InputField
            id={props.id}
            label={props.label}
            error={fieldError}
            tooltip={props.tooltip}
            className={cn('calendar-picker', props.className, {required: props.rules?.required})}
        >
            <input
                className='input'
                type="text"
                value={field.value ? moment(field.value).format('DD.MM.YYYY') : null}
                onFocus={() => setShowDatePicker(true)}
                disabled={props.disabled}
            />
            <i className="fi fi-rr-calendar"/>
            {showDatePicker && (
                <div className='date-range' ref={contentRef}>
                    <Calendar onChange={(e) => handleSelect(e)} date={field.value ? new Date(field.value) : null}/>
                </div>
            )}
        </InputField>
    );
};

export default CalendarPicker;