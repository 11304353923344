import { PortalDropdown } from "../../../components/Dropdown/Dropdown";
import { DropdownOption } from "../../../components/Dropdown/DropdownOption";
import { FormattedMessage } from "react-intl";
import { ThemeSwitcher } from "../../../components/ThemeSwitcher/ThemeSwitcher";
import { LangSwitcher } from "../../../components/LangSwitcher/LangSwitcher";
import { useRef } from "react";
import { useDropdown } from "../../../components/Dropdown/useDropdown";
import { logout } from "../../../store/auth";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { setMode } from "../../../store/theme";
import { useEvent } from "../../../hooks/useEvent";
import './ProfileDropdown.scss';

export const ProfileDropdown = () => {
    const dispatch = useAppDispatch();
    const buttonRef = useRef(null);
    const nestedDropdownRef = useRef(null);
    const navigate = useNavigate();
    const {dropdownOpened, switchDropdown, setDropdownOpened} = useDropdown(buttonRef, false);

    useEvent('click',(e: MouseEvent) => {
        if (dropdownOpened) {
            const dropdownButtonClick = buttonRef.current && buttonRef.current.contains(e.target as Node);
            const nestedDropdownClick = nestedDropdownRef.current && nestedDropdownRef.current.contains(e.target as Node);
            if (!dropdownButtonClick && !nestedDropdownClick) {
                setDropdownOpened(false);
            }
        }
    });

    const handleLogOut = () => {
        dispatch(logout()).finally(() => {
            dispatch(setMode('light'));
            navigate('/')
        });
    };

    const handleNavigateToPersonal = () => {
        navigate('/personal')
    };

    return (
        <>
            <div className="profile-avatar _flex-sh-0" onClick={switchDropdown} ref={buttonRef}>
                <i className="fi fi-rr-circle-user _text-main _flex"/>
            </div>
            {dropdownOpened ? (
                <PortalDropdown
                    positionY={buttonRef?.current?.offsetTop}
                    positionX={buttonRef?.current?.offsetLeft}
                    className="header-profile-dropdown"
                    position='bottom-left'>
                    <DropdownOption
                        icon='fi-rr-user'
                        title={<FormattedMessage id="menu.profile" defaultMessage=" My profile"/>}
                        onSelect={handleNavigateToPersonal}
                    />
                    <DropdownOption
                        icon='fi-rr-sign-out-alt'
                        title={<FormattedMessage id="menu.logout" defaultMessage="Logout"/>}
                        onSelect={handleLogOut}
                    />
                    <div className="dropdown-footer" ref={nestedDropdownRef}>
                        <div className="dropdown-footer-divider"/>
                        <div className="_flex _flex-j-b _flex-a-c">
                            <ThemeSwitcher/>
                            <LangSwitcher closeOnSelect={true} onSelect={() => setDropdownOpened(false)}/>
                        </div>
                    </div>
                </PortalDropdown>
            ) : null}
        </>
    )
}