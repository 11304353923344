import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import { REQUIRED_FIELD_VALIDATION } from "../../utils/validators";
import { DepositAmountOptions } from "../../constants/finance";
import { Button } from "../../components/Button/Button";
import { useAppDispatch } from "../../store";
import { addToast } from "../../store/toast";
import { transferFunds } from "../../api/deposit";
import { useForm, useWatch } from "react-hook-form";
import { AmountInput } from "../../components/Form/AmountInput/AmountInput";
import { useGetTradingAccounts } from "../../hooks/useGetTradingAccounts";
// import { useWithdrawAvailability } from "../../hooks/useWithdrawAvailability";
import { formatAccountSelectOptions } from "../../components/Form/_helpers/formatAccountSelectOptions";
import { AccountSelectOption } from "../../components/Form/_helpers/AccountSelectOption";
import { getResponseErrorMessage } from "../../utils/getResponseErrorMessage";

export const TRANSFER_MODAL_KEY = 'TRANSFER_MODAL_KEY';

export const TransferModal = ({closeModal}) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const {control, handleSubmit, setValue} = useForm();
    const {amount, fromTransfer, toTransfer} = useWatch({control});

    const { tradingAccounts: accountsData } = useGetTradingAccounts();
    // TODO: Replace the amount validation with availableWithdraw?.available_balance_amount
    // const availableWithdraw = useWithdrawAvailability(fromTransfer);

    const [isLoading, setLoadStatus] = useState(false);
    const [accountsTo, setAccountsTo] = useState(accountsData);
    const [accountsFrom, setAccountsFrom] = useState(accountsData);

    useEffect(() => {
        if (!fromTransfer && !toTransfer) {
            setAccountsTo(accountsData);
            setAccountsFrom(accountsData);
        }

        if (fromTransfer) {
            setAccountsTo(accountsData.filter(item =>
                item.trading_account !== fromTransfer.data.trading_account && item.amount_type
                === fromTransfer.data.amount_type,
            ));
        }

        if (toTransfer) {
            setAccountsFrom(accountsData.filter(item =>
                item.trading_account !== toTransfer.data.trading_account && item.amount_type
                === toTransfer.data.amount_type,
            ));
        }

        if (fromTransfer && toTransfer) {
            setAccountsFrom(accountsData.filter(item =>
                item.trading_account !== toTransfer.data.trading_account
            ));
        }
    }, [fromTransfer, toTransfer, accountsData]);

    const onSubmit = (data) => {
        setLoadStatus(true);
        transferFunds({
            amount: data.amount,
            from_trading_account: fromTransfer.data.trading_account,
            to_trading_account: toTransfer.data.trading_account,
        })
            .then(() => {
                dispatch(addToast({
                    type: 'success',
                    title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
                    message: intl.formatMessage({id: 'transaction.transfer.alert.created'}),
                    timeout: 3000
                }));
                closeModal();
            })
            .catch((err) => {
                dispatch(addToast({
                    type: 'error',
                    message: getResponseErrorMessage(err),
                    timeout: 5000,
                }))
            })
            .finally(() => {
                    setLoadStatus(false);
                },
            );
    };

    return (
        <div className="transfer-modal">
            <div className="modal-template-content-subtitle">
                <FormattedMessage
                    id="page.finance.transfer.popup.descr.text"
                    defaultMessage="Transfered funds will immediately be converted and credited to your trading account."
                />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SelectInput
                    id="fromTransfer_transfer_modal"
                    name="fromTransfer"
                    label={
                        <FormattedMessage
                            id="page.finance.transfer.field.fromTradingAccount.title"
                            defaultMessage="From trading account"
                        />
                    }
                    options={formatAccountSelectOptions(accountsFrom)}
                    formatOptionLabel={AccountSelectOption}
                    control={control}
                    rules={REQUIRED_FIELD_VALIDATION}
                    maxMenuHeight={180}
                    onChange={() => {
                        setValue('amount', 0);
                        setValue('toTransfer', null);
                    }}
                />
                <SelectInput
                    id="toTransfer_transfer_modal"
                    name="toTransfer"
                    label={
                        <FormattedMessage
                            id="page.finance.transfer.field.toTradingAccount.title"
                            defaultMessage="To trading account"
                        />
                    }
                    options={formatAccountSelectOptions(accountsTo)}
                    formatOptionLabel={AccountSelectOption}
                    control={control}
                    rules={REQUIRED_FIELD_VALIDATION}
                    maxMenuHeight={172}
                />
                <AmountInput
                    id="amount_transfer_modal"
                    name="amount"
                    label={
                        <FormattedMessage
                            id="page.finance.deposit.popup.field.amount.title"
                            defaultMessage="Amount"
                        />
                    }
                    control={control}
                    currency={fromTransfer?.data?.amount_type}
                    rules={{
                        ...REQUIRED_FIELD_VALIDATION,
                        // validate: (value) => value <= availableWithdraw?.available_balance_amount ||
                        validate: (value) => value <= Number(fromTransfer?.data?.balance?.replace(',', '')) ||
                            <FormattedMessage
                                id="page.finance.transfer.popup.validation"
                                defaultMessage="Not enough funds"
                            />
                    }}
                    setValue={setValue}
                    buttons={DepositAmountOptions}
                    // details={
                    //     availableWithdraw &&
                    //     `${intl.formatMessage({
                    //         id: 'withdraw.available',
                    //         defaultMessage: 'Available',
                    //     })}: ${availableWithdraw.available_balance_amount}`
                    // }
                />
                <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
                    <Button buttonType="outline" className="_m-r-15" onClick={closeModal}>
                        <FormattedMessage id="page.finance.deposit.popup.btn.cancel" defaultMessage="Cancel"/>
                    </Button>
                    <Button
                        tag="button"
                        type="submit"
                        isDisabled={isLoading || !amount || !fromTransfer || !toTransfer}
                        showSpinner={isLoading}
                    >
                        <FormattedMessage id="page.finance.deposit.popup.btn.checkout" defaultMessage="Сheckout"/>
                    </Button>
                </div>
            </form>
        </div>
    )
}