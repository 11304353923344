import axios from "axios";
import { useEffect, useState } from "react";


export const useIp = () => {
    const [ip, setIp] = useState(null);

    useEffect(() => {
        const getIp = async () => {
            try {
                const ipResponse = await axios.get('https://geolocation-db.com/json/');
                setIp(ipResponse?.data?.IPv4 === 'Not found' ? null : ipResponse.data.IPv4);
            } catch (error: any) {
                console.log('Failed to load IP address')
            }
        }
        getIp()
    }, []);

    return ip
}