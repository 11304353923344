import { FormattedMessage } from "react-intl";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectPaymentsEnableWebTrader } from "../../../../store/payments";
import { Table } from "../../../../components/Table";
import { makeTableData } from "../../../../utils/makeTableData";
import { Button } from "../../../../components/Button/Button";
import { openModal } from "../../../../store/modal";
import { useAppDispatch } from "../../../../store";
import { DEPOSIT_MODAL_KEY } from "../../../../modals/DepositModal/DepositModal";
import { ACCOUNT_PASSWORD_MODAL_KEY } from "../../../../modals/AccountChangePasswordModal/AccountChangePasswordModal";
import { TABLE_ACCOUNTS_COLUMNS_NAMES, TABLE_ACCOUNTS_COLUMNS_NAMES_WITH_WEBTRADER } from "./AccountsTableColumns";
import { TRADING_ACCOUNT_STATUS } from "../../../../constants/trading-account-status";
import { Account, TradingTableProps } from "../../../../types/Trading";

import "./accounts-table.scss";

export const AccountsTable = ({ data, perPage, showLoader }: TradingTableProps<Account>) => {
  const ENABLE_WT = useSelector(selectPaymentsEnableWebTrader);
  const dispatch = useAppDispatch();
  const columns = useMemo(() => ENABLE_WT ? TABLE_ACCOUNTS_COLUMNS_NAMES_WITH_WEBTRADER : TABLE_ACCOUNTS_COLUMNS_NAMES, [ENABLE_WT]);

  const preparedTableData = useMemo(() =>
    makeTableData(data, data.length).map((item: Account) => {
    const { trading_account, name, balance, demo, credit, amount_type, status, leverage } = item;

    return {
      tradingAcc: (
        <span className="account-table__transaction__id">
          <span className={`status-badge ${status}`}/>
          <span>{name || <FormattedMessage id="trading.accounts.no_name" defaultMessage="No name" />}</span>
        </span>
      ),
      login: (
        <span>
          {demo === 1 && (<span className="account-table__demo">
                            <FormattedMessage id="table.th.demo" defaultMessage="DEMO" />
                          </span>)}
          {trading_account}
        </span>
      ),
      balance: balance + ' ' + amount_type,
      credit:`${credit ? credit : '0.00'} ${amount_type}`,
      liverage: leverage,
      demo: (<span>{demo === 1 ? <FormattedMessage id="table.th.yes" defaultMessage="Yes" /> : <FormattedMessage id="table.th.no" defaultMessage="No" />}</span>),
      controls: status === TRADING_ACCOUNT_STATUS.ACTIVE ?
        <div className="table-buttons">
          <i className="fi fi-rr-lock cursor-pointer" onClick={
            () => dispatch(openModal({ key: ACCOUNT_PASSWORD_MODAL_KEY, data: item}))
          }/>
          <Button onClick={() => dispatch(openModal({ key: DEPOSIT_MODAL_KEY, data: {
            trading_account_id: item.trading_account,
            amount: 0,
            amount_type: item.amount_type
          }}))}>
            <FormattedMessage id="page.finance.deposit.btn" defaultMessage="Deposit" />
          </Button>
        </div> : null
    };
  }), [data]);

  return (
    <Table
      columns={columns}
      data={preparedTableData}
      perPage={perPage}
      showLoader={showLoader}
      className="account-table"
      getCellProps={() => ({style: {}})}
    />
  );
};

export default AccountsTable;
