import { TradingFilter } from '../types/Trading';

export const getDealsFilterParams = (filter) => {
  let filterQuery = '';
  if (filter.trading_account) {
    filterQuery += `&trading_account=${filter.trading_account?.value}`;
  }
  if (filter.symbol) {
    filterQuery += `&symbol=${filter.symbol.value}`;
  }
  if (filter.close) {
    filterQuery = `${filterQuery}&close[from]=${filter.close.from}&close[to]=${filter.close.to}`;
  }
  return filterQuery;
};

export const getHistoryFilterParams = (filter: TradingFilter) => {
  let filterQuery = '';
  if (filter.trading_account) {
    filterQuery += `&trading_account=${filter.trading_account.value}`;
  }
  if (filter.open) {
    filterQuery += `&open[from]=${Number(filter.open.from) * 1000}&open[to]=${
      Number(filter.open.to) * 1000
    }`;
  }
  if (filter.close) {
    filterQuery += `&close[from]=${Number(filter.close.from) * 1000}&close[to]=${
      Number(filter.close.to) * 1000
    }`;
  }
  return filterQuery;
};

export const getOpenedPositionsFilterParams = (filter: TradingFilter): string => {
  let filterQuery = '';
  if (filter.trading_account) {
    filterQuery += `&trading_account=${filter.trading_account?.value}`;
  }
  if (filter.symbol) {
    filterQuery += `&symbol=${filter.symbol.value}`;
  }
  if (filter.open) {
    filterQuery += `${filterQuery}&open[from]=${filter.open.from}&open[to]=${filter.open.to}`;
  }
  return filterQuery;
};
