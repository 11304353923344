import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useForm, useWatch } from "react-hook-form";
import { useAppDispatch } from "../../../store";
import { addToast } from "../../../store/toast";
import { newPassword } from "../../../api/auth";
import { Button } from "../../../components/Button/Button";
import {
    PASSWORD_CONFIRM_INPUT_VALIDATION,
    PASSWORD_INPUT_VALIDATION,
    REQUIRED_FIELD_VALIDATION
} from "../../../utils/validators";
import { SimpleLayout } from "../_shared/SimpleLayout/SimpleLayout";
import { Link, useNavigate } from 'react-router-dom';
import { PasswordInput } from "../../../components/Form/PasswordInput/PasswordInput";
import { ResetPasswordData } from "../../../types/Auth";
import { FormHeader } from "../_shared/FormHeader/FormHeader";
import { getResponseErrorMessage } from "../../../utils/getResponseErrorMessage";

export const PasswordReset = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const intl = useIntl();
    const {handleSubmit, control} = useForm<ResetPasswordData>({
        reValidateMode: 'onChange',
        defaultValues: {password: '', password_confirmation: ''}
    });
    //@ts-ignore
    const password = useWatch({name: 'password', control});

    usePageTitle(intl.formatMessage({
        id: 'auth.resetPassword.title',
        defaultMessage: 'Reset password'
    }));

    const onSubmit = async (data) => {
        setIsLoading(true);
        try {
            // 'https://domain/password/reset/:email/:code'
            const url: string[] = window.location.href.split('/');
            const response = await newPassword({
                email: url[5],
                code: url[6],
                password: data.password,
                password_confirmation: data.password_confirmation,
            });

            dispatch(addToast({
                type: 'success',
                message: response.data.message,
                timeout: 3000,
            }));

            setTimeout(() => {
                setIsLoading(false);
                navigate('/');
            }, 1000);
        } catch (error) {
            setIsLoading(false);
            dispatch(addToast({
                type: 'error',
                message: getResponseErrorMessage(error),
                timeout: 3000,
            }));
        }
    };

    return (
        <SimpleLayout>
            <FormHeader title={<FormattedMessage id='auth.resetPassword.title' defaultMessage='Reset password'/>}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <PasswordInput
                    name="password"
                    label={
                        <FormattedMessage
                            id="changePassword.form.field.label.newPassword"
                            defaultMessage="New password"
                        />
                    }
                    control={control}
                    rules={{ ...REQUIRED_FIELD_VALIDATION, validate: PASSWORD_INPUT_VALIDATION, }}
                />
                <PasswordInput
                    name="password_confirmation"
                    label={
                        <FormattedMessage
                            id="changePassword.form.field.label.confirmPassword"
                            defaultMessage="Confirm password"
                        />
                    }
                    control={control}
                    rules={{
                        ...REQUIRED_FIELD_VALIDATION,
                        validate: {
                            ...PASSWORD_INPUT_VALIDATION,
                            ...PASSWORD_CONFIRM_INPUT_VALIDATION(password)
                        },
                    }}
                />
                <div className="_m-t-50">
                    <Button tag='button' type='submit' className='_w-100' isDisabled={isLoading}>
                        <FormattedMessage
                            id='auth.resetPassword.btnText'
                            defaultMessage='Reset password'
                        />
                    </Button>
                </div>
                <div className="_flex _flex-xy-c _m-t-40 _ff-roboto _fz-14">
                    <FormattedMessage
                        id="auth.resetPassword.sideBar.title"
                        defaultMessage="Do not have an account yet?"
                    />
                    <Link to="/sign-up" className='_p-l-5 _text-main'>
                        <FormattedMessage
                            id="auth.resetPassword.sideBar.btnText"
                            defaultMessage="Sign up here"
                        />
                    </Link>
                </div>
            </form>
        </SimpleLayout>
    )
}