import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { useTimeout } from '../../hooks/useTimeout';
import { removeToast, Toast } from '../../store/toast';

type Props = { toast: Toast };

export const ToastItem = ({ toast }: Props) => {
  const dispatch = useDispatch();

  const { key, type, message, title, timeout } = toast;
  const animation = timeout ? { animationName: 'fadeIn, fadeOut', animationDelay: `0s, ${timeout - 200}ms` } : {};

  useTimeout(() => dispatch(removeToast(key)), timeout);

  return (
    <div className={cn('toast _pos-r', type)} style={animation} key={key}>
      {title ? <div className="toast-title">{title}</div> : null}
      {message}
      {!timeout ? (
        <div className="toast-close _pos-a _p-5" onClick={() => dispatch(removeToast(key))}>
          &#10005;
        </div>
      ) : null}
    </div>
  );
};
