import { useEffect } from "react";
import { chatPageOpened } from "../../../store/chat/chatSlice";
import { useAppDispatch } from "../../../store";

export const useChatOpen = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(chatPageOpened(true));
        return () => {
            dispatch(chatPageOpened(false));
        }
    },[]);
};