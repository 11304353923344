import moment from "moment";
import { endOfDay, parseISO, startOfDay } from "date-fns";

type DateFromTo<T> = { from?: T; to?: T; } | undefined | null;

export const getTimesTamp = (data: DateFromTo<string>): DateFromTo<string> => {
  
  if (!data) {
    return;
  }

  if (!data.from && !data.to) {
    return;
  }

  const res = {};

  if (data.from) {
    res['from'] = moment(startOfDay(parseISO(data['from']))).format("X");
  }

  if (data.to) {
    res['to'] = moment(endOfDay(parseISO(data['to']))).format("X");
  }

  return res;
}

export const timestampToDate = (data: DateFromTo<string>, format = "DD-MM-YYYY"): DateFromTo<string>  => {
  if (!data) {
    return;
  }

  if (!data.from && !data.to) {
    return;
  }

  const res = {};

  if (data.from) {
    res['from'] = moment.unix(Number(data.from)).format(format);
  }

  if (data.to) {
    res['to'] = moment.unix(Number(data.to)).format(format);
  }

  return res;
};

export const timestampToDateDot = (timestamp: number, format: string = 'DD.MM.YYYY'): string => {
  return moment.unix(timestamp).format(format);
};
