export const GermanyIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_25_31621)">
                <path d="M0.497559 10.7825C1.6279 13.8289 4.56025 15.9999 7.99996 15.9999C11.4397 15.9999 14.372 13.8289 15.5024 10.7825L7.99996 10.0869L0.497559 10.7825Z" fill="#FFDA44"/>
                <path d="M7.99996 0C4.56025 0 1.6279 2.171 0.497559 5.21741L7.99996 5.91303L15.5024 5.21737C14.372 2.171 11.4397 0 7.99996 0Z" fill="black"/>
                <path d="M0.497594 5.21741C0.176031 6.08406 0 7.02144 0 8C0 8.97856 0.176031 9.91594 0.497594 10.7826H15.5024C15.824 9.91594 16 8.97856 16 8C16 7.02144 15.824 6.08406 15.5024 5.21741H0.497594Z" fill="#D80027"/>
            </g>
            <defs>
                <clipPath id="clip0_25_31621">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}