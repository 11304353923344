import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetTableDataOptions, ITableLink, ITableMeta, TableAxiosResponse } from '../types/table';

interface IUseTableDataReturn<T> {
    tableDataLoading: boolean;
    tableData: T[];
    setTableData: Dispatch<SetStateAction<T[]>>;
    getTableData: (options: GetTableDataOptions) => Promise<void>;
    perPageCount: string;
    setPerPageCount: Dispatch<SetStateAction<string>>;
    tableMeta?: Partial<ITableMeta>;
    tableLinks: Partial<ITableLink>;
}

export const useTableData = <T = any>(
    apiRequest: (params: string) => Promise<TableAxiosResponse<T>>,
    shouldFetch = true,
    onSuccess?: ((data: TableAxiosResponse<T>) => void),
    onError?: ((error: any) => void),
): IUseTableDataReturn<T> => {
    const [tableDataLoading, setTableDataLoading] = useState(false);
    const [tableData, setTableData] = useState<T[]>([]);
    const [perPageCount, setPerPageCount] = useState('1000');
    const [tableMeta, setTableMeta] = useState<Partial<ITableMeta>>({});
    const [tableLinks, setTableLinks] = useState<Partial<ITableLink>>({});

    const getTableParams = (options?: GetTableDataOptions): string => {
        if (!options) {
            return '';
        }

        const { page, navigate, perPage, filter } = options;
        let params;
        params = `per_page=${perPage || perPageCount}`;

        if (page) {
            params = `${params}&page=${page}`;
        } else if (navigate) {
            let toPage;
            toPage = tableLinks[navigate].split('?').reverse()[0];
            params = `${toPage}&${params}`;
        }

        if (filter) {
            return params + filter;
        }

        return params;
    };

    const getTableData = async (options: GetTableDataOptions) => {
        setTableDataLoading(true);
        const tableParams = getTableParams(options);
        try {
            const response = await apiRequest(tableParams);
            setTableData(response.data.data);
            setTableMeta(response.data.meta);
            setTableLinks(response.data.links);
            setPerPageCount(response.data.meta?.per_page ? `${response.data.meta.per_page}` : '');
            onSuccess?.(response);
        } catch (error) {
            onError?.(error);
        } finally {
            setTableDataLoading(false)
        }
    };

    useEffect(() => {
        shouldFetch && getTableData(null);
    }, [shouldFetch]);

    return {
        tableDataLoading,
        tableData,
        setTableData,
        getTableData,
        perPageCount,
        setPerPageCount,
        tableMeta,
        tableLinks,
    };
};
