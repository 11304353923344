export const DEPOSIT_TYPE = {
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
};
export const DEPOSIT_METHOD = {
    CREDIT_CARD: 'credit_card',
};
export const CURRENCY = {
    USD: 'USD',
    EUR: 'EUR',
};

export const PAYMENTS = {
    kasha: 'kasha',
    mandato: 'mandato.global',
}
