import { useEffect } from "react";
import { useAppSelector } from "../store";
import { selectFavicon } from "../store/theme";

export const useFavicon = () => {
    const favicon = useAppSelector(selectFavicon);

    useEffect(() => {
        if (favicon) {
            let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = favicon;
        }
    }, [favicon]);
}