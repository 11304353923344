import { useEffect, useRef } from "react";

export const useEvent = (eventName: string, eventHandler: (e: any) => void) => {
    const callbackRef = useRef(eventHandler);

    useEffect(() => {
        callbackRef.current = eventHandler;
    });

    useEffect(() => {
        const callback = (e: any) => callbackRef.current(e);
        window.addEventListener(eventName, callback);
        return () => {
            window.removeEventListener(eventName, callback)
        }
    }, [eventName])
}