import { useIntl, FormattedMessage } from 'react-intl';
import { useForm, useWatch } from 'react-hook-form';

import { changeTradingAccountPassword } from '../../api/trading-account';
import { useAppDispatch, useAppSelector } from '../../store';

import { addToast } from '../../store/toast';
import { getResponseErrorMessage } from '../../utils/getResponseErrorMessage';
import { PasswordInput } from '../../components/Form/PasswordInput/PasswordInput';
import { PASSWORD_INPUT_VALIDATION } from '../../utils/validators';
import { Button } from '../../components/Button/Button';
import { selectModalProps } from '../../store/modal';

type AccChangePassDataTypes = {
  password: string;
  password_confirmation: string;
};

export const ACCOUNT_PASSWORD_MODAL_KEY = 'ACCOUNT_PASSWORD_MODAL_KEY';

export const AccountChangePasswordModal = ({ onCloseModal }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const tradingAccount = useAppSelector(selectModalProps);

  const {
    handleSubmit,
    control,
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const fields = useWatch({ control });
  const new_password = useWatch({ control, name: 'password' });

  const closeModal = () => {
    reset();
    onCloseModal();
  };

  const onSubmit = async (data: AccChangePassDataTypes) => {
    changeTradingAccountPassword(tradingAccount.id, data, undefined)
      .then(() => {
        dispatch(
          addToast({
            type: 'success',
            message: intl.formatMessage({
              id: 'password.reset.alert.text',
              defaultMessage: 'Password was reset and must be sent to your email'
            }),
            timeout: 5000,
          }),
        );
        closeModal();
      })
      .catch((error) => {
        if (error && error.response !== null) {
          dispatch(
            addToast({
              type: 'error',
              message: getResponseErrorMessage(error),
              timeout: 3000,
            }),
          );
        }
      });
  };

  return (
    <>
      <div className="modal-template-content-subtitle">
          <FormattedMessage
            id="page.trading.popup.changePassword.for"
            defaultMessage="Change password for"
          />{' '}
          - ID {tradingAccount.trading_account}
          {tradingAccount.name && ` (${tradingAccount.name})`}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PasswordInput
          id="acc_trading_password"
          name="password"
          type="password"
          placeholder={intl.formatMessage({
            id: 'page.trading.popup.changePassword.field.name.newPassword',
            defaultMessage: 'New Password',
          })}
          label={
            <FormattedMessage
              id="page.trading.popup.changePassword.field.name.newPassword"
              defaultMessage="New Password"
            />
          }
          control={control}
          rules={{
            required: (
              <FormattedMessage
                id="form.error.passwordMessage_7"
                defaultMessage="Password field is required"
              />
            ),
            validate: PASSWORD_INPUT_VALIDATION,
          }}
        />
        <PasswordInput
          id="acc_trading_confirm"
          name="password_confirmation"
          type="password"
          placeholder={intl.formatMessage({
            id: "page.trading.popup.changePassword.field.name.confirmNewPassword",
            defaultMessage: "Confirm New Password",
          })}
          label={intl.formatMessage({
            id: "page.trading.popup.changePassword.field.name.confirmNewPassword",
            defaultMessage: "Confirm New Password",
          })}
          control={control}
          rules={{
            required: (
              <FormattedMessage
                id="form.error.passwordMessage_7"
                defaultMessage="Password field is required"
              />
            ),
            validate: {
              ...PASSWORD_INPUT_VALIDATION,
              compare: (value) =>
                value === new_password || (
                  <FormattedMessage
                    id="form.error.passwordMessage_8"
                    defaultMessage="The password confirmation does not match."
                  />
                ),
            },
          }}
        />
        <div className="mobile-buttons _flex _flex-j-e _m-t-20">
          <Button buttonType="outline" className="_m-r-10" onClick={closeModal}>
            <FormattedMessage
              id="page.trading.popup.changePassword.btn.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button tag="button" type="submit" isDisabled={Object.values(fields).every(value => !value)}>
            <FormattedMessage
              id="page.trading.popup.changePassword.btn.change"
              defaultMessage="Change password"
            />
          </Button>
        </div>
      </form>
    </>
  );
};

export default AccountChangePasswordModal;
