import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import './Tooltip.scss';

type TooltipProps = {
    dataEvent?: 'string',
    delayHide?: number,
    tooltipText: string,
}

const Tooltip = ({ dataEvent, delayHide, tooltipText }: TooltipProps) => {
  const id = uuidv4();

  return (
      <div className="tooltip">
      <span data-tip data-for={id} data-event="click">
        <i className="fi fi-rr-interrogation _flex _fz-16"/>
      </span>
        <ReactTooltip
            id={id}
            globalEventOff={dataEvent ?? 'click'}
            delayHide={delayHide ?? 1000}
        >
          {tooltipText}
        </ReactTooltip>
      </div>
  );
};

export default Tooltip;