import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useMobileMenu = () => {
    const location = useLocation();
    const [menuOpened, setMenuOpened] = useState(false);

    useEffect(() => {
        setMenuOpened(false);
    }, [location.pathname]);

    return {
        menuOpened,
        setMenuOpened
    }
}