import { FormattedMessage } from "react-intl";

export const TABLE_WIRE_TRANSFERS_COLUMNS_NAMES = [
    {
        Header: <FormattedMessage id="table.th.accName" defaultMessage="Account Name" />,
        accessor: 'AccName',
    },
    {
        Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
        accessor: 'tradingAcc',
    },
    {
        Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
        accessor: 'amount',
    },
    {
        Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
        accessor: 'dateTime',
    },
    {
        Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
        accessor: 'status',
    },
];