import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ChatInput } from "./ChatInput";
import {
    selectProcessedMessage,
    selectProcessedMessageMode, selectThread
} from "../../../../store/chat/chatSlice";
import { ChatFormProcessedMessage } from "./ChatFormProcessedMessage";
import { useEvent } from "../../helpers/useEvent";
import { ChatUploadInput } from "./ChatUploadInput";
import { sendFile, sendText } from "../../../../store/chat/thunks";
import { handleReadThread } from "../../helpers/readThread";
import { Button } from "../../../../components/Button/Button";
import './ChatMessageForm.scss';

export const ChatMessageForm = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const thread = useAppSelector(selectThread);
    const processedMessageMode = useAppSelector(selectProcessedMessageMode);
    const processedMessage = useAppSelector(selectProcessedMessage);

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState('');

    const fileMessageSubmitHandler = (file: File) => {
        dispatch(sendFile({ threadId: thread?.id, file }));
    };

    const textMessageSubmitHandler = async (event) => {
        event.stopPropagation();
        handleReadThread(thread);

        if (!text.trim().length) return;

        setText('');
        textAreaRef.current?.focus();

        dispatch(sendText({ threadId: thread?.id, body: text }));
    };

    const keyDownHandler = event => {
        if (event.key === 'Enter' && document.activeElement === textAreaRef.current) {
            event.preventDefault();
            textMessageSubmitHandler(event);
        }
    };

    useEffect(() => {
        textAreaRef.current?.focus();
    }, []);

    useEvent('keydown', keyDownHandler);

    useEffect(() => {
        if (processedMessageMode) {
            textAreaRef.current?.focus();
        }
    }, [processedMessageMode]);

    return (
        <div className="chat-message-form">
            <ChatUploadInput onChangeHandler={fileMessageSubmitHandler}/>
            <ChatInput
                text={text}
                placeholder={intl.formatMessage(
                    {
                      id: "chat.message.write",
                      defaultMessage: 'Write a message',
                    })}
                onChange={e => setText(e.target.value)}
                textAreaRef={textAreaRef}
            />
            <Button onClick={textMessageSubmitHandler} className="message-sent-button">
                <i className="fi fi-br-paper-plane-top _flex _fz-18"/>
            </Button>
            {processedMessageMode ? (
                <ChatFormProcessedMessage
                    mode={processedMessageMode}
                    message={processedMessage}
                />
            ) : null}
        </div>
    )
}
