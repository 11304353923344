import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHeaderInfo } from '../api/myProfile'

export const getAccountInfo = createAsyncThunk('headerInfo/get', async (_, { rejectWithValue }) => {
  try {
    const response = await getHeaderInfo();
    return response.data.data
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

export const headerInfo = createSlice({
  name: 'header',
  initialState: {
    headerInfo: {
      user: {
        first_name: '',
        last_name: '',
      },
      balance: {
        EUR: { total: 'N/A', credit: 'N/A' },
        USD: { total: 'N/A', credit: 'N/A' },
      },
    },
    errorBalance: '',
  },
  reducers: {
    setHeaderInfo: (state, action) => {
      if (action.payload) {
        state.headerInfo = action.payload;
      }
    },
    setErrorBalance(state, action) {
      state.errorBalance = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.headerInfo = action.payload;
        state.errorBalance = '';
      }
    });
    builder.addCase(getAccountInfo.rejected, (state, action) => {
      // @ts-ignore
      state.errorBalance = action.payload;
    });
  }
});

export const selectHeaderInfo = state => state.header.headerInfo;

export const { setHeaderInfo } = headerInfo.actions;

export default headerInfo.reducer;
