export const getResponseErrorMessage = (error: any): string => {
    if (!error || error?.response?.data === null) {
        return 'Something went wrong.'
    }

    if (typeof error === 'string') {
        return error
    }

    if ('errors' in error.response.data) {
        let keys: string[] = Object.keys(error.response.data.errors);
        return error.response.data.errors[keys[0]][0];
    }

    return error.response.data.message
}