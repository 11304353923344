import { FormattedMessage } from 'react-intl';

export const TABLE_DEALS_COLUMNS_NAMES = [
    {
      Header: <FormattedMessage id="table.th.account" defaultMessage="Account" />,
      accessor: 'account',
    },
    {
      Header: <FormattedMessage id="table.th.side" defaultMessage="Side" />,
      accessor: 'side',
    },
    {
      Header: <FormattedMessage id="table.th.deal" defaultMessage="Deal" />,
      accessor: 'deal',
    },
    {
      Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
      accessor: 'symbol',
    },
    {
      Header: <FormattedMessage id="table.th.openPrice" defaultMessage="Open Price" />,
      accessor: 'open_price',
    },
    {
      Header: <FormattedMessage id="table.th.closePrice" defaultMessage="Close Price" />,
      accessor: 'close_price',
    },
    {
      Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
      accessor: 'volume',
    },
    {
      Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
      accessor: 'profit',
    },
    {
      Header: <FormattedMessage id="table.th.closeTime" defaultMessage="Close Time" />,
      accessor: 'close_time',
    },
  ];