import { FunctionComponent } from 'react';
import { DropdownIndicatorProps, components } from 'react-select';

export const DropdownIndicator: FunctionComponent<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fi fi-rr-angle-small-down _fz-24 _flex"/>
    </components.DropdownIndicator>
  );
};
