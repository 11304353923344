import { FormattedMessage } from "react-intl";

export const AccessRestricted = () => {
    return (
        <div className="_h-100vh _flex _flex-col _flex-xy-c">
            <div className="_fz-32">
                <FormattedMessage id="auth.siteIsRestricted" defaultMessage="Access to this site is restricted"/>
            </div>
        </div>
    )
}