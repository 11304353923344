import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { changePassword } from '../../../api/myProfile';
import { FormattedMessage, useIntl } from 'react-intl';
import { PASSWORD_INPUT_VALIDATION, REQUIRED_FIELD_VALIDATION } from '../../../utils/validators';
import { Button } from "../../../components/Button/Button";
import { PasswordInput } from "../../../components/Form/PasswordInput/PasswordInput";
import { addToast } from "../../../store/toast";
import { useAppDispatch } from "../../../store";
import { getResponseErrorMessage } from "../../../utils/getResponseErrorMessage";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { PageNavigation } from "../../../components/PageNavigation/PageNavigation";
import { MyProfilePageTabs } from "../_shared/MyProfilePageTabs";
import './changePassword.scss';

const chgPasDescr = 'Your privacy is a big issue for us, we do everything we can to keep your information private. For your own safety and privacy, we recommend you update your password every three months. Keep it private and confidential, do not share it with anyone including personnel. Remember that this will change your password to the client area and all your trading accounts as well.';

const ChangePassword = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const {handleSubmit, control, reset} = useForm({
        reValidateMode: 'onChange'
    });

    usePageTitle(intl.formatMessage({
        id: "menu.changePassword",
        defaultMessage: "Change password",
    }));

    const new_password = useWatch({control, name: 'password'});

    const cancelAction = () => {
        reset({old_password: '', password: '', password_confirmation: ''});
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        changePassword(data)
            .then((res) => {
                if (res.status === 200) {
                    setIsLoading(false);
                    dispatch(addToast({
                        type: 'success',
                        message: res.data?.message || intl.formatMessage({
                            id: 'status.success.title',
                            defaultMessage: 'Success'
                        }),
                        timeout: 3000,
                    }));
                    setTimeout(() => {
                        navigate('/cryptocurrency');
                    }, 500);
                }
            })
            .catch((error) => {
                if (error && error.response !== null) {
                    setIsLoading(false);
                    dispatch(addToast({
                        type: 'error',
                        message: getResponseErrorMessage(error),
                        timeout: 3000,
                    }))
                }
            });
    };

    return (
        <div className="password-page">
            <h1><FormattedMessage id="menu.profile" defaultMessage=" My profile"/></h1>
            <PageNavigation nav={MyProfilePageTabs}>
                <Button buttonType="outline" className="_m-r-10" onClick={cancelAction}>
                    <FormattedMessage id="changePassword.form.field.btn.reset" defaultMessage="Reset"/>
                </Button>
                <Button onClick={handleSubmit(onSubmit)} isDisabled={isLoading}>
                    <FormattedMessage id="changePassword.form.field.btn.change" defaultMessage="Change password"/>
                </Button>
            </PageNavigation>
            <div className="row password-page-description">
                <div className='_fz-14 _ff-roboto _lh-20 col c-lg-7 c-xs-12'>
                    <FormattedMessage id="changePassword.descr" defaultMessage={chgPasDescr}/>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col c-md-6 c-lg-4">
                        <PasswordInput
                            id="old_password_id-personal"
                            name="old_password"
                            type="password"
                            label={
                                <FormattedMessage
                                    id="changePassword.form.field.label.currentPassword"
                                    defaultMessage="Current password"
                                />
                            }
                            control={control}
                            rules={REQUIRED_FIELD_VALIDATION}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col c-md-6 c-lg-4">
                        <PasswordInput
                            id="password_id_personal"
                            name="password"
                            type="password"
                            label={
                                <FormattedMessage
                                    id="changePassword.form.field.label.newPassword"
                                    defaultMessage="New password"
                                />
                            }
                            control={control}
                            rules={{
                                ...REQUIRED_FIELD_VALIDATION,
                                validate: PASSWORD_INPUT_VALIDATION,
                            }}
                        />
                    </div>
                    <div className="col c-md-6 c-lg-4">
                        <PasswordInput
                            id="password_confirmation_id"
                            name="password_confirmation"
                            type="password"
                            label={
                                <FormattedMessage
                                    id="changePassword.form.field.label.confirmPassword"
                                    defaultMessage="Confirm password"
                                />
                            }
                            control={control}
                            rules={{
                                ...REQUIRED_FIELD_VALIDATION,
                                validate: {
                                    ...PASSWORD_INPUT_VALIDATION,
                                    compare: (value) =>
                                        value === new_password || (
                                            <FormattedMessage
                                                id="form.error.passwordMessage_8"
                                                defaultMessage="The password confirmation does not match."
                                            />
                                        ),
                                },
                            }}
                        />
                    </div>
                </div>
                <div className="mobile-buttons _flex _flex-j-e _m-t-20">
                    <Button buttonType="outline" className="_m-r-10" onClick={cancelAction}>
                        <FormattedMessage id="changePassword.form.field.btn.reset" defaultMessage="Reset"/>
                    </Button>
                    <Button tag='button' type="submit" isDisabled={isLoading}>
                        <FormattedMessage id="changePassword.form.field.btn.change" defaultMessage="Change password"/>
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
