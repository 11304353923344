import cn from 'classnames';
import Tooltip from "../../Tooltip/Tooltip";
import { ReactNode } from 'react';
import { CommonInputProps } from '../types';
import './InputField.scss';

type Props = Omit<CommonInputProps, 'rules' | 'name'> & {
  error?: any;
  className: string;
  children: ReactNode;
};

export const InputField = ({ id, label, error, tooltip, children, className = "" }: Props) => {
    return (
    <div className={cn('input-field', className, { error })}>
      <div className="input-field-label">
        {label && id ? <label htmlFor={id} className='label-title'>{label}</label> : null}
        {label && !id ? <span className='label-title'>{label}</span> : null}
        {tooltip ? <Tooltip tooltipText={tooltip}/> : null}
      </div>
      <div className="input-wrap">{children}</div>
      {error && (typeof error === 'string' || '$$typeof' in error) ? <div className="error-message">{error}</div> : null}
    </div>
  );
};
