import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import '@flaticon/flaticon-uicons/css/regular/rounded.css';
import '@flaticon/flaticon-uicons/css/bold/rounded.css';
import '@flaticon/flaticon-uicons/css/solid/rounded.css';
import './styles/index.scss';
import { Toast } from "./components/Toast";
import { Provider } from 'react-redux';
import { store } from './store';
import { router } from "./routes/routes";



ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
        <RouterProvider router={router} />
        <Toast />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
