export const copyToClipboard = (text, onCopy = null) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
        onCopy?.();
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus({ preventScroll: true });
        textArea.select();
        try {
            document.execCommand('copy');
            onCopy?.();
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }
}