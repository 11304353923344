import { FormattedMessage } from "react-intl";

export const TABLE_TRADING_COLUMNS_NAMES = [
    {
        Header: <FormattedMessage id="table.th.trade" defaultMessage="Trade" />,
        accessor: 'trade',
    },
    {
        Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
        accessor: 'type',
    },
    {
        Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
        accessor: 'symbol',
    },
    {
        Header: <FormattedMessage id="table.trading_history.th.openPrice" defaultMessage="Transaction price" />,
        accessor: 'open_price',
    },
    {
        Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
        accessor: 'volume',
    },
    {
        Header: <FormattedMessage id="table.trading_history.th.openTime" defaultMessage="Transaction time" />,
        accessor: 'open_time',
    },
];