import Echo from "laravel-echo";
import Pusher from 'pusher-js';
import { setConnectionStatus } from "../../store/chat/chatSlice";
import { chatApi } from "./index";

declare global {
    interface Window {
        ChatEcho: Echo;
        Pusher: any;
    }
}

export function createChatConnection(token: string, dispatch) {
    if (!token) {
        return;
    }

    if (!window.Pusher) {
        window.Pusher = Pusher
    }

    window.ChatEcho = new Echo({
        broadcaster: 'pusher',
        key: process.env.REACT_APP_PUSHER_KEY,
        wsHost: process.env.REACT_APP_PUSHER_HOST,
        wsPort: process.env.REACT_APP_PUSHER_PORT,
        wssPort: process.env.REACT_APP_PUSHER_PORT,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    chatApi.post('/api/broadcasting/auth', {
                        socket_id: socketId,
                        channel_name: channel.name
                    })
                        .then(response => {
                            callback(null, response.data);
                        })
                        .catch(error => {
                            callback(error);
                        });
                }
            };
        },
    });

    window.ChatEcho.connector.pusher.connection.bind('state_change', function(states) {
        if (states.current === 'connected') {
            dispatch(setConnectionStatus(true));
        } else {
            dispatch(setConnectionStatus(false));
        }
    });
}