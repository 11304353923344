import { FormattedMessage } from 'react-intl';

export const PASSWORD_INPUT_VALIDATION = {
    isNumber: (value: string) =>
        /^(?=.*[0-9])/.test(value) || (
            <FormattedMessage
                id="form.error.passwordMessage_5"
                defaultMessage="Password should contain at least one digit"
            />
        ),
    isUpper: (value: string) =>
        /^(?=.*[a-zа-я])/.test(value) || (
            <FormattedMessage
                id="form.error.passwordMessage_2"
                defaultMessage="Password should contain at least one lowercase letter"
            />
        ),
    isLower: (value: string) =>
        /^(?=.*[A-ZА-Я])/.test(value) || (
            <FormattedMessage
                id="form.error.passwordMessage_4"
                defaultMessage="Password should contain at least one uppercase letter"
            />
        ),
    isSimbol: (value: string) =>
        /^(?=.*[!@#$%^&*\.,?;:"'№_=\/<>\|{}`~()\[\]\\])/.test(value) || (
            <FormattedMessage
                id="form.error.passwordMessage_1"
                defaultMessage="Password should contain at least one special symbol"
            />
        ),
    minLength: (value: string) =>
        /^.{8,}$/.test(value) || (
            <FormattedMessage
                id="form.error.passwordMessage_6"
                defaultMessage="Password must contain at least 8 characters"
            />
        ),
};

export const EMAIL_INPUT_VALIDATION = {
    correctEmail: (value: string) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || (
            <FormattedMessage
                id="form.error.email"
                defaultMessage="Incorrect E-mail address"
            />
        ),
};

export const PASSWORD_CONFIRM_INPUT_VALIDATION = (new_password) => ({
    compare: (value) =>
        value === new_password || <FormattedMessage
            id="form.error.passwordMessage_8"
            defaultMessage="The password confirmation does not match."
        />
});

export const REQUIRED_FIELD_VALIDATION = {
    required: (
        <FormattedMessage
            id="form.error.requiredFieldnoName"
            defaultMessage="Field is required"
        />
    )
};
