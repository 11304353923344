import { FormattedMessage } from "react-intl";

export const MyProfilePageTabs = [
    {
        title: <FormattedMessage id="personalInfo.title" defaultMessage="Personal Info" />,
        src: '/personal'
    },
    {
        title: <FormattedMessage id="changePassword.title" defaultMessage="Change password" />,
        src: '/change-password'
    }
];
