import { useEffect, useRef, useState } from 'react';
import cn from "classnames";
import moment from 'moment';
import { DateRange } from 'react-date-range';
import { useController } from 'react-hook-form';
import { useOnClickOutside } from "../../../hooks/useClickOutside";
import { InputField } from "../InputField/InputField";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DatePickerRange.scss';

const DatePickerRange = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const contentRef = useRef(null);
    const {field, formState} = useController(props);

    const handleSelect = ({selection}) => {
        setRange([selection]);
        const from = moment(selection.startDate).format('Y-MM-DD');
        const to = moment(selection.endDate).format('Y-MM-DD');
        field.onChange({from, to});
        props.onChange?.({from, to});
        if (props.handleChange) {
            props.handleChange({from: from, to: to});
        }
    };

    useOnClickOutside(contentRef, () => {
        setShowDatePicker(false);
    });

    useEffect(() => {
        if (!field.value) {
            setInputValue('');
            setRange([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection',
                },
            ]);
            return;
        }

        setInputValue(`${field?.value?.from} - ${field?.value?.to}`);
        setRange([
            {
                startDate: new Date(field?.value?.from),
                endDate: new Date(field?.value?.to),
                key: 'selection',
            },
        ]);
    }, [field.value]);

    const fieldError = formState.errors?.[field.name]?.message || '';

    return (
        <InputField
            id={props.id}
            label={props.label}
            error={fieldError}
            tooltip={props.tooltip}
            className={cn('calendar-picker', props.className, {required: props.rules?.required})}
        >
            <input
                name={props.name}
                type="text"
                value={inputValue}
                className='input'
                onChange={() => {
                    field.onChange(null);
                    props.onChange?.(null);
                }}
                autoFocus={props.autoFocus}
                autoComplete={'off'}
                placeholder={props.placeholder}
                onFocus={() => setShowDatePicker(true)}
            />
            <i className="fi fi-rr-calendar"/>
            {showDatePicker && (
                <div className='date-range' ref={contentRef}>
                    <DateRange
                        editableDateInputs={false}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        onChange={handleSelect}
                    />
                </div>
            )}
        </InputField>
    );
};

export default DatePickerRange;
