import { useForm, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "../../components/Button/Button";
import { SelectInput } from "../../components/Form/SelectInput/SelectInput";
import DatePickerRange from "../../components/Form/DatePickerRange/DatePickerRange";
import { useGetTradingAccounts } from "../../hooks/useGetTradingAccounts";
import { getTimesTamp } from "../../utils/getTimesTamp";
import { formatAccountSelectOptions } from "../../components/Form/_helpers/formatAccountSelectOptions";
import { useAppSelector } from "../../store";
import { selectModalProps } from "../../store/modal";
import { AccountSelectOption } from "../../components/Form/_helpers/AccountSelectOption";
import { useGetSymbolOptions } from "../../hooks/useGetSymbols";
import { TradingFilter } from "../../types/Trading";

export const POSITIONS_FILTERS_MODAL_KEY = 'POSITIONS_FILTERS_MODAL_KEY';

const OpenedPositionsModal = ({ closeModal, onSubmitFilter }) => {
  const intl = useIntl();
  const filter  = useAppSelector(selectModalProps);
  const { tradingAccounts: accountsData } = useGetTradingAccounts();
  const symbolsData = useGetSymbolOptions();

  const {
    control,
    handleSubmit,
  } = useForm<TradingFilter>({
    reValidateMode: 'onChange',
    defaultValues: {
          open: filter?.open,
          trading_account: filter?.trading_account,
          symbol: filter?.symbol,
        }
  });
  const fields = useWatch({ control });

  const onSubmit = (data: TradingFilter) => {
    onSubmitFilter({
      trading_account: data.trading_account,
      open: data.open ? getTimesTamp(data.open) : null,
      symbol: data.symbol,
    });
    closeModal();
  }

  return (
    <div className="opened-positions-modal">
      <form onSubmit={handleSubmit(onSubmit)} className="_overflow-h">
        <div className="row">
          <div className="col c-xs-12">
            <SelectInput
              id="filter_trading_positions_acc"
              name="trading_account"
              label={
                <FormattedMessage id="page.trading.history.tradingAccount.title" defaultMessage="Trading account" />
              }
              control={control}
              options={formatAccountSelectOptions(accountsData)}
              formatOptionLabel={AccountSelectOption}
            />
          </div>
          <div className="col c-md-6">
            <SelectInput
              id="filter_trading_positions_symbol"
              name="symbol"
              label={
                <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />
              }
              control={control}
              options={symbolsData}
            />
          </div>
          <div className="col c-md-6">
            <DatePickerRange
              name="open"
              control={control}
              label={
                <FormattedMessage
                  id="page.trading.history.range.open.title"
                  defaultMessage="Open time range"
                />
              }
              placeholder={intl.formatMessage({
                id: "page.trading.history.range.placeholder",
                defaultMessage: "From - To",
              })}
            />
          </div>
        </div>
        <div className="form-buttons _flex _flex-a-c _flex-j-e _m-t-50">
          <Button buttonType="outline" className="_m-r-15" onClick={closeModal}>
            <FormattedMessage id="newAccountPopup.cancelBtn" defaultMessage="Cancel" />
          </Button>
          <Button tag="button" type="submit" isDisabled={Object.values(fields).every(value => !value)}>
            <FormattedMessage id="page.trading.history.form.apply" defaultMessage="Apply" />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OpenedPositionsModal;
