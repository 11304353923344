import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../api/axiosBaseQuery";

export const languagesQuery = createApi({
    reducerPath: 'languages',
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        getLanguages: builder.query({
            query: () => 'api/v1/languages?per_page=100&page=1',
        }),
    }),
});

export const { useGetLanguagesQuery } = languagesQuery;
