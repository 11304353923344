import { LangSwitcher } from "../../../../components/LangSwitcher/LangSwitcher";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import './FormHeader.scss';

type FormHeaderProps = {
    title: string | ReactNode;
    backUrl?: string;
}
export const FormHeader = ({ title, backUrl }: FormHeaderProps) => {
    return (
        <div className="form-header _flex _flex-j-b _flex-a-c _m-b-50 _pos-r">
            {backUrl ? (
                <Link to={backUrl} className='form-header-link _pos-a _flex _flex-col _flex-xy-c' title={'back'}>
                    <i className='fi fi-rr-angle-small-left _fz-24'/>
                </Link>
            ) : null}
            <div className="form-header-title _fz-30 _fw-600">{title}</div>
            <LangSwitcher closeOnSelect={true}/>
        </div>
    )
}