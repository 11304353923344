import cn from 'classnames';
import { useState } from "react";
import { useController } from 'react-hook-form';
import { InputField } from '../InputField/InputField';
import './PasswordInput.scss';

export const PasswordInput = (props) => {
  const { field, formState } = useController(props);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const fieldError = formState.errors?.[field.name]?.message || '';

  return (
    <InputField
      id={props.id}
      label={props.label}
      error={fieldError}
      tooltip={props.tooltip}
      className={cn('password-input', props.className, { required: props.rules?.required })}
    >
      <input
          {...field}
          className='input'
          type={showPassword ? 'text' : 'password'}
          autoComplete={props.autoComplete ? props.autoComplete : ''}
          placeholder={props.placeholder}
          value={field.value}
      />
      <div className="field-icon">
        <div className="show-password">
          <i
              className={cn('fi', {'fi-rr-eye': showPassword, 'fi-rr-eye-crossed': !showPassword})}
              onClick={handleShowPassword}
          />
        </div>
      </div>
    </InputField>
  );
};
