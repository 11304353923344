import { createAction, createSlice } from "@reduxjs/toolkit";
import {
    ChatMessageType,
    ChatUserInfo, OnlineStatusesVerbose,
    ProcessedMessageMode,
    Thread
} from "../../types/Chat";
import {
    getMessages,
    getNextMessages,
    getThreads, incomingThread,
    updateUserInfo
} from "./thunks";

type InitialState = {
    token: string;
    connected: boolean | 'init';
    userInfo: ChatUserInfo | null;
    onlineUserStatuses: Record<string, number>;
    unreadTotal: number;
    threadsPending: boolean;
    threads: Thread[];
    messagesError: boolean;
    messagesPending: boolean;
    messagesNextPage: string | null;
    messages: ChatMessageType[];
    processedMessage: ChatMessageType | null;
    processedMessageMode: ProcessedMessageMode | null;
    scrollToTheLast: boolean;
};

const initialState: InitialState = {
    token: '',
    connected: 'init',
    userInfo: null,
    unreadTotal: 0,
    threadsPending: true,
    threads: [],

    messagesError: false,
    messagesPending: true,
    messagesNextPage: null,
    messages: [],

    processedMessage: null,
    processedMessageMode: null,

    scrollToTheLast: true,
    onlineUserStatuses: {}
};

export const selectChatToken = (state) => state.chat.token;
export const selectChatPending = (state) => state.chat.pending;
export const selectChatConnectionStatus = (state) => state.chat.connected;
export const selectChatAuth = (state) => !!state.chat.userInfo;
export const selectChatUser = (state): ChatUserInfo => state.chat.userInfo;
export const selectChatUserId = (state) => state.chat.userInfo?.provider?.provider_id;
export const selectUserStatus = (state) => state.chat.userInfo?.online_status;
export const selectUserStatuses = (state) => state.chat.onlineUserStatuses;
export const selectThreads = (state) => state.chat.threads;
export const selectThread = (state) => state.chat.threads[0];
export const selectChatLoading = (state) => state.chat.threadsPending;
export const selectChatUnreadThreads = (state) => state.chat.unreadTotal;
export const selectMessages = (state) => state.chat.messages;
export const selectMessagesError = (state) => state.chat.messagesError;
export const selectMessagesNextPage = (state) => state.chat.messagesNextPage;
export const selectMessagesLoading = (state) => state.chat.messagesPending;
export const selectProcessedMessageMode = (state) => state.chat.processedMessageMode;
export const selectProcessedMessage = (state) => state.chat.processedMessage;
export const selectScrollToTheLast = (state) => state.chat.scrollToTheLast;

export const chatInit = createAction<undefined>('chat/init');
export const chatAuth = createAction<undefined>('chat/login');
export const chatThreadsRequested = createAction<undefined>('chat/threads/requested');
export const chatPageOpened = createAction<boolean>('chat/opened');

const updateMessages = (state: InitialState, message: ChatMessageType) => {
    if (window.location.pathname.includes('chat')) {
        const index = state.messages.findIndex(m => m?.temporary_id === message.temporary_id);
        index === -1 ? state.messages.unshift(message) : state.messages[index] = message;
    }
};

const updateIncomingMessageThread = (state: InitialState, message: ChatMessageType) => {
    const index = state.threads.findIndex(thread => thread.id === message.thread_id);
    const thread = state.threads[index];
    const ownMessage = message.owner.provider_id === state.userInfo.provider.provider_id;

    thread.resources.latest_message = message;

    if (!ownMessage) {
        thread.unread = true;
        thread.unread_count += 1;
        state.unreadTotal += 1;
    }

    state.threads.unshift(state.threads.splice(index, 1)[0]);
};

const clearMessages = (state) => {
    state.messages = [];
    state.messagesPending = true;
    state.messagesError = false;
    state.messagesNextPage = null;
    state.processedMessage = null;
    state.processedMessageMode = null;
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChatToken(state, action) {
            state.token = action.payload;
            if (!action.payload) {
                state.threads = [];
                state.threadsPending = true;
            }
        },
        setConnectionStatus(state, action) {
            state.connected = action.payload;
        },
        setUserStatus(state, action) {
            state.userInfo = action.payload;
        },
        readThread(state, action) {
            const threadIndex = state.threads.findIndex(thread => thread.id === action.payload.thread_id);
            if (state.threads[threadIndex].resources?.latest_message?.owner_id !== state.userInfo.provider.provider_id) {
                state.unreadTotal -= state.threads[threadIndex].unread_count;
                state.threads[threadIndex].unread = false;
                state.threads[threadIndex].unread_count = 0;
            }
        },
        addNewMessage(state, action) {
            //TODO: CHAT - remove the condition when fixed undefined Thread
            if (action.payload) {
                state.onlineUserStatuses[action.payload.owner.provider_id] = OnlineStatusesVerbose.ONLINE;
                updateMessages(state, action.payload);
                updateIncomingMessageThread(state, action.payload);
            }
        },
        removeMessage(state, action) {
            const index = state.messages.findIndex(message => message.id === action.payload.message_id);
            state.messages.splice(index, 1);
        },
        addPendingMessage(state, action) {
            state.messages.unshift(action.payload);
            state.processedMessageMode = null;
            state.processedMessage = null;
            state.scrollToTheLast = true;
        },
        setProcessedMessage(state, action) {
            state.processedMessageMode = action.payload.mode;
            state.processedMessage = action.payload.message;
        },
        setMessagesError(state, action) {
            state.messagesError = action.payload;
        },
        clearChatMessages(state) {
            clearMessages(state);
        },
        clearChatStore() {
            return initialState
        },
    },
    extraReducers: builder => {
        builder.addCase(updateUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload;
            state.onlineUserStatuses[action.payload.provider.provider_id] = action.payload.online_status;
        });
        builder.addCase(getThreads.fulfilled, (state, action) => {
            if (state.threads?.[0]?.id !== action.payload?.threads?.[0]?.id) {
                clearMessages(state);
            }
            state.threadsPending = false;
            state.threads = action.payload.threads;
            state.unreadTotal = action.payload.unreadCount;
        });
        builder.addCase(incomingThread.fulfilled, (state, action) => {
            clearMessages(state);
            state.threads.unshift(action.payload);
            state.unreadTotal += 1;

            if (!action.payload.group) {
                state.onlineUserStatuses[action.payload.resources.recipient.provider_id] = OnlineStatusesVerbose.ONLINE;
            }
        });
        builder.addCase(getMessages.pending, (state) => {
            state.messagesPending = true;
            state.messages = [];
        });
        builder.addCase(getMessages.fulfilled, (state, action) => {
            state.messagesPending = false;
            state.messages = action.payload.messages;
            state.messagesNextPage = action.payload.next;
            state.scrollToTheLast = true;
            state.onlineUserStatuses = Object.assign(state.onlineUserStatuses, action.payload.online);
        });
        builder.addCase(getNextMessages.fulfilled, (state, action) => {
            state.messages.push(...action.payload.messages);
            state.messagesNextPage = action.payload.next;
            state.scrollToTheLast = false;
        });
        builder.addCase(getMessages.rejected, (state) => {
            state.messagesPending = false;
            state.messagesError = true;
        });
    }
});

const { reducer, actions } = chatSlice;

export const {
    readThread,
    setChatToken,
    setProcessedMessage,
    addPendingMessage,
    addNewMessage,
    removeMessage,
    setConnectionStatus,
    clearChatStore,
    clearChatMessages
} = actions;
export default reducer;