import { FormattedMessage } from 'react-intl';
import { unEscapeHtml } from '../../helpers/unscapeHtml';
import { copyToClipboard } from '../../../../utils/copyToClipboard';
import { DropdownOption } from '../../../../components/Dropdown/DropdownOption';

type CopyTextOptionProps = { text: string };

export const CopyTextOption = ({ text }: CopyTextOptionProps) => {
  const handleCopyToClipboard = () => {
    copyToClipboard(unEscapeHtml(text));
  };

  return (
    <DropdownOption
      title={<FormattedMessage id="chat.dropdown.option.copy" defaultMessage="Copy" />}
      onSelect={handleCopyToClipboard}
      icon="fi-rr-duplicate"
    />
  );
};
