import { FormattedMessage } from "react-intl";

export const TABLE_ACCOUNTS_COLUMNS_NAMES_WITH_WEBTRADER = [
    {
        Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
        accessor: 'tradingAcc',
    },
    {
        Header: <FormattedMessage id="table.th.login" defaultMessage="Login" />,
        accessor: 'login',
    },
    {
        Header: <FormattedMessage id="table.th.balance" defaultMessage="Balance" />,
        accessor: 'balance',
    },
    {
        Header: <FormattedMessage id="table.th.demoAccount" defaultMessage="Demo account" />,
        accessor: 'demo',
    },
    {
        Header: <FormattedMessage id="table.th.credit" defaultMessage="Credit" />,
        accessor: 'credit',
    },
    {
        Header: <FormattedMessage id="table.th.liverage" defaultMessage="Leverage" />,
        accessor: 'liverage',
    },
    {
        Header: '',
        accessor: 'controls',
    },
];

export const TABLE_ACCOUNTS_COLUMNS_NAMES = [
    {
        Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
        accessor: 'tradingAcc',
    },
    {
        Header: <FormattedMessage id="table.th.login" defaultMessage="Login" />,
        accessor: 'login',
    },
    {
        Header: <FormattedMessage id="table.th.balance" defaultMessage="Balance" />,
        accessor: 'balance',
    },
    {
        Header: <FormattedMessage id="table.th.credit" defaultMessage="Credit" />,
        accessor: 'credit',
    },
    {
        Header: <FormattedMessage id="table.th.liverage" defaultMessage="Leverage" />,
        accessor: 'liverage',
    },
    {
        Header: <FormattedMessage id="table.th.actions" defaultMessage="Actions"/>,
        accessor: 'controls',
    },
];