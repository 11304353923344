export const SIZE_WIDTH = {
  mobile: 576,
  tablet: 992,
  desktop: 1200,
};

export const isDevice = (width) => {
  if (width < SIZE_WIDTH.mobile) {
    return 'MOBILE';
  } else if (width < SIZE_WIDTH.tablet) {
    return 'TABLET';
  } else {
    return 'DESKTOP';
  }
}
