import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../store';
import { closeModal, openModal } from '../../../store/modal';
import { PageNavigation } from '../../../components/PageNavigation/PageNavigation';
import { tradingPageTabs } from '../_shared/TradingPageTabs';
import { Button } from '../../../components/Button/Button';
import { ModalTemplate } from '../../../components/Modal/components/ModalTemplate/ModalTemplate';
import TablePagination from '../../../components/Table/TablePagination';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useTableData } from '../../../hooks/useTableData';
import { timestampToDate, timestampToDateDot } from '../../../utils/getTimesTamp';
import { FilterTag } from '../../../components/FilterTag/FilterTag';
import { DealsTable } from './DealsTable/DealsTable';
import { getDeals } from '../../../api/deals';
import { getDealsFilterParams } from '../../../utils/getTradingFiltersParams';
import DealsFilterModal, { DEALS_FILTERS_MODAL_KEY } from '../../../modals/DealsFilterModal/DealsFilterModal';
import { Deal, TradingFilter } from '../../../types/Trading';

const Deals = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [filters, setFilters] = useState<TradingFilter>({});

  const {
    tableDataLoading,
    tableData,
    tableMeta,
    getTableData,
    setPerPageCount,
    perPageCount,
    tableLinks,
  } = useTableData<Deal>(getDeals);

  usePageTitle(
    intl.formatMessage({
      id: 'menu.deals',
      defaultMessage: 'Deals',
    }),
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const onTableParamsChange = (params) => {
    getTableData({
      ...params,
      filter: getDealsFilterParams(filters),
    });
  };

  const onSubmitFilter = (filter: TradingFilter) => {
    setFilters(filter);
    getTableData({ filter: getDealsFilterParams(filter) });
  };

  const openFilterModal = () => {
    dispatch(openModal({
          key: DEALS_FILTERS_MODAL_KEY,
          data: {
            trading_account: filters.trading_account || null,
            symbol: filters.symbol || null,
            close: filters.close ? timestampToDate(filters.close, "YYYY-MM-DD") : null,
          }
        }
    ));
  };

  return (
    <>
      <h1>
        <FormattedMessage id="menu.trading" defaultMessage="Trading" />
      </h1>
      <PageNavigation nav={tradingPageTabs}>
        <div className="_flex _flex-w _flex-a-c">
          {filters.trading_account && (
            <FilterTag 
              title={<FormattedMessage id="page.trading.tag.account" defaultMessage="Account:" />} 
              onRemove={() => onSubmitFilter({ ...filters, trading_account: null })}
            >
              {filters.trading_account?.data?.name || (
                <FormattedMessage id="trading.accounts.no_name" defaultMessage="No name" />
              )}{' '}- ID{' '}{filters.trading_account?.data?.trading_account}
            </FilterTag>
          )}
          {filters.symbol && (
            <FilterTag 
              title={<FormattedMessage id="page.trading.tag.symbol" defaultMessage="Symbol:" />} 
              onRemove={() => onSubmitFilter({ ...filters, symbol: null })}
            >
              {filters?.symbol?.value}
            </FilterTag>
          )}
          {filters.close && (
            <FilterTag 
              title={<FormattedMessage id="page.trading.tag.closed" defaultMessage="Closed:" />}
              onRemove={() => onSubmitFilter({ ...filters, close: null })}
            >
              {timestampToDateDot(Number(filters?.close?.from))}-
              {timestampToDateDot(Number(filters?.close?.to))}
            </FilterTag>
          )}
        </div>
        <Button buttonType="outline" onClick={openFilterModal} iconOnly>
          <i className="fi fi-rr-filter _flex _fz-18" />
        </Button>
      </PageNavigation>
      <ModalTemplate
        id={DEALS_FILTERS_MODAL_KEY}
        title={
          <FormattedMessage id="page.trading.history.form.modal.title" defaultMessage="Filter" />
        }
        onClose={handleCloseModal}
        size="md"
      >
        <DealsFilterModal closeModal={handleCloseModal} onSubmitFilter={onSubmitFilter} />
      </ModalTemplate>
      <DealsTable data={tableData} perPage={perPageCount} showLoader={tableDataLoading} />
      {tableData?.length && tableMeta ? (
        <TablePagination
          onChange={onTableParamsChange}
          setPerPageCount={setPerPageCount}
          perPage={perPageCount}
          tableLinks={tableLinks}
          tableMeta={tableMeta}
        />
      ) : null}
    </>
  );
};

export default Deals;
