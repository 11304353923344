import { useEffect, useState } from "react";
import { getTradingAccounts } from "../api/trading-account";
import { TRADING_ACCOUNT_STATUS } from "../constants/trading-account-status";

export const useGetTradingAccounts = () => {
    const [loading, setLoading] = useState(true);
    const [tradingAccounts, setTradingAccounts] = useState([]);

    const getData = () => {
        setLoading(true);
        getTradingAccounts(`per_page=100&status=${TRADING_ACCOUNT_STATUS.ACTIVE}`)
            .then((res) => {
                if (res && res.status === 200) {
                    setTradingAccounts(res.data.data);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    };

    useEffect(() => {
        getData()
    }, []);

    return {
        tradingAccounts,
        accountsLoading: loading,
        refreshAccounts: getData
    }
}