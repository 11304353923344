import API from '../api';

export const getCountryList = (currency?: string) => (
  API.get(`api/v1/countries?per_page=250${currency ? `&currency_code=${currency}` : ''}`)
);

export const getProhibitedCountryList = () => (
    API.get(`api/v1/countries/not-allowed`)
);

export const checkIpCountry = () => API.get('api/v1/check-ip-country');