import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./Header/Header";
import { Menu } from "./Menu/Menu";
import { useAppDispatch } from "../../store";
import { useMobileMenu } from "./Menu/useMobileMenu";
import { useHeartBeat } from "../Chat/helpers/useHeartBeat";
import { chatInit } from "../../store/chat/chatSlice";
import './PrivateLayout.scss';

export const PrivateLayout = () => {
    const dispatch = useAppDispatch();
    const { setMenuOpened, menuOpened } = useMobileMenu();

    useEffect(() => {
        dispatch(chatInit());
    }, []);

    useHeartBeat();

    return (
        <div className='private-layout _h-100vh'>
            <Header switchMenu={() => setMenuOpened(!menuOpened)} isOpened={menuOpened}/>
            <Menu isOpened={menuOpened}/>
            <div className="private-layout-overflow">
                <div className="private-layout-content">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}