import NumberInput from "../../../components/Form/NumberInput/NumberInput";
import { useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import {
    createTransaction,
    createTransactionKasha,
    fetchPayment,
} from "../../../api/payments-currency";
import { getMyInfo } from "../../../api/myProfile";
import { useSelector } from "react-redux";
import { isDevice } from "../../../constants/mobile";
import { PAYMENTS } from "../../../constants/deposit";
import { FormInput } from "../../../components/Form/FormInput/FormInput";
import { Button } from "../../../components/Button/Button";
import { AmountInput } from "../../../components/Form/AmountInput/AmountInput";
import { SelectInput } from "../../../components/Form/SelectInput/SelectInput";
import { selectCurrentLanguage } from "../../../store/locale";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useCountriesOptions } from "../../../hooks/useCountriesOptions";
import { REQUIRED_FIELD_VALIDATION } from "../../../utils/validators";
import { useIp } from "../../../hooks/useIp";
import "./PaymentForm.scss";

const PaymentForm = () => {
    const intl = useIntl();
    const navigate: NavigateFunction = useNavigate();
    const {state}: any = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const lang = useSelector(selectCurrentLanguage);
    const countriesOptions = useCountriesOptions(state?.payment_currency);
    const ip = useIp();

    const isNotParameters: boolean = !(
        state.amount &&
        state.trading_account_id &&
        state.payment_currency &&
        state.gateway
    );
    const isKashaGateway = state?.gateway === PAYMENTS.kasha;
    const title = intl.formatMessage({
        id: 'payment.form.title',
        defaultMessage: 'Enter your data',
    }) + ' ' + state?.gateway || '';

    usePageTitle(title);

    const {handleSubmit, control, reset, setValue} = useForm();

    const fetchMyInfo = (): void => {
        getMyInfo()
            .then((res): void => {
                const userData = res.data.data;
                reset({
                    amount: state.amount,
                    firstname: userData.profile.first_name,
                    lastname: userData.profile.last_name,
                    city: userData.profile.city,
                    address: userData.profile.address,
                    email: userData.email,
                    phone: userData.phone,
                });
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        if (isNotParameters) {
            navigate('/cryptocurrency');
        }

        fetchMyInfo();
    }, [state]);

    const onSubmit = async (data): Promise<void> => {
        setIsLoading(true);

        if (!ip) {
            setIsLoading(false);
            navigate('/payment/fail');
        }

        state.amount = data.amount;

        try {
            const payment = await fetchPayment(state);

            const isFail: boolean = !(
                payment.data.result &&
                payment.data.result === 'success' &&
                payment.data.payment_id &&
                (state.gateway === PAYMENTS.mandato ? payment.data.token : true)
            );

            if (isFail) {
                navigate('/payment/fail');
            }

            const token = payment.data.token;
            const paymentID = payment.data.payment_id;

            switch (state.gateway) {
                case PAYMENTS.kasha:
                    const payload_kasha = {
                        country: data.country.iso_3166_2,
                        currency: state.payment_currency,
                        amount: data.amount,
                        redirectUrl: 'https://merchant.io/where-to-go',
                        language: lang,
                        customer: {
                            name: `${data.firstname} ${data.lastname}`,
                            email: data.email,
                            phone: '+' + data.phone,
                            userDevice: isDevice(window.innerWidth),
                            userAgent: navigator.userAgent.toLowerCase(),
                            ip: ip ? ip : '109.242.79.100',
                            address: {
                                street: data.address,
                                streetNumber: data.streetNumber,
                                country: data.country.iso_3166_2,
                                zipCode: data.zip,
                                city: data.city,
                                state: data.state,
                            },
                        },
                        token: token,
                        paymentId: paymentID,
                        gateway: state.gateway,
                    };

                    createTransactionKasha(payload_kasha)
                        .then(result => {
                            if (result.data?.redirect?.redirect_url) {
                                window.open(result?.data?.redirect?.redirect_url, '_blank');
                                navigate('/cryptocurrency');
                            }
                        })
                        .catch(() => navigate('/payment/fail'))
                        .finally(() => setIsLoading(false));
                    break;

                case PAYMENTS.mandato:
                    const payload = {
                        token: token,
                        payment_id: paymentID,
                        gateway: state.gateway,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        address: data.address,
                        city: data.city,
                        zip: data.zip,
                        state: data.state,
                        phoneNumber: '+' + data.phone,
                        email: data.email,
                        ip: ip ? ip : '109.242.79.100',
                        cardNumber: data.cardnumber,
                        cardName: data.cardname,
                        cvv: data.cvv,
                        month: data.cardmonth,
                        year: data.cardyear,
                    };

                    createTransaction(payload)
                        .then(result => {
                            if (result.data?.redirect?.redirect_url) {
                                window.open(result?.data?.redirect?.redirect_url, '_blank');
                                navigate('/cryptocurrency');
                            }
                        })
                        .catch(() => navigate('/payment/fail'))
                        .finally(() => setIsLoading(false));
                    break;
            }
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            navigate('/payment/fail');
        }
    };

    return (
        <div className="payment-form-page _flex _flex-col">
            <div className="_flex _flex-a-c _flex-j-b _m-b-30">
                <h1>{title}</h1>
                <Button showSpinner={isLoading} isDisabled={isLoading} onClick={handleSubmit(onSubmit)}>
                    <FormattedMessage id="payment.form.submit" defaultMessage="Submit"/>
                </Button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="form-overflow">
                <div className="_form-title">
                    <FormattedMessage id="personalInfo.form.title.main_info" defaultMessage="Main info"/>
                </div>
                <div className="row">
                    <AmountInput
                        id="payment_form_amount"
                        name="amount"
                        control={control}
                        currency={state?.payment_currency}
                        setValue={setValue}
                        label={<FormattedMessage id="payment.form.amount" defaultMessage="Amount"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 16, message: "This value is too long "}
                        }}
                        className="col c-xs-12"
                    />
                </div>
                <div className="row">
                    <FormInput
                        id="payment_form_firstname"
                        name="firstname"
                        control={control}
                        label={<FormattedMessage id="payment.form.firstname" defaultMessage="First name"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 256, message: "This value is too long "},
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                    <FormInput
                        id="payment_form_lastname"
                        name="lastname"
                        control={control}
                        label={<FormattedMessage id="payment.form.lastname" defaultMessage="Last name"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 256, message: "This value is too long "},
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                </div>
                <div className="_form-divider"/>
                <div className="_form-title">
                    <FormattedMessage
                        id="personalInfo.form.title.address"
                        defaultMessage="Address"
                    />
                </div>
                <div className="row">
                    {isKashaGateway ?
                        <SelectInput
                            id="payment_form_country"
                            name="country"
                            label={<FormattedMessage id="auth.signUp.fieldName.country" defaultMessage="Country"/>}
                            control={control}
                            rules={REQUIRED_FIELD_VALIDATION}
                            options={countriesOptions}
                            className="col c-md-6 c-lg-4"
                            menuPosition="absolute"
                        /> : null}
                    <FormInput
                        id="payment_form_address"
                        name="address"
                        control={control}
                        label={<FormattedMessage id="payment.form.address" defaultMessage="Address"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 256, message: "This value is too long "},
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                    {isKashaGateway ?
                        <NumberInput
                            id="payment_form_streetNumber"
                            name="streetNumber"
                            label={<FormattedMessage id="payment.form.streetNumber" defaultMessage="Street number"/>}
                            control={control}
                            rules={{
                                ...REQUIRED_FIELD_VALIDATION,
                                maxLength: {value: 16, message: "This value is too long "},
                            }}
                            className="col c-md-6 c-lg-2"
                        /> : null}
                    <FormInput
                        id="payment_form_city"
                        name="city"
                        control={control}
                        label={<FormattedMessage id="payment.form.city" defaultMessage="City"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 256, message: "This value is too long "},
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                    <FormInput
                        id="payment_form_zip"
                        name="zip"
                        control={control}
                        label={
                            <FormattedMessage
                                id="payment.form.zip"
                                defaultMessage="ZIP"
                            />
                        }
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 16, message: "This value is too long "}
                        }}
                        className="col c-md-6 c-lg-2"
                    />
                    <FormInput
                        id="payment_form_state"
                        name="state"
                        control={control}
                        label={<FormattedMessage id="payment.form.state" defaultMessage="State"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 256, message: "This value is too long "},
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                </div>
                <div className="_form-divider"/>
                <div className="_form-title">
                    <FormattedMessage id="personalInfo.form.title.contact_info" defaultMessage="Contact info"/>
                </div>
                <div className="row">
                    <NumberInput
                        id="payment_form_phone"
                        name="phone"
                        label={<FormattedMessage id="payment.form.phone" defaultMessage="Phone Number"/>}
                        control={control}
                        format="+# (###) ###-####"
                        mask="_"
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 18, message: "This value is too long "}
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                    <FormInput
                        id="payment_form_email"
                        name="email"
                        control={control}
                        label={<FormattedMessage id="payment.form.email" defaultMessage="Email"/>}
                        rules={{
                            ...REQUIRED_FIELD_VALIDATION,
                            maxLength: {value: 256, message: "This value is too long "},
                        }}
                        className="col c-md-6 c-lg-4"
                    />
                </div>
                {state.gateway === PAYMENTS.mandato ?
                    <>
                        <div className="_form-divider"/>
                        <div className="_form-title">
                            <FormattedMessage id="page.payments.card_info" defaultMessage="Credit card info"/>                            
                        </div>
                        <div className="row">
                            <NumberInput
                                id="payment_form_cardnumber"
                                name="cardnumber"
                                control={control}
                                label={<FormattedMessage id="payment.form.cardnumber" defaultMessage="Card number"/>}
                                rules={{
                                    ...REQUIRED_FIELD_VALIDATION,
                                    maxLength: {value: 24, message: "This value is too long "},
                                }}
                                className="col c-md-6 c-lg-4"
                            />
                        </div>
                        <div className="row">
                            <FormInput
                                id="payment_form_cardname"
                                name="cardname"
                                control={control}
                                label={<FormattedMessage id="payment.form.cardname" defaultMessage="Name on card"/>}
                                rules={{
                                    ...REQUIRED_FIELD_VALIDATION,
                                    maxLength: {value: 256, message: "This value is too long "},
                                }}
                                className="col c-md-6 c-lg-4"
                            />
                        </div>
                        <div className="row">
                            <div className="col c-lg-4 c-md-6">
                                <div className="row">
                                    <NumberInput
                                        id="payment_form_cvv"
                                        name="cvv"
                                        control={control}
                                        label={<FormattedMessage id="payment.form.cvv" defaultMessage="CVV"/>}
                                        rules={{
                                            ...REQUIRED_FIELD_VALIDATION,
                                            maxLength: {value: 4, message: "This value is too long "},
                                        }}
                                        className="col c-md-4 c-lg-4"
                                    />
                                    <NumberInput
                                        id="payment_form_cardmonth"
                                        name="cardmonth"
                                        control={control}
                                        label={<FormattedMessage id="payment.form.cardmonth" defaultMessage="Month"/>}
                                        rules={{
                                            ...REQUIRED_FIELD_VALIDATION,
                                            maxLength: {value: 4, message: "This value is too long "},
                                        }}
                                        className="col c-md-4 c-lg-4"
                                    />
                                    <NumberInput
                                        id="payment_form_cardyear"
                                        name="cardyear"
                                        control={control}
                                        label={<FormattedMessage id="payment.form.cardyear" defaultMessage="Year"/>}
                                        rules={{
                                            ...REQUIRED_FIELD_VALIDATION,
                                            maxLength: {value: 4, message: "This value is too long "},
                                        }}
                                        className="col c-md-4 c-lg-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </> : null}
            </form>
        </div>
    );
};

export default PaymentForm;
