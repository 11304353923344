import { useMemo } from 'react';
import moment from 'moment';

import Table from '../../../../components/Table/Table';
import { TABLE_TRADING_COLUMNS_NAMES } from './TradingHistoryColumns';
import { makeTableData } from '../../../../utils/makeTableData';
import { Trade, TradingTableProps } from '../../../../types/Trading';

import './trading-table.scss';

export const TradingHistoryTable = ({ data, perPage, showLoader }: TradingTableProps<Trade>) => {
  const preparedTableData = useMemo(
    () =>
      makeTableData(data, data.length).map((item: Trade) => {
        const { trade, type, open_time_dt, symbol, volume, open_price } = item;

        return {
          trade,
          type: (
            <span className={type?.toLowerCase()}>{type}</span>
          ),
          symbol,
          open_price,
          volume,
          open_time: (
            <div className="date-cell-row">
              <span>{moment(open_time_dt, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}</span>
              <span className="_grey-title">
                {moment(open_time_dt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}
              </span>
            </div>
          ),
        };
      }),
    [data],
  );

  return (
    <Table
      columns={TABLE_TRADING_COLUMNS_NAMES}
      data={preparedTableData}
      perPage={perPage}
      showLoader={showLoader}
      getCellProps={() => ({ style: {} })}
      className="trading-table"
    />
  );
};
