import { createSlice } from '@reduxjs/toolkit';
import { RootState } from "./index";

type InitialState = {
    modal: string | null,
    data: any
};

const initialState: InitialState = {
    modal: null,
    data: null
};

const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal(state, action) {
            state.modal = action.payload.key;
            if (action.payload.data) {
                state.data = action.payload.data;
            }
        },
        closeModal(state) {
            state.modal = null;
            state.data = null;
        },
    },
});

export const modalReducer = modal.reducer;
export const { openModal, closeModal } = modal.actions;

export const selectModalOpened = (state: RootState) => state.modal.modal;
export const selectModalProps = (state: RootState) => state.modal.data;
