import { useEffect } from "react";
import { useClearCache } from "react-clear-cache";

export const useAppCache = () => {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    useEffect(()=>{
        if (!isLatestVersion) {
            emptyCacheStorage()
        }
    },[]);
}