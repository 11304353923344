import { PageNavigation } from "../../../components/PageNavigation/PageNavigation";
import { paymentsPageTabs } from "./PaymentsPageTabs";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { Button } from "../../../components/Button/Button";
import { DropdownOption } from "../../../components/Dropdown/DropdownOption";
import { FormattedMessage } from "react-intl";
import { closeModal, openModal } from "../../../store/modal";
import { DEPOSIT_MODAL_KEY } from "../../../modals/DepositModal/DepositModal";
import { TRANSFER_MODAL_KEY, TransferModal } from "../../../modals/TransferModal/TransferModal";
import { WITHDRAW_MODAL_KEY, WithdrawModal } from "../../../modals/WithdrawModal/WithdrawModal";
import AddAccountModal, { ADD_ACCOUNT_MODAL_KEY } from "../../../modals/AddAccountModal/AddAccountModal";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ModalTemplate } from "../../../components/Modal/components/ModalTemplate/ModalTemplate";
import { selectPaymentIsEnabled } from "../../../store/payments";
import { useGetTradingAccounts } from "../../../hooks/useGetTradingAccounts";

export const PaymentsPageHeader = () => {
    const dispatch = useAppDispatch();
    const { tradingAccounts, accountsLoading, refreshAccounts } = useGetTradingAccounts();
    const isPaymentsEnabled = useAppSelector(selectPaymentIsEnabled);
    const tabs = isPaymentsEnabled ? paymentsPageTabs : paymentsPageTabs.filter(tab => tab.src !== '/wire-statuses');

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    return (
        <>
            <h1><FormattedMessage id="page.payments.page_title" defaultMessage="Payments" /></h1>
            {!accountsLoading && !tradingAccounts.length ? (
                <div className="_flex _flex-col _flex-a-c _p-20">
                    <div className="_m-b-15">
                        <FormattedMessage id='trading.accounts.firsttext' />
                    </div>
                    <Button onClick={() => dispatch(openModal({ key: ADD_ACCOUNT_MODAL_KEY }))}>
                        + <FormattedMessage id="newAccountPopup.title.add" defaultMessage="Trading"/>
                    </Button>
                </div>
            ) : null}
            <PageNavigation nav={tabs}>
                {tradingAccounts.length ? (
                    <Dropdown
                        className='transactions-dropdown'
                        position='bottom-left'
                        button={<Button><FormattedMessage id="page.payments.make_transaction" defaultMessage="Make transaction"/><i className="fi fi-rr-angle-small-down _flex _m-l-5"/></Button>}
                        closeOnClick={true}
                    >
                        <DropdownOption
                            title={<FormattedMessage id='page.finance.deposit.btn' defaultMessage='Deposit'/>}
                            onSelect={() => dispatch(openModal({ key: DEPOSIT_MODAL_KEY}))}
                        />
                        <DropdownOption
                            title={<FormattedMessage id='page.finance.transfer.title' defaultMessage='Transfer funds'/>}
                            onSelect={() => dispatch(openModal({ key: TRANSFER_MODAL_KEY}))}
                        />
                        <DropdownOption
                            title={<FormattedMessage id='page.finance.widthdraw.title' defaultMessage='Withdraw' />}
                            onSelect={() => dispatch(openModal({ key: WITHDRAW_MODAL_KEY}))}
                        />
                    </Dropdown>
                ) : null}
            </PageNavigation>
            <ModalTemplate
                id={TRANSFER_MODAL_KEY}
                title={<FormattedMessage id='page.finance.transfer.title' defaultMessage='Transfer funds'/>}
                onClose={handleCloseModal}
                size="sm">
                <TransferModal closeModal={handleCloseModal}/>
            </ModalTemplate>
            <ModalTemplate
                id={WITHDRAW_MODAL_KEY}
                title={<FormattedMessage id='page.finance.widthdraw.title' defaultMessage='Widthraw'/>}
                onClose={handleCloseModal}
                size="sm">
                <WithdrawModal closeModal={handleCloseModal}/>
            </ModalTemplate>
            <ModalTemplate
                id={ADD_ACCOUNT_MODAL_KEY}
                title={<FormattedMessage id="newAccountPopup.title.add" defaultMessage="Add Account"/>}
                onClose={handleCloseModal}
                size="sm"
            >
                <AddAccountModal closeModal={handleCloseModal} onSuccess={refreshAccounts}/>
            </ModalTemplate>
        </>
    )
}
