import { ReactNode } from "react";
import { AuthPageHeader } from "../AuthPageHeader/AuthPageHeader";
import PasswordImg from "../../../../assets/img/password-reset.jpg";
import { useAppSelector } from "../../../../store";
import { selectTheme } from "../../../../store/theme";
import './SimpleLayout.scss';

export const SimpleLayout = ({ children }: { children: ReactNode }) => {
    const { reset_password } = useAppSelector(selectTheme);
    return (
        <div className='simple-layout _pos-r _flex _flex-col _flex-xy-c _h-100vh'>
            <AuthPageHeader/>
            <img src={reset_password || PasswordImg} className='_object-fit' alt=''/>
            <div className='page-form-wrap _pos-r'>
                {children}
            </div>
        </div>
    )
}