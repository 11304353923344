import React from 'react';
import { IntlProvider } from 'react-intl';
import { translations } from '../translations';
import { selectCurrentLanguage } from "../store/locale";
import { useAppSelector } from "../store";

const LangWrapper = ({ children }: { children: React.ReactNode }) => {
    const locale = useAppSelector(selectCurrentLanguage);
    const messages = React.useMemo(() => translations[locale], [locale]);

    return <IntlProvider messages={messages} locale={locale}>{children}</IntlProvider>;
};

export default LangWrapper;
