import API from '../api'
import { TableAxiosResponse } from "../../types/table";
import { Transaction } from "../../types/Finance";

export const getTransactionHistory = (params: string): Promise<TableAxiosResponse<Transaction>> => (
  API.get(`/api/v1/deposit/transaction?method=Crypto&${params}`)
)

export const getTransactionHistoryWire = (params: string) => (
  API.get(`/api/v1/deposit/transaction?method=Wire&${params}`)
)
