import React from 'react';
import './preloader.scss';

const Preloader = () => {
  return (
    <div className='loader'></div>
  );
};

export default Preloader;
