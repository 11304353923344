import { FormattedMessage } from 'react-intl';
import { setProcessedMessage } from '../../../../store/chat/chatSlice';
import { ChatMessageType, ProcessedMessageMode } from '../../../../types/Chat';
import { useAppDispatch } from '../../../../store';
import { DropdownOption } from '../../../../components/Dropdown/DropdownOption';

type ReplyMessageOptionProps = { message: ChatMessageType };

export const ReplyMessageOption = ({ message }: ReplyMessageOptionProps) => {
  const dispatch = useAppDispatch();

  const handleReplyMessage = () => {
    dispatch(setProcessedMessage({ message: message, mode: ProcessedMessageMode.IS_REPLYING }));
  };

  return (
    <DropdownOption
      title={<FormattedMessage id="chat.dropdown.option.reply" defaultMessage="Reply" />}
      onSelect={handleReplyMessage}
      icon="fi-rr-reply-all"
    />
  );
};
