import { useNavigate } from 'react-router-dom';
import { usePaymentInfo } from '../../../../hooks/usePaymentInfo';
import { FormattedMessage } from 'react-intl';
import { Button } from 'components/Button/Button';

export const PaymentsError = () => {
  const paymentInfo = usePaymentInfo();
  const navigate = useNavigate();

  return (
    <>
      <h1>
          <FormattedMessage id="page.payments.errors.payment_error" defaultMessage="Payment Error" />
      </h1>
      <div className="_flex _flex-col _flex-a-c">
        <div className="_fz-24 _m-b-15 _text-c">
          <FormattedMessage id="page.payments.errors.operation_failed" defaultMessage="Operation failed!" />
        </div>
        <div className="_text-c">
          {paymentInfo ? (
            <>
              <p>
                <span>
                  {paymentInfo?.amount} {paymentInfo?.currency}
                </span>{' '}
                <FormattedMessage id="page.payments.status_is" defaultMessage="status is" />
                <span className={paymentInfo?.status}>{paymentInfo?.status}</span>
              </p>
              <br />
            </>
          ) : null}
          <p>
            <FormattedMessage
              id="page.payments.errors.replenish_balance"
              defaultMessage="Go to the Payments section and replenish the balance again."
            />
          </p>
        </div>
        <Button buttonType="primary" onClick={() => navigate("/cryptocurrency")}>
            <FormattedMessage id="page.payments.goto_payments" defaultMessage="Go to Payments" />
        </Button>
      </div>
    </>
  );
};

export default PaymentsError;
