export const translateGenderOptions = (formatMessage) => ({
    male: {
        value: 'male',
        label: formatMessage({
            id: 'personalInfo.form.field.label.male',
            defaultMessage: 'personalInfo.form.field.label.male',
        }),
    },
    female: {
        value: 'female',
        label: formatMessage({
            id: 'personalInfo.form.field.label.female',
            defaultMessage: 'personalInfo.form.field.label.female',
        }),
    },
});