import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import {
    addNewMessage, chatAuth,
    chatInit, chatPageOpened, chatThreadsRequested, clearChatMessages, clearChatStore,
    readThread,
    removeMessage,
    selectChatToken,
    selectChatUser,
    selectChatUserId, selectMessagesLoading,
    selectThread,
    selectThreads,
    setChatToken,
} from "./chatSlice";
import { playNotificationSound } from "../../pages/Chat/helpers/playNotificationSound";
import { getMessages, getThreads, incomingThread } from "./thunks";
import { createChatConnection } from "../../api/chat/socket";
import { isChatEnabled } from "../../pages/Chat/helpers/isChatEnabled";
import { logout } from "../auth";
import { loadThreadsInterval, refreshTokenInterval } from "../../pages/Chat/constants";
import { chatGetToken, chatLogin } from "../../api/chat";
import { addToast } from "../toast";

const ChatListenerMiddleware = createListenerMiddleware();

ChatListenerMiddleware.startListening({
    actionCreator: chatInit,
    effect: async (action, { dispatch, getState }) => {
        if (isChatEnabled()) {
            const storedToken = localStorage.getItem('chat-token');
            const token = selectChatToken(getState());

            if (storedToken) {
                storedToken !== token && dispatch(setChatToken(storedToken));
            } else {
                dispatch(chatAuth());
            }
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: setChatToken,
    effect: async (action, { getState, dispatch, delay, cancelActiveListeners }) => {
        cancelActiveListeners();

        const state = getState();
        const token = action.payload;
        const userId = selectChatUserId(state);

        removeChatSubscriptions(userId);

        if (token) {
            createChatConnection(token, dispatch);
            initChatSubscriptions(dispatch, userId);
            dispatch(chatThreadsRequested());

            await delay(refreshTokenInterval);
            dispatch(chatAuth());
        } else {
            dispatch(chatAuth());
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: chatAuth,
    effect: async (action, { dispatch, unsubscribe, subscribe }) => {
        try {
            unsubscribe();
            const response = await chatGetToken();
            const chatToken = await chatLogin(response.data.token);

            localStorage.setItem('chat-token', chatToken.data.token);

            dispatch(setChatToken(chatToken.data.token));
            subscribe();
        } catch (error) {
            subscribe();
            dispatch(addToast({ type: 'error', message: 'Failed to authenticate to the chat.', timeout: 2000}))
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: chatThreadsRequested,
    effect: async (action, { dispatch, cancelActiveListeners, delay, subscribe, unsubscribe }) => {
        cancelActiveListeners();
        unsubscribe();

        await dispatch(getThreads());
        subscribe();

        while (true) {
            await delay(loadThreadsInterval);
            dispatch(getThreads());
        }
    },
});

ChatListenerMiddleware.startListening({
    actionCreator: chatPageOpened,
    effect: async (action, { dispatch }) => {
        action.payload ? dispatch(chatThreadsRequested()) : dispatch(clearChatMessages());
    },
});

ChatListenerMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
        if (action.type === 'chat/user/fulfilled') {
            return !selectChatUser(previousState);
        }

        return false
    },
    effect: async (action, { getState, dispatch }) => {
        initChatSubscriptions(dispatch, selectChatUserId(getState()));
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: logout.pending,
    effect: async (action, listenerApi) => {
        if (isChatEnabled()) {
            const userId = selectChatUserId(listenerApi.getState());
            removeChatSubscriptions(userId);
            listenerApi.dispatch(clearChatStore())
        }
    }
});

ChatListenerMiddleware.startListening({
    actionCreator: addNewMessage,
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState();
        const userId = selectChatUserId(state);
        const threads = selectThreads(state);

        //TODO: CHAT - remove the condition when fixed undefined Thread
        const messageThreadIndex = threads.findIndex(thread => thread.id === action.payload.thread_id);
        if (messageThreadIndex === -1) {
            listenerApi.dispatch(incomingThread(action.payload.thread_id));
            return
        }

        if (action.payload.owner_id !== userId) {
            playNotificationSound();
        }
    },
});

ChatListenerMiddleware.startListening({
    matcher: isAnyOf(getThreads.fulfilled, incomingThread.fulfilled),
    effect: async (action, listenerApi) => {
        const state = listenerApi.getState();
        const supportId = selectThread(state)?.id;

        if (supportId && selectMessagesLoading(state) && window.location.pathname.includes('chat')) {
            listenerApi.dispatch(getMessages(supportId));
        }

        if (incomingThread.fulfilled.match(action)) {
            playNotificationSound();
        }
    },
});

const initChatSubscriptions = (dispatch, userId) => {
    if (userId) {
        window.ChatEcho.private(`messenger.user.${userId}`)
            .listen('.new.message', (message) => { dispatch(addNewMessage(message)) })
            .listen('.new.thread', (response) => { dispatch(incomingThread(response.thread.id)) })
            .listen('.message.archived', (response) => { dispatch(removeMessage(response)) })
            .listen('.thread.read', (response) => { dispatch(readThread(response)) })
    }
};

const removeChatSubscriptions = (userId) => {
    userId && window.ChatEcho.leave(`messenger.user.${userId}`);
};

export default ChatListenerMiddleware;