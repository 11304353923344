import { FormattedMessage } from 'react-intl';
import { ITradingAcc } from '../../../types/payments';

export const AccountSelectOption = ({ data }: { data: ITradingAcc }) => {
  return (
    <>
      <div className="_fw-600">
        {data.name || <FormattedMessage id="trading.accounts.no_name" defaultMessage="No name" />}{' '}
        - ID{' '}{data.trading_account}
      </div>
      <div className="option_select-component__subtitle">
        <FormattedMessage id="header.balance" defaultMessage="Balance" />
        :&nbsp;
        {data.balance} {data.amount_type}
      </div>
    </>
  );
};
