import NumberFormat from 'react-number-format';
import cn from "classnames";
import { useController } from 'react-hook-form';
import { InputField } from "../InputField/InputField";

const NumberInput = (props) => {
  const { field, formState } = useController(props);
  const fieldError = formState.errors?.[field.name]?.message || '';

  return (
        <InputField
            id={props.id}
            label={props.label}
            error={fieldError}
            tooltip={props.tooltip}
            className={cn('text-input', props.className, { required: props.rules?.required })}
        >
            <NumberFormat
              {...field}
              format={props.format}
              mask={props.mask}
              className={props.errors ? 'input error' : 'input'}
              disabled={props.disabled}
            />
        </InputField>
  );
};

export default NumberInput;
