import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import Table from "../../../../components/Table";
import { useMemo } from "react";
import { Button } from "../../../../components/Button/Button";
import { makeTableData } from "../../../../utils/makeTableData";
import { useAppDispatch } from "../../../../store";
import { openModal } from "../../../../store/modal";
import { PAYMENT_ERRORS_MODAL_KEY } from "../../../../modals/PaymentErrorsModal/PaymentErrorsModal";
import { TABLE_WIRE_TRANSFERS_COLUMNS_NAMES } from "./BankTransferTableColumns";
import { IPaymentInfo } from "../../../../types/payments";
import './Bank-transfers-statuses-table.scss';

type BankTransfersStatusesTableProps = {
    data: IPaymentInfo[],
    perPage: string,
    showLoader: boolean
}

const BankTransfersStatusesTable = ({data, perPage, showLoader}: BankTransfersStatusesTableProps) => {
    const dispatch = useAppDispatch();
    const preparedTableData = useMemo(() => makeTableData(data, data.length).map((item: IPaymentInfo) => {
        const {amount, created_at, currency, details, status, trading_account, updated_at} = item;
        const currentDetails = Array.isArray(details) && details?.map(item => Object.values(item)).flat();

        return {
            AccName: (
                <div className='account-name'>
                    <div className={`status-badge ${trading_account[0]?.status}`}/>
                    {trading_account[0]?.name}
                </div>
            ),
            tradingAcc: <span className='trading-account'>{trading_account[0]?.trading_account}</span>,
            amount: <span>{amount + ' ' + currency || ' '}</span>,
            dateTime: (
                <div className="date-cell-row">
                    <div>{moment.unix(created_at).format('DD.MM.YYYY')}</div>
                    <div className="_grey-title">{moment.unix(created_at).format('HH:mm')}</div>
                </div>
            ),
            status: (
                <div className='wire-status'>
                    <span className={`${status?.toLowerCase()} wire-status__item`}>
                        {status}
                    </span><br/>
                    <span><FormattedMessage id="page.any.date.on" defaultMessage="on"/>{' '} {moment.unix(updated_at).format('DD.MM.YYYY')}
                        &nbsp;<span className="_grey-title">{moment.unix(updated_at).format('HH:mm')}</span>
                    </span>
                    {currentDetails ?
                        <Button
                            onClick={() => dispatch(openModal({
                                key: PAYMENT_ERRORS_MODAL_KEY,
                                data: currentDetails
                            }))}>
                            <FormattedMessage id="page.payment_statuses.btn.show_errors" defaultMessage="Show errors"/>
                        </Button>
                        : null}
                </div>
            ),
        };
    }), [data]);

    return (
        <Table
            columns={TABLE_WIRE_TRANSFERS_COLUMNS_NAMES}
            data={preparedTableData}
            perPage={perPage}
            showLoader={showLoader}
            className='wire-transfers-table'
            getCellProps={() => ({style: {}})}
        />
    );
};

export default BankTransfersStatusesTable;
