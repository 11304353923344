import BankTransfersStatusesTable from "./BankTransfersStatusesTable/BankTransfersStatusesTable";
import TablePagination from "../../../components/Table/TablePagination";
import ErrorPage from "../../ErrorPage/ErrorPage";
import Preloader from '../../../components/Preloader/Preloader';
import { getPayments } from "../../../api/payments-currency";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { PaymentsPageHeader } from "../_shared/PaymentsPageHeader";
import { useTableData } from "../../../hooks/useTableData";
import { ModalTemplate } from "../../../components/Modal/components/ModalTemplate/ModalTemplate";
import { PAYMENT_ERRORS_MODAL_KEY, PaymentErrorsModal } from "../../../modals/PaymentErrorsModal/PaymentErrorsModal";
import { useAppDispatch, useAppSelector } from "../../../store";
import { closeModal } from "../../../store/modal";
import { selectPaymentIsEnabled, selectPaymentsPending } from "../../../store/payments";
import { IPaymentInfo } from "../../../types/payments";
import { FormattedMessage, useIntl } from "react-intl";

const PaymentSystemStatuses = () => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const isPaymentPending = useAppSelector(selectPaymentsPending);
    const isPaymentEnabled = useAppSelector(selectPaymentIsEnabled);

    const {
        tableDataLoading,
        tableData,
        tableMeta,
        getTableData,
        perPageCount,
        setPerPageCount,
        tableLinks
    } = useTableData<IPaymentInfo>(getPayments, !!isPaymentEnabled);

    usePageTitle(intl.formatMessage({id: "page.payment_statuses.page_title", defaultMessage: "Bank wire statuses"}));

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    if (isPaymentPending) {
        return <Preloader />
    }

    if (!isPaymentEnabled) {
        return <ErrorPage />
    }

    return (
        <>
            <PaymentsPageHeader />
            <BankTransfersStatusesTable data={tableData} perPage={perPageCount} showLoader={tableDataLoading}/>
            {tableData.length ? (
                <TablePagination
                    perPage={perPageCount}
                    onChange={getTableData}
                    tableMeta={tableMeta}
                    tableLinks={tableLinks}
                    setPerPageCount={setPerPageCount}
                />
            ) : null}
            <ModalTemplate
                id={PAYMENT_ERRORS_MODAL_KEY}
                title={<FormattedMessage id="page.payments.errors.modal_title" defaultMessage="Errors"/>}
                onClose={handleCloseModal}
                size="sm">
                <PaymentErrorsModal closeModal={handleCloseModal}/>
            </ModalTemplate>
        </>
    )
};

export default PaymentSystemStatuses;
