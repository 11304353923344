import Table from '../../../../components/Table/Table';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { Button } from '../../../../components/Button/Button';
import { withdrawCancel } from '../../../../api/deposit';
import { addToast } from "../../../../store/toast";
import { selectPaymentIsEnabled } from "../../../../store/payments";
import { makeTableData } from "../../../../utils/makeTableData";
import { TRANSACTION_TABLE_COLUMNS, TRANSACTION_TABLE_COLUMNS_WITH_PAYMENTS } from "./TransactionTableColumns";
import { Transaction } from "../../../../types/Finance";
import { FormattedMessage, useIntl } from 'react-intl';
import { getResponseErrorMessage } from "../../../../utils/getResponseErrorMessage";
import { paymentMethodLabelAdapter } from "../../../../utils/PaymentMethodLabelAdapter";
import './transaction-table.scss';

type TransactionTableProps = {
    data: Transaction[],
    perPage: string,
    showLoader: boolean,
    modifyDataHandler
}

export const TransactionTable = ({data, perPage, showLoader, modifyDataHandler}: TransactionTableProps) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const ENABLE_PAYMENT = useAppSelector(selectPaymentIsEnabled);
    const columns = useMemo(() => ENABLE_PAYMENT ? TRANSACTION_TABLE_COLUMNS_WITH_PAYMENTS : TRANSACTION_TABLE_COLUMNS, [ENABLE_PAYMENT]);

    const tableData = useMemo(() => makeTableData(data, data.length).map((item: Transaction) => {
        const {
          trading_account_id,
          demo,
          gateway,
          payment_method,
          type,
          amount,
          amount_type,
          created_at,
          status_created_date,
          transaction_id,
          status,
          acc_type,
        } = item;
        return {
          ...item,
          type: (
            <span className={`type ${type.toLowerCase()}`}>{type.replace('_', ' ')}</span>
          ),
          tradingAcc: (
            <div className="trading_account">
              {demo === 1 && (
                <>
                  <p style={{ color: 'blue' }}>
                    <FormattedMessage id="table.th.demo" defaultMessage="DEMO" />
                  </p>{' '}
                </>
              )}
              {trading_account_id}
              <span>{acc_type ? acc_type : '---'}</span>
            </div>
          ),
          amount: (
            <span>
              {amount} {amount_type}
            </span>
          ),
          transaction: <span className="transaction__id">{transaction_id}</span>,
          paymentMethod: <span>{paymentMethodLabelAdapter(payment_method)}</span>,
          gateway: <span>{gateway ? gateway : '---'}</span>,
          dateTime: (
            <div className="date-cell-row">
              <span>{format(created_at * 1000, 'dd.MM.yyyy')}</span>
              <span className="_grey-title">{format(created_at * 1000, 'HH:mm')}</span>
            </div>
          ),
          status: (
            <div className="date-cell">
              <span className={`${status?.toLowerCase()} status`}>
                {status}
              </span>
              <span className="_grey-title">
                <FormattedMessage id="page.any.date.on" defaultMessage="on"/>{' '}{format(status_created_date * 1000, 'dd.MM.yyyy')}
                &nbsp;{format(status_created_date * 1000, 'HH:mm')}
              </span>
              {status?.toLowerCase() === 'pending' && type === 'withdraw' ? (
                <Button
                  buttonType="outline"
                  onClick={() => {
                    withdrawCancel(item.id)
                      .then((res) => {
                        if (!res.data.errors) {
                          modifyDataHandler((cell) => {
                            if (cell.id === item.id) {
                              cell.status = 'canceled';
                            }
                            return cell;
                          });
                          dispatch(
                            addToast({
                              type: 'success',
                                title: intl.formatMessage({ id: 'status.success.title', defaultMessage: 'Success' }),
                                message: intl.formatMessage({
                                  id: 'page.transactions.canceled',
                                  defaultMessage: 'Transaction canceled'
                              }),
                              timeout: 3000,
                            }),
                          );
                        }
                      })
                      .catch((err) =>
                        dispatch(
                          addToast({
                            type: 'error',
                            message: getResponseErrorMessage(err),
                            timeout: 5000,
                          }),
                        ),
                      );
                  }}
                >
                  <FormattedMessage
                    id="page.transactions.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              ) : null}
            </div>
          ),
        };
      }),
    [data],
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      perPage={perPage}
      showLoader={showLoader}
      getCellProps={() => ({ style: {} })}
    />
  );
};
