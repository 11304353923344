import cn from 'classnames';
import { ComponentProps as CPops, ElementType as EType, ReactNode } from 'react';
import './Button.scss';

const DefaultTagType = 'div' as const;
type DefaultTagT = typeof DefaultTagType;
type buttonTypes = 'primary' | 'outline';

type OwnProps<T extends EType> = {
  children: ReactNode;
  isDisabled?: boolean;
  tag?: T;
  buttonType?: buttonTypes | '';
  className?: string;
  showSpinner?: boolean;
  iconOnly?: boolean;
};

type ButtonProps<T extends EType> = OwnProps<T> & Omit<CPops<T>, keyof OwnProps<T>>;

export const Button = <T extends EType = DefaultTagT>(
    {
      children,
      tag,
      className,
      buttonType = 'primary',
      isDisabled = false,
      showSpinner = false,
      iconOnly = false,
      ...otherProps
    }: ButtonProps<T>,
  ) => {
    const ButtonTag = tag || DefaultTagType;

    return (
      <ButtonTag
        className={cn('btn', buttonType, className, { disabled: isDisabled || showSpinner, 'icon-only': iconOnly})}
        {...otherProps}
      >
        <div className="btn-field">{children}</div>
        {showSpinner ? <div className="spinner"></div> : ''}
      </ButtonTag>
    );
  };

Button.displayName = 'Button';
