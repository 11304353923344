import API from '../api'
import { KashaPaymentSubmitData, TransactionSubmitData } from "../../types/Finance";
import { IndexedObjectType } from "../../types/helpers";

export const getPaymentsInfo = () => API.get('api/v1/settings')

export const fetchPayment = (data: IndexedObjectType<any>) => API.post('api/v1/payment/create', data)
export const createTransaction = (data: TransactionSubmitData) => API.post('api/v1/payment/transaction/create', data)

export const createTransactionKasha = (data: KashaPaymentSubmitData) => API.post('api/v1/payment/kasha/transaction/create', data)

export const currencyKashaPayments = () => API.get(`/api/v1/payment/kasha/currency/list`);
export const getPaymentById = (id: string) => API.get(`api/v1/payment/info/${id}`)
export const getPayments = (options: string) => API.post(`api/v1/payment/list?${options}`)

