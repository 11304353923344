import Select, { GroupBase } from 'react-select';
import cn from 'classnames';
import { useController } from 'react-hook-form';
import { SelectInputProps } from "../types";
import { InputField } from "../InputField/InputField";
import { DropdownIndicator } from "./DropdownIndicator";
import { FormattedMessage } from "react-intl";
import './SelectInput.scss';

export const SelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  id,
  name,
  label,
  options,
  isDisabled,
  isLoading,
  tooltip,
  rules,
  placeholder,
  control,
  readonly,
  formatOptionLabel,
  filterOption,
  className,
  onChange: onSelectChange,
  getOptionValue,
  getOptionLabel,
  maxMenuHeight,
  menuPosition
}: SelectInputProps<Option, IsMulti, Group>) => {
  const { field, formState } = useController({ name, control, rules });
  const { onChange, ...fieldProps } = field;
  const fieldError = formState.errors?.[name]?.message || '';

  const filterOptionsDefault = (data, input) => {
    const { label } = data;
    if (input) {
      return !!label.toLowerCase().startsWith(input.toLowerCase())
    }
    return true;
  }

  const handleChange = (data) => {
    onChange(data);
    onSelectChange?.(data, null);
  };

  return (
    <InputField
      id={id}
      label={label}
      error={fieldError}
      className={cn('select-input', className, { required: rules?.required })}
      tooltip={tooltip}
    >
      <Select
        {...fieldProps}
        inputId={id}
        menuPosition={menuPosition || "fixed"}
        options={options}
        classNamePrefix="select-input"
        className={cn({ 'is-loading': isLoading })}
        isLoading={isLoading}
        components={{ DropdownIndicator }}
        isDisabled={isDisabled || readonly}
        placeholder={
            placeholder || (
                <FormattedMessage id="placeholder.select" defaultMessage="Select" />
            )
        }
        onChange={handleChange}
        filterOption={filterOption || filterOptionsDefault}
        formatOptionLabel={formatOptionLabel}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        maxMenuHeight={maxMenuHeight || 160}
        // isClearable={isClearable}
        // isSearchable={isSearchable}
      />
    </InputField>
  );
};
