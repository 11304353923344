import { useAppSelector } from "../../store";
import { selectModalProps } from "../../store/modal";
import { Button } from "../../components/Button/Button";
import { FormattedMessage } from "react-intl";

export const PAYMENT_ERRORS_MODAL_KEY = 'PAYMENT_ERRORS_MODAL_KEY';
export const PaymentErrorsModal = ({ closeModal }) => {
    const errorsDetails = useAppSelector(selectModalProps);
    return (
        <div className="payment-error-modal">
            {errorsDetails.map((detail, index) => (
                <div className="_flex _flex-a-c" key={index}>
                    <i className="fi fi-rr-shield-exclamation _flex _fz-18 _m-r-5" style={{color: '#FFB444'}}/> {detail}
                </div>
            ))}
            <div className="_flex _m-t-30 _flex-j-e">
                <Button onClick={closeModal}>
                    <FormattedMessage id="page.trading.popup.wallet.ok" defaultMessage="OK"/>
                </Button>
            </div>
        </div>
    )
}