import cn from "classnames";
import LogoImg from "../../../../assets/Svg/logo_default.svg";
import { useAppSelector } from "../../../../store";
import { selectTheme } from "../../../../store/theme";
import './AuthPageHeader.scss';

type AuthPageHeaderProps = {
    inverse?: boolean;
}

export const AuthPageHeader = ({ inverse }: AuthPageHeaderProps) => {
    const { logo_src } = useAppSelector(selectTheme);
    return (
        <div className={cn("auth-header _pos-a _w-100 _p-x-15", {'_text-r': inverse})}>
            <div className="auth-header-logo _img-wrap">
                <img src={logo_src || LogoImg} alt='logo'/>
            </div>
        </div>
    )
}