import Preloader from '../Preloader/Preloader';
import { useTable, usePagination } from 'react-table';
import { FormattedMessage } from 'react-intl';
import './Table.scss';

export const Table = ({ columns, data, getCellProps, perPage, showLoader, className = "" }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: perPage || 10, data: data, manualPagination: true },
      stateReducer: (newState) => {
        if (perPage && newState.pageSize !== perPage) {
          newState.pageSize = perPage;
        }
      },
    },
    usePagination,
  );

  return (
      <div className={`table-container ${className} ${!page.length ? 'empty-table' : ''}`}>
        {showLoader ? (
          <div className="preloader">
            <div className="preloader__inner">
              <Preloader />
            </div>
          </div>
        ) : null}
        <table {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
          </thead>

          <tbody {...getTableBodyProps()} className={!page?.length ? 'empty' : ''}>
          {!page.length && !showLoader ? (
            <tr className="empty-msg">
              <td>
                <FormattedMessage id="table.noItems" defaultMessage="No items" />
              </td>
            </tr>
          ) : null}
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={showLoader ? '_blur' : ''}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
  );
};

export default Table;
