import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { verificationEmail } from '../../../api/auth';
import { FormattedMessage } from 'react-intl';
import Preloader from '../../../components/Preloader';
import { useAppDispatch } from "../../../store";
import { addToast } from "../../../store/toast";
import { getResponseErrorMessage } from "../../../utils/getResponseErrorMessage";
import { Button } from "../../../components/Button/Button";
import { LangSwitcher } from "../../../components/LangSwitcher/LangSwitcher";
import { SimpleLayout } from "../_shared/SimpleLayout/SimpleLayout";
import { usePageTitle } from "../../../hooks/usePageTitle";
import './verifyEmail.scss';

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  usePageTitle('Email verification');

  const sendVerificationDate = () => {
    // 'https://domain/verify/:email/:code'
    const url: string[] = window.location.href.split('/');

    verificationEmail({ email: url[4], code: url[5] })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setVerificationStatus(true);
          dispatch(addToast({
            type: 'success',
            message: res?.data?.message,
            timeout: 4000,
          }));
          setTimeout(() => {
            navigate('/');
          }, 1000);
        }
      })
      .catch((error) => {
        if (error && error.response !== null) {
          setIsLoading(false);
          dispatch(addToast({
            type: 'error',
            message: getResponseErrorMessage(error),
            timeout: 3000,
          }));
        }
      });
  };

  useEffect(() => {
    sendVerificationDate();
  }, []);

  return (
    <SimpleLayout>
      {isLoading ? <Preloader /> : (
          <div className='verify-email _text-c'>
            <div className="_flex _flex-j-e">
              <LangSwitcher closeOnSelect={true}/>
            </div>
            {verificationStatus ? (
                <>
                  <i className="fi fi-rr-shield-trust _fz-30"/>
                  <p>
                    <FormattedMessage
                        id="page.verify.successfully"
                        defaultMessage="Registration completed successfully"
                    />
                  </p>
                </>
            ) : (
                <>
                  <i className="fi fi-rr-octagon-xmark _fz-30"/>
                  <p>
                    <FormattedMessage
                        id="page.verify.somethingwWong"
                        defaultMessage="Something wrong"
                    />
                  </p>
                </>
            )}
            <div className='_m-t-50'>
              {verificationStatus ? (
                  <Button onClick={() => navigate('/')}>
                    <FormattedMessage id="page.verify.SignIn" defaultMessage="Sign in" />
                  </Button>
              ) : (
                  <Button onClick={() => sendVerificationDate()}>
                    <FormattedMessage id="page.verify.tryAgain" defaultMessage="Try again" />
                  </Button>
              )}
            </div>
          </div>
      )}
    </SimpleLayout>
  );
};

export default VerifyEmail;
