import TablePagination from "../../../components/Table/TablePagination";
import { TransactionTable } from "./TransactionTable/TransactionTable";
import { getTransactionHistory } from '../../../api/transaction';
import { PaymentsPageHeader } from "../_shared/PaymentsPageHeader";
import { useTableData } from "../../../hooks/useTableData";
import { Transaction } from "../../../types/Finance";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useIntl } from "react-intl";

const CryptocurrencyPage = () => {
    const {
        tableDataLoading,
        tableData,
        setTableData,
        tableMeta,
        getTableData,
        setPerPageCount,
        perPageCount,
        tableLinks
    } = useTableData<Transaction>(getTransactionHistory);

    const intl = useIntl();
    usePageTitle(intl.formatMessage({
        id: 'page.finance.transactionHistory.table.title',
        defaultMessage: 'Transaction history'
    }));

    return (
        <>
            <PaymentsPageHeader/>
            <TransactionTable
                data={tableData}
                perPage={perPageCount}
                showLoader={tableDataLoading}
                modifyDataHandler={(callback) => setTableData(tableData.map(callback))}
            />
            {tableData?.length ? (
                <TablePagination
                    perPage={perPageCount}
                    onChange={getTableData}
                    tableMeta={tableMeta}
                    setPerPageCount={setPerPageCount}
                    tableLinks={tableLinks}
                />
            ) : null}
        </>
    );
};

export default CryptocurrencyPage;
