import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from '../../../store';
import { closeModal, openModal } from '../../../store/modal';
import { PageNavigation } from '../../../components/PageNavigation/PageNavigation';
import { tradingPageTabs } from '../_shared/TradingPageTabs';
import { Button } from '../../../components/Button/Button';
import HistoryFilterModal, {
  HISTORY_FILTERS_MODAL_KEY,
} from '../../../modals/HistoryFilterModal/HistoryFilterModal';
import { ModalTemplate } from '../../../components/Modal/components/ModalTemplate/ModalTemplate';
import { tradingHistoryList } from '../../../api/trading';
import TablePagination from '../../../components/Table/TablePagination';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useTableData } from '../../../hooks/useTableData';
import { FilterTag } from '../../../components/FilterTag/FilterTag';
import { timestampToDate, timestampToDateDot } from '../../../utils/getTimesTamp';
import { getHistoryFilterParams } from '../../../utils/getTradingFiltersParams';
import { TradingHistoryTable } from './TradingTable/TradingHistoryTable';
import { Trade, TradingFilter } from '../../../types/Trading';

const History = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [filters, setFilters] = useState<TradingFilter>({});

  const {
    tableDataLoading,
    tableData,
    tableMeta,
    getTableData,
    setPerPageCount,
    perPageCount,
    tableLinks,
  } = useTableData<Trade>(tradingHistoryList);

  usePageTitle(
    intl.formatMessage({
      id: 'page.trading.history.title',
      defaultMessage: 'Trading History',
    }),
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const onTableParamsChange = (params) => {
    getTableData({
      ...params,
      filter: getHistoryFilterParams(filters),
    });
  };

  const onSubmitFilter = (filter: TradingFilter) => {
    setFilters(filter);
    getTableData({ filter: getHistoryFilterParams(filter) });
  };

  const openFilterModal = () => {
    dispatch(openModal({
      key: HISTORY_FILTERS_MODAL_KEY, 
      data: {
        trading_account: filters.trading_account || null,
        open: filters.open ? timestampToDate(filters.open, "YYYY-MM-DD") : null,
        close: filters.close ? timestampToDate(filters.close, "YYYY-MM-DD") : null,
      } 
    }));
  };

  return (
    <>
      <h1>
        <FormattedMessage id="menu.trading" defaultMessage="Trading" />
      </h1>
      <PageNavigation nav={tradingPageTabs}>
        <div className="_flex _flex-w _flex-a-c">
          {filters.trading_account && (
            <FilterTag 
              title={<FormattedMessage id="page.trading.tag.account" defaultMessage="Account:" />}
              onRemove={() => onSubmitFilter({ ...filters, trading_account: null })}
            >
              {filters?.trading_account?.data?.name || (
                <FormattedMessage id="trading.accounts.no_name" defaultMessage="No name" />
              )}{' '}
              - ID {filters?.trading_account?.data?.trading_account}
            </FilterTag>
          )}
          {filters.close && (
            <FilterTag 
              title={<FormattedMessage id="page.trading.tag.closed" defaultMessage="Closed:" />}
              onRemove={() => onSubmitFilter({ ...filters, close: null })}
            >
              {timestampToDateDot(Number(filters?.close?.from))}-
              {timestampToDateDot(Number(filters?.close?.to))}
            </FilterTag>
          )}
          {filters.open && (
            <FilterTag 
              title={<FormattedMessage id="page.trading.tag.opened" defaultMessage="Opened:" />}
              onRemove={() => onSubmitFilter({ ...filters, open: null })}
            >
              {timestampToDateDot(Number(filters?.open?.from))}-
              {timestampToDateDot(Number(filters?.open?.to))}
            </FilterTag>
          )}
        </div>
        <Button buttonType="outline" onClick={openFilterModal} iconOnly>
          <i className="fi fi-rr-filter _flex _fz-18" />
        </Button>
      </PageNavigation>
      <ModalTemplate
        id={HISTORY_FILTERS_MODAL_KEY}
        title={
          <FormattedMessage id="page.trading.history.form.modal.title" defaultMessage="Filter" />
        }
        onClose={handleCloseModal}
        size="md"
      >
        <HistoryFilterModal closeModal={handleCloseModal} onSubmitFilter={onSubmitFilter} />
      </ModalTemplate>
      <TradingHistoryTable data={tableData} perPage={perPageCount} showLoader={tableDataLoading} />
      {tableData?.length && tableMeta ? (
        <TablePagination
          onChange={onTableParamsChange}
          setPerPageCount={setPerPageCount}
          perPage={perPageCount}
          tableLinks={tableLinks}
          tableMeta={tableMeta}
        />
      ) : null}
    </>
  );
};

export default History;
