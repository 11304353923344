import { FormattedMessage } from "react-intl";
import { useAppSelector } from "../../../store";
import { selectModalProps } from "../../../store/modal";
import { useForm, useWatch } from "react-hook-form";
import { SelectInput } from "../../../components/Form/SelectInput/SelectInput";
import { useEffect, useState } from "react";
import { documentTypes } from "../../../api/documents";
import { Button } from "../../../components/Button/Button";
import { optionsCategoryTranslations, optionsTypeTranslations } from "./optionsTranslations";

export const DOCUMENT_UPLOAD_MODAL_KEY = 'DOCUMENT_UPLOAD_MODAL_KEY';
export const DocumentUploadModal = ({onSubmit, closeModal}) => {
    const fileName = useAppSelector(selectModalProps);

    const [typesData, setTypesData] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [typesOptions, setTypesOptions] = useState([]);

    const {control, handleSubmit} = useForm();
    const { category, category_type } = useWatch({ control });

    const changeTypesDefaultValue = (selectedOption) => {
        setTypesOptions(typesData
            .filter((el) => el.key === selectedOption.value)[0].statuses
            .map((el) => ({
                    value: el.key,
                    label: optionsTypeTranslations[el.translate],
                    key: el.key,
                }
            ))
            .filter(item => item.key !== 'id_card'));
    };

    useEffect(() => {
        documentTypes()
            .then((res) => {
                setTypesData(res.data.data);
                setCategoryOptions(res.data.data
                    .map((item) => ({
                        value: item.key,
                        label: optionsCategoryTranslations[item.translate],
                        key: item.key,
                    }))
                    .filter(item => item.value !== 'proof_of_payment' || item.label !== 'proof_of_payment')
                );
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <div className="document-upload-modal">
            <div className="_m-b-25 _word-break">
                <FormattedMessage id="documentTypes.popup.file.name" defaultMessage="Name:"/>
                &nbsp;{fileName}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SelectInput
                    id="category_upload_id"
                    name="category"
                    options={categoryOptions}
                    label={<FormattedMessage id="documentTypes.popup.file.category" defaultMessage="Category:"/>}
                    placeholder={
                        <FormattedMessage
                            id="documentTypes.popup.file.category.placeholder"
                            defaultMessage="Select category"
                        />
                    }
                    control={control}
                    onChange={changeTypesDefaultValue}
                />
                <SelectInput
                    id="category_type_upload_id"
                    name="category_type"
                    options={typesOptions}
                    label={<FormattedMessage id='documentTypes.popup.file.type' defaultMessage='Type:' />}
                    placeholder={
                        <FormattedMessage
                        id="documentTypes.popup.type.placeholder"
                        defaultMessage="Select type"
                        />
                    }
                    control={control}
                    isDisabled={!category}
                />
                <div className="_flex _flex-j-e _m-t-50">
                    <Button className="_m-r-10" buttonType="outline" onClick={closeModal}>
                        <FormattedMessage id="documentTypes.popup.btn.cancel" defaultMessage="Cancel"/>
                    </Button>
                    <Button type="submit" tag="button" isDisabled={!category_type || !category}>
                        <FormattedMessage id="documentTypes.popup.btn.send" defaultMessage="Send"/>
                    </Button>
                </div>
            </form>
        </div>
    )
}