export const FranceIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_25_31596)">
                <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#F0F0F0"/>
                <path d="M16.0001 7.99996C16.0001 4.56025 13.8291 1.6279 10.7827 0.497559V15.5024C13.8291 14.372 16.0001 11.4397 16.0001 7.99996Z" fill="#D80027"/>
                <path d="M0 7.99996C0 11.4397 2.17103 14.372 5.21741 15.5024V0.497559C2.17103 1.6279 0 4.56025 0 7.99996Z" fill="#0052B4"/>
            </g>
            <defs>
                <clipPath id="clip0_25_31596">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}